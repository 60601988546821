import React, { createContext, useContext } from 'react';
import { useNotificationLoader } from 'lib/hooks/api/notifications';

const initialState = {
  notifications: [],
  newNotifications: false,
};

const NotificationContext = createContext({
  notifications: initialState.notifications,
  newNotifications: initialState.newNotifications,
});

export const NotificationProvider = ({ children }) => {
  const {
    notifications,
    setNotifications,
    hasUnreadNotifications: newNotifications,
    setRefreshNotifications,
    setLoadMoreCounter,
    disableLoadMore,
  } = useNotificationLoader();

  return (
    <NotificationContext.Provider
      value={{
        notifications,
        setNotifications,
        newNotifications,
        setRefreshNotifications,
        setLoadMoreCounter,
        disableLoadMore,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export default function useNotificationProvider() {
  return useContext(NotificationContext);
}
