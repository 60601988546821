/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import PlanCard from 'components/Plans/PlanCard';
import { getStaticServerFilePath } from 'lib/utils';
import React from 'react';
import { EuiFocusTrap, EuiOverlayMask } from '@elastic/eui';

function PlanCardModal({ planData, isVisible, setIsVisible }) {
  const closeModal = () => {
    setIsVisible(false);
  };

  if (!isVisible || !planData) {
    return null;
  }

  return (
    <EuiOverlayMask>
      <EuiFocusTrap onClickOutside={closeModal}>
        <div
          css={css`
            max-width: 282px;
          `}
        >
          <PlanCard
            planData={{
              ...planData,
              icon: getStaticServerFilePath(planData.icon),
            }}
            removeUnselected={true}
          />
        </div>
      </EuiFocusTrap>
    </EuiOverlayMask>
  );
}

export default PlanCardModal;
