import {
  EuiButton,
  EuiI18n,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiOutsideClickDetector,
  EuiMarkdownFormat,
} from '@elastic/eui';
import { OptionTypeEnum, OptionGroupEnum } from '@hello-label/api-client';
import { useOption } from 'lib/hooks/api/options';
import useLocale from 'lib/providers/localeProvider';
import { useEffect, useState } from 'react';

function AppPoliciesModal({
  isVisible,
  setIsVisible,
  policyKey,
  usePublicApi = false,
}) {
  const { locale } = useLocale();
  const [content, setContent] = useState('');

  const { option } = useOption(
    policyKey,
    {
      type: OptionTypeEnum.Global,
      group: OptionGroupEnum.AppPolicy,
      locale: locale,
    },
    [],
    true,
    {},
    usePublicApi,
  );

  useEffect(() => {
    if (option) {
      setContent(option.value);
    }
  }, [option]);

  const closeModal = () => {
    setIsVisible(false);
  };

  if (!isVisible || !option) {
    return null;
  }

  return (
    <EuiOutsideClickDetector onOutsideClick={closeModal}>
      <EuiModal style={{ width: 800 }} onClose={closeModal}>
        <EuiModalBody>
          <EuiMarkdownFormat>{content}</EuiMarkdownFormat>
        </EuiModalBody>

        <EuiModalFooter>
          <EuiButton onClick={closeModal} fill>
            <EuiI18n default={'Close'} token={'Close'} />
          </EuiButton>
        </EuiModalFooter>
      </EuiModal>
    </EuiOutsideClickDetector>
  );
}

export default AppPoliciesModal;
