import { useState } from 'react';
import {
  EuiButtonEmpty,
  EuiIcon,
  EuiPopover,
  EuiPopoverTitle,
  EuiToolTip,
  shade,
  useEuiTheme,
  useGeneratedHtmlId,
} from '@elastic/eui';
import { css } from '@emotion/react';
import { panelWidthClosed } from 'components/Creator/CreatorCanvas/CanvasPanel/index';
import { activeStyle } from 'components/Creator/CreatorCanvas/CanvasPanel/SidebarItem';
import useAppTheme from 'lib/providers/appProvider';
import CanvasTooltip from 'components/Creator/CanvasTooltip';

function SidebarPopover({ actionItem, Component, componentProps, isMobile }) {
  const { icon, label, description } = actionItem;
  const [isOpen, setIsOpen] = useState(false);
  const popoverId = useGeneratedHtmlId();
  const { euiTheme } = useEuiTheme();
  const { theme } = useAppTheme();

  const onMenuButtonClick = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  const button = (
    <EuiButtonEmpty
      css={css`
        width: ${panelWidthClosed}px;
        height: ${panelWidthClosed}px;
        color: ${shade('#fff', 0.3)};
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        &:hover {
          ${activeStyle(theme, euiTheme)}
        }
      `}
      aria-label={label}
      onClick={onMenuButtonClick}
    >
      <CanvasTooltip
        position={'right'}
        label={actionItem.description}
        shortcut={actionItem.shortcut}
      >
        <div onClick={actionItem.action}>
          <EuiIcon size={'l'} type={actionItem.icon} />
        </div>
      </CanvasTooltip>
    </EuiButtonEmpty>
  );

  return (
    <EuiPopover
      id={popoverId}
      repositionOnScroll
      button={button}
      isOpen={isOpen}
      anchorPosition="rightCenter"
      closePopover={closeMenu}
      panelPaddingSize="m"
    >
      <EuiPopoverTitle>{label}</EuiPopoverTitle>
      <div>
        <Component {...componentProps} callback={closeMenu} />
      </div>
    </EuiPopover>
  );
}

export default SidebarPopover;
