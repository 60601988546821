/** @jsxImportSource @emotion/react */
import { useEuiFontSize, useEuiTheme } from '@elastic/eui';
import { css } from '@emotion/react';

function CurrencyRender({
  value,
  size = 'm',
  color = 'text',
  addStyle = true,
  customText = null,
}) {
  const currency = '€';
  const { euiTheme } = useEuiTheme();
  const fontSize = useEuiFontSize(size);

  let priceString = `${value.toFixed(2)} ${currency}`;
  if (customText) {
    priceString = customText;
  }

  if (addStyle) {
    return (
      <span
        css={css`
          color: ${euiTheme.colors[color]};
          ${fontSize}
        `}
      >
        {priceString}
      </span>
    );
  } else {
    return <span>{priceString}</span>;
  }
}

export default CurrencyRender;
