import { Configuration, PublicApi } from '@hello-label/api-client';
import { useQuery } from '@tanstack/react-query';
import { handleApiError } from 'lib/ErrorService';
import {
  getEntityListCacheKey,
  parseQueryParam,
  QUERY_KEY_MAPPING,
} from 'lib/hooks/api/common';
import useAuth from 'lib/providers/authProvider';
import useGlobalProvider from 'lib/providers/globalProvider';
import { useEffect } from 'react';

export function usePlans(
  { pageSize, page, query, sort, populate, useSpecialQuery } = {
    pageSize: 0,
    page: 0,
    query: {},
    sort: { createdAt: -1 },
    populate: [],
    useSpecialQuery: false,
  },
  useSetLoading = true,
  paginationEnabled = false,
  axiosOptions = {},
  usePublicApi = false,
) {
  const { user, apiClient } = useAuth();
  const { setLoading, setMessage, loading } = useGlobalProvider();
  const params = {
    pageSize,
    page,
    query,
    sort,
    populate,
    useSpecialQuery: useSpecialQuery ? '1' : '0',
  };

  const initialData = paginationEnabled
    ? {
        data: [],
        meta: {
          totalItems: 0,
          currentPage: page,
          pageSize: pageSize,
          totalPages: 0,
          query: {},
          sort: { createdAt: -1 },
        },
      }
    : undefined;

  const isEnabled = () => {
    if (usePublicApi) {
      return true;
    } else {
      return !!user && !!apiClient;
    }
  };

  const {
    isLoading,
    isFetching,
    data: plans,
    fetchStatus,
    error,
  } = useQuery({
    queryKey: [getEntityListCacheKey(QUERY_KEY_MAPPING.plans), params],
    queryFn: usePublicApi ? fetchQueryPublic : fetchQuery,
    placeholderData: initialData,
    staleTime: 60 * 1000 * 10, // 10 minute
    keepPreviousData: paginationEnabled,
    enabled: isEnabled(),
    onError: (err) =>
      handleApiError(err, setMessage, 'Error while loading the plans'),
  });

  async function fetchQuery() {
    const { data } = await apiClient.plansUsersControllerFindAll(
      params.pageSize,
      params.page,
      parseQueryParam(params.query),
      parseQueryParam(params.sort),
      params.populate,
      params.useSpecialQuery,
      axiosOptions,
    );
    return data;
  }

  async function fetchQueryPublic() {
    const api = new PublicApi(
      new Configuration({
        basePath: process.env.REACT_APP_API_CLIENT_BASE_PATH,
      }),
    );
    const { data } = await api.plansPublicControllerFindAll(
      params.pageSize,
      params.page,
      parseQueryParam(params.query),
      parseQueryParam(params.sort),
      params.populate,
      params.useSpecialQuery,
      axiosOptions,
    );
    return data;
  }

  useEffect(() => {
    if (useSetLoading) {
      if (paginationEnabled) {
        setLoading(isLoading || isFetching, usePlans.name);
      } else {
        setLoading(isLoading, usePlans.name);
      }
    }
    return () => {
      if (loading) {
        setLoading(false, usePlans.name);
      }
    };
  }, [isLoading, isFetching]);

  return {
    plans,
    isFetching,
    isLoading: isLoading && fetchStatus !== 'idle',
    fetchStatus,
    error,
  };
}
