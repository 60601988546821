import { EuiText } from '@elastic/eui';

function CreditCard({ amount, objectPurchased, additionalNotes }) {
  return (
    <EuiText>
      <h3>Credit Card payment method</h3>
    </EuiText>
  );
}

export default CreditCard;
