import { useEuiI18n } from '@elastic/eui';
import { Fragment } from 'react';

function LabelHelper({ field, isEdit }) {
  const labelTranslated = useEuiI18n(field.label, field.label);
  return (
    <Fragment>
      {labelTranslated}
      {!isEdit && field.is_required ? ' *' : ''}
    </Fragment>
  );
}

export default LabelHelper;
