import {
  EuiColorPicker,
  EuiColorPickerSwatch,
  EuiFlexItem,
  EuiForm,
  EuiFormRow,
  EuiIcon,
  EuiRange,
  EuiSelect,
  useEuiTheme,
  useGeneratedHtmlId,
} from '@elastic/eui';
import useCanvas from 'lib/providers/canvasProvider';
import React, { useEffect, useState } from 'react';
import ActionPopover from 'components/Creator/CreatorCanvas/CanvasHeader/UtilityHeaderComponents/ActionsComponents/ActionPopover';
import { ReactComponent as StrokeIcon } from 'assets/customIcons/Stoke.svg';
import ColorPicker from 'components/Creator/CreatorCanvas/CanvasHeader/UtilityHeaderComponents/ActionsComponents/ColorPicker';

function StrokeActions({ objects }) {
  return (
    <ActionPopover
      width={300}
      buttonIcon={<EuiIcon type={StrokeIcon} />}
      label={'Modify stroke'}
      content={<StrokeForm objects={objects} />}
      marginRight={4}
    />
  );
}

function StrokeForm({ objects }) {
  const { euiTheme } = useEuiTheme();
  const strokeWidthId = useGeneratedHtmlId({ prefix: 'strokeWidth' });
  const strokeStyleId = useGeneratedHtmlId({ prefix: 'strokeStyle' });
  const { editor, activeObjectUpdated } = useCanvas();
  const [width, setWidth] = useState(
    editor.getActiveStyle('strokeWidth', objects[0]),
  );
  const [style, setStyle] = useState(editor.getObjectStrokeType(objects[0]));

  useEffect(() => {
    setWidth(editor.getActiveStyle('strokeWidth', objects[0]));
    setStyle(editor.getObjectStrokeType(objects[0]));
  }, [objects, activeObjectUpdated]);

  const handleStrokeWidthChange = (e) => {
    const newWidth = parseInt(e.target.value);
    setWidth(newWidth);
    objects.forEach((object) => {
      editor.setActiveStyle('strokeWidth', newWidth, object);
      editor.setObjectStrokeType(object, style);
    });
  };

  const handleStyleChange = (e) => {
    const newStyle = e.target.value;
    setStyle(newStyle);
    objects.forEach((object) => {
      editor.setObjectStrokeType(object, newStyle);
    });
  };

  return (
    <EuiForm component="form">
      <EuiFormRow label="Stroke color" display="columnCompressed">
        <ColorPicker
          objects={objects}
          optionName={'stroke'}
          label={'Stroke color'}
          showButton={false}
        />
      </EuiFormRow>
      <EuiFormRow label="Stroke width" display="columnCompressed">
        <EuiRange
          min={1}
          max={50}
          name="range"
          id={strokeWidthId}
          showInput
          compressed
          value={width}
          onChange={handleStrokeWidthChange}
          append="px"
        />
      </EuiFormRow>
      <EuiFormRow label="Stroke style" display="columnCompressed">
        <EuiSelect
          id={strokeStyleId}
          options={[
            { value: 'solid', text: 'Solid' },
            { value: 'dashed', text: 'Dashed' },
            { value: 'dotted', text: 'Dotted' },
          ]}
          value={style}
          compressed
          aria-label="Stroke style"
          onChange={handleStyleChange}
        />
      </EuiFormRow>
    </EuiForm>
  );

  return 'stroke | (stroke - width) | (stroke - type)';
}

export default StrokeActions;
