export const minZoom = 0.05;
export const maxZoom = 8;

const updateCanvasZoom = (canvas, zoom, callback = undefined) => {
  canvas.setZoom(zoom);
  canvas.setWidth(canvas.originalW * canvas.getZoom());
  canvas.setHeight(canvas.originalH * canvas.getZoom());
  if (callback) {
    callback(zoom);
  }
};

export const zoomOutKeyboard = (canvas, updateZoomCallback) => {
  if (canvas.getZoom() === minZoom) {
    return;
  }

  let updatedZoom = parseInt(canvas.getZoom() * 100);

  // 25% jumps
  if (updatedZoom % 25 !== 0) {
    while (updatedZoom % 25 !== 0) {
      updatedZoom = updatedZoom - 1;
    }
  } else {
    updatedZoom = updatedZoom - 25;
  }

  updatedZoom = updatedZoom / 100;
  updatedZoom = updatedZoom <= 0 ? minZoom : updatedZoom;

  updateCanvasZoom(canvas, updatedZoom, updateZoomCallback);
};

export const zoomInKeyboard = (canvas, updateZoomCallback) => {
  if (canvas.getZoom() === maxZoom) {
    return;
  }

  let updatedZoom = parseInt(canvas.getZoom() * 100);

  // 25% jumps
  if (updatedZoom % 25 !== 0) {
    while (updatedZoom % 25 !== 0) {
      updatedZoom = updatedZoom + 1;
    }
  } else {
    updatedZoom = updatedZoom + 25;
  }

  updatedZoom = updatedZoom / 100;
  updatedZoom = updatedZoom > maxZoom ? maxZoom : updatedZoom;

  updateCanvasZoom(canvas, updatedZoom, updateZoomCallback);
};

export const resetZoom = (canvas, updateZoomCallback) => {
  updateCanvasZoom(canvas, 1, updateZoomCallback);
};

export const setZoomWheel = (canvas, zoomAmount, updateZoomCallback) => {
  let updatedZoom = canvas.getZoom().toFixed(2);
  updatedZoom = (updatedZoom * 100 + zoomAmount) / 100;
  if (updatedZoom < minZoom || updatedZoom > maxZoom) {
    return;
  }
  updateCanvasZoom(canvas, updatedZoom, updateZoomCallback);
};

export const setZoomValue = (canvas, zoomValue, updateZoomCallback) => {
  updateCanvasZoom(canvas, zoomValue, updateZoomCallback);
};
