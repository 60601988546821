import { EuiI18n, EuiLink, EuiBadge } from '@elastic/eui';
import { useQueryClient } from '@tanstack/react-query';
import { renderPlanUsageValue } from 'components/Plans/PlanSummaryViewer';
import { tableReducer } from 'components/table/reducer';
import { renderDateField } from 'components/table/specialFields';
import Table, { ACTIONS_LABEL } from 'components/table/Table';
import { downloadLabels } from 'lib/api/labels';
import {
  getEntityListCacheKey,
  invalidateCacheEntry,
  QUERY_KEY_MAPPING,
} from 'lib/hooks/api/common';
import { useLabels } from 'lib/hooks/api/labels';
import useAuth from 'lib/providers/authProvider';
import useGlobalProvider from 'lib/providers/globalProvider';
import useNotificationProvider from 'lib/providers/notificationsProvider';
import useServerBridgeProvider from 'lib/providers/serverBridgeProvider';
import moment from 'moment/moment';
import { useEffect, useMemo, useReducer, useState } from 'react';
import { Link } from 'react-router-dom';
import { LabelStatusEnum } from '@hello-label/api-client';

export const LABELS_DOWNLOAD_VALIDITY = 10;

export const downloadExpiresIn = (label) => {
  const createdAt = moment(label.createdAt);
  const now = moment();
  return LABELS_DOWNLOAD_VALIDITY - now.diff(createdAt, 'days');
};

export const isDownloadEnabled = (label) => {
  const expiresIn = downloadExpiresIn(label);
  if (label.status !== LabelStatusEnum.Completed) {
    return false;
  }
  if (expiresIn <= 0) {
    return false;
  } else {
    return (
      label?.generatedLabels?.downloadRemaining > 0 ||
      label?.generatedLabels?.downloadRemaining === -1
    );
  }
};

const tableInitialState = {
  currentPage: 0,
  pageSize: 10,
  sort: { createdAt: -1 },
  useSpecialQuery: false,
};

const defaultVisibleColumns = [
  { field: '_id', visible: false, searchable: false },
  { field: 'code', visible: false, searchable: true, type: 'string' },
  {
    field: 'template',
    visible: true,
    searchable: true,
    type: 'string',
    searchField: 'template.name',
    requiresSpecialQuery: true,
  },
  {
    field: 'source.originalFilename',
    visible: true,
    searchable: true,
    type: 'string',
  },
  { field: 'source.lines', visible: true, searchable: true, type: 'int' },
  { field: 'status', visible: true, searchable: true, type: 'string' },
  {
    field: 'generationOptions.labelType',
    visible: true,
    searchable: true,
    type: 'string',
  },
  {
    field: 'generationOptions.labelNameField',
    visible: true,
    searchable: true,
    type: 'string',
  },
  {
    field: 'generatedLabels.downloadRemaining',
    visible: true,
    searchable: true,
    type: 'string',
  },
  { field: 'expiresIn', visible: true, searchable: false },
  { field: 'createdAt', visible: false, searchable: false },
  { field: 'notification.opened', visible: true, searchable: false },
];

const columnsGenerator = (handleDownloadClick) => [
  {
    field: '_id',
    name: 'Id',
    'data-test-subj': 'codeCell',
    sortable: true,
    truncateText: true,
    enlarge: 1,
    align: 'center',
  },
  {
    field: 'code',
    name: 'Code',
    sortable: true,
    truncateText: false,
    enlarge: 1,
    align: 'center',
  },
  {
    field: 'template',
    name: 'Template',
    truncateText: true,
    sortable: true,
    render: (template) =>
      template ? (
        <Link to={`/templates/${template.code}/edit`}>
          <EuiLink>{template.name}</EuiLink>
        </Link>
      ) : (
        'template not found'
      ),
    align: 'center',
  },
  {
    field: 'source.originalFilename',
    name: 'Filename',
    sortable: true,
    truncateText: true,
    align: 'center',
  },
  {
    field: 'source.lines',
    name: 'Labels Generated',
    sortable: true,
    dataType: 'number',
    align: 'center',
  },
  {
    field: 'generationOptions.labelType',
    name: 'Labels Type',
    sortable: true,
    align: 'center',
  },
  {
    field: 'generationOptions.labelNameField',
    name: 'Labels Name Field',
    sortable: true,
    align: 'center',
  },
  {
    field: 'status',
    name: 'Status',
    sortable: true,
    align: 'center',
    render: (status, label) => (
      <RenderLabelStatus status={status} label={label} />
    ),
  },
  {
    field: 'generatedLabels.downloadRemaining',
    name: 'Downloads',
    sortable: true,
    align: 'center',
    render: (value, label) => {
      return (
        <EuiBadge color={getLabelBadgeColor(label.status)}>
          {renderPlanUsageValue(value, label.status)}
        </EuiBadge>
      );
    },
  },
  {
    field: 'expiresIn',
    name: 'Download expires in',
    sortable: false,
    dataType: 'date',
    render: (date, item) => {
      const expiresIn = downloadExpiresIn(item);
      let label;
      if (item.status === LabelStatusEnum.Completed) {
        if (expiresIn > 1) {
          label = `${expiresIn} days`;
        } else if (expiresIn === 1) {
          label = `${expiresIn} day`;
        } else {
          label = 'expired';
        }
      } else {
        label = labelsStatusMapper[item.status];
      }
      return (
        <EuiBadge
          color={
            label === 'expired' ? 'warning' : getLabelBadgeColor(item.status)
          }
        >
          {label}
        </EuiBadge>
      );
    },
    align: 'center',
  },
  {
    field: 'createdAt',
    name: 'Created at',
    sortable: true,
    dataType: 'date',
    render: (none, item) => renderDateField(item.createdAt),
    align: 'center',
  },
  {
    field: 'notification.opened',
    name: 'Downloaded',
    sortable: false,
    dataType: 'date',
    render: (opened, label) => (
      <RenderDownloaded opened={opened} label={label} />
    ),
    align: 'center',
  },
  {
    name: ACTIONS_LABEL,
    actions: [
      {
        name: 'Download',
        description: 'Download labels',
        type: 'icon',
        icon: 'download',
        onClick: (item) => handleDownloadClick(item),
        enabled: (item) => isDownloadEnabled(item),
      },
    ],
  },
];

function LabelsTable({ setError }) {
  const { apiClient, user } = useAuth();
  const { setMessage } = useGlobalProvider();
  const { setRefreshNotifications } = useNotificationProvider();
  const { socket } = useServerBridgeProvider();
  const [tableQuery, setTableQuery] = useState({});
  const queryClient = useQueryClient();
  const [tableState, tableDispatcher] = useReducer(
    tableReducer,
    tableInitialState,
  );
  const { labels, isFetching, isLoading, error } = useLabels(
    {
      page: tableState.currentPage,
      pageSize: tableState.pageSize,
      query: tableQuery,
      sort: tableState.sort,
      populate: ['template', 'notification'],
      useSpecialQuery: tableState.useSpecialQuery,
    },
    false,
    true,
    {},
  );

  useEffect(() => {
    if (socket) {
      socket.on('progress', (msg) => {
        handleLabelsProgress(msg);
      });
    }

    return () => {
      if (socket) {
        socket.off('progress');
      }
    };
  }, [socket]);

  useEffect(() => {
    if (error) {
      setError(error);
    }
  }, [error]);

  const handleDownloadClick = async (item) => {
    await downloadLabels(apiClient, item.code, setMessage, queryClient);
    invalidateCacheEntry(queryClient, QUERY_KEY_MAPPING.labels, 'list');
    invalidateCacheEntry(queryClient, QUERY_KEY_MAPPING.stats, 'list');
    setRefreshNotifications((prevState) => !prevState);
  };

  const handleLabelsProgress = (msg) => {
    const { user: userId } = JSON.parse(msg);
    if (userId === user._id) {
      invalidateCacheEntry(queryClient, QUERY_KEY_MAPPING.labels, 'list');
    }
  };

  const columns = useMemo(() => columnsGenerator(handleDownloadClick), []);

  if (!labels) {
    return null;
  }

  return (
    <Table
      entities={labels}
      entityName={<EuiI18n token={'Label'} default={'Label'} />}
      entitiesName={<EuiI18n token={'Labels'} default={'Labels'} />}
      isLoading={isLoading || isFetching}
      tableColumns={columns}
      tableState={tableState}
      setTableQuery={setTableQuery}
      tableDispatcher={tableDispatcher}
      defaultVisibleColumns={defaultVisibleColumns}
      pageSizeOptions={[5, 10, 25, 0]}
      tableLayout="auto"
      canSelect={false}
      sortSpecialFields={{
        template: 'template.name',
      }}
      sortSpecialFieldsMapping={{
        'template.name': 'template',
      }}
    />
  );
}

function RenderLabelStatus({ status, label }) {
  let text = status;
  if (status === LabelStatusEnum.Running) {
    const progress = label.generationProgress;
    text = `${status.toLowerCase()} - ${progress.toFixed(1)}%`;
  }

  return <EuiBadge color={getLabelBadgeColor(label.status)}>{text}</EuiBadge>;
}

function RenderDownloaded({ opened, label }) {
  if (opened !== undefined) {
    return (
      <EuiBadge color={opened ? 'hollow' : 'success'}>
        <EuiI18n
          token={opened ? 'downloaded' : 'new'}
          default={opened ? 'downloaded' : 'new'}
        />
      </EuiBadge>
    );
  }
  return (
    <EuiBadge color={'hollow'}>{labelsStatusMapper[label.status]}</EuiBadge>
  );
}

export const labelsStatusMapper = {
  [LabelStatusEnum.Requested]: (
    <EuiI18n token={'requested'} default={'requested'} />
  ),
  [LabelStatusEnum.Error]: <EuiI18n token={'error'} default={'error'} />,
  [LabelStatusEnum.DownloadExpired]: (
    <EuiI18n token={'expired'} default={'expired'} />
  ),
  [LabelStatusEnum.OutOfDownloads]: (
    <EuiI18n token={'out of downloads'} default={'out of downloads'} />
  ),
  [LabelStatusEnum.Running]: (
    <EuiI18n token={'not ready'} default={'not ready'} />
  ),
};

export function getLabelBadgeColor(status) {
  if (
    [LabelStatusEnum.DownloadExpired, LabelStatusEnum.OutOfDownloads].includes(
      status,
    )
  ) {
    return 'warning';
  }
  if (status === LabelStatusEnum.Requested) {
    return 'default';
  }
  if (status === LabelStatusEnum.Running) {
    return 'primary';
  }
  if (status === LabelStatusEnum.Error) {
    return 'danger';
  } else {
    return 'hollow';
  }
}

export default LabelsTable;
