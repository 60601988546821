import { EuiEmptyPrompt } from '@elastic/eui';

function PageBanner({
  title,
  body,
  iconType = 'alert',
  color = 'danger',
  actions = [],
}) {
  return (
    <EuiEmptyPrompt
      iconType={iconType}
      color={color ? color : 'danger'}
      title={<h2>{title}</h2>}
      body={body}
      actions={actions}
    />
  );
}

export default PageBanner;
