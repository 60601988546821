import React, { createContext, useState, useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';

const initialState = {
  message: [
    /*{
      id: uuidv4(),
      title: "Message title",
      color: "danger",
      iconType: "alert",
      text: "Message body",
      toastLifeTimeMs: 15000, // 15 seconds
    },*/
  ],
  globalUser: null,
  loading: false,
  fatalError: false,
  persistentNotification: null,
};

const GlobalContext = createContext({
  message: initialState.message,
  globalUser: initialState.globalUser,
  loading: initialState.loading,
  fatalError: initialState.fatalError,
  persistentNotification: initialState.persistentNotification,
});

const loadingManager = {};

export const GlobalProvider = ({ children }) => {
  const [loading, setLoadingState] = useState(initialState.loading);
  const [messages, setMessages] = useState(initialState.message);
  const [globalUser, setGlobalUser] = useState(initialState.globalUser);
  const [fatalError, setFatalError] = useState(initialState.fatalError);
  const [persistentNotification, setPersistentNotification] = useState(
    initialState.persistentNotification,
  );

  const setMessage = ({
    title,
    color,
    iconType,
    text,
    toastLifeTimeMs,
    code,
  }) => {
    setMessages((prevState) => [
      ...prevState,
      {
        id: code ? code : uuidv4(),
        title,
        color,
        iconType,
        text,
        toastLifeTimeMs,
      },
    ]);
  };

  const setLoading = (value, caller) => {
    if (value) {
      loadingManager[caller] = value;
    } else {
      delete loadingManager[caller];
    }
    let appIsLoading = false;
    for (const [key, isLoading] of Object.entries(loadingManager)) {
      if (isLoading) {
        appIsLoading = true;
      }
    }
    setLoadingState(appIsLoading);
  };

  return (
    <GlobalContext.Provider
      value={{
        loading,
        setLoading,
        messages,
        setMessages,
        setMessage,
        fatalError,
        setFatalError,
        globalUser,
        setGlobalUser,
        persistentNotification,
        setPersistentNotification,
      }}
    >
      <div className="app">{children}</div>
    </GlobalContext.Provider>
  );
};

export default function useGlobalProvider() {
  return useContext(GlobalContext);
}
