/** @jsxImportSource @emotion/react */
import { EuiIcon, shade, useEuiTheme } from '@elastic/eui';
import { css } from '@emotion/react';
import useAppTheme from 'lib/providers/appProvider';
import {
  expandedBackgroundColor,
  border,
  panelWidthClosed,
} from 'components/Creator/CreatorCanvas/CanvasPanel/index';
import CanvasTooltip from 'components/Creator/CanvasTooltip';

export const activeStyle = (colorMode, euiTheme) => css`
  background-color: ${expandedBackgroundColor(colorMode, euiTheme)};
  border-color: ${colorMode === 'dark'
    ? border(colorMode, euiTheme)
    : expandedBackgroundColor(colorMode, euiTheme)};
  color: #fff;
`;

function SidebarItem({ actionItem, activeAction, actionClickWrapper }) {
  const { euiTheme } = useEuiTheme();
  const { theme } = useAppTheme();

  return (
    <CanvasTooltip
      position={'right'}
      label={actionItem.description}
      shortcut={actionItem.shortcut}
    >
      <div
        onClick={() => actionClickWrapper(actionItem)}
        css={css`
          width: ${panelWidthClosed}px;
          height: ${panelWidthClosed}px;
          color: ${shade('#fff', 0.3)};
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          ${actionItem.code === activeAction?.code
            ? activeStyle(theme, euiTheme)
            : null}

          &:hover {
            ${activeStyle(theme, euiTheme)}
          }
        `}
      >
        <EuiIcon size={'l'} type={actionItem.icon} />
      </div>
    </CanvasTooltip>
  );
}

export function SidebarItemMobileSimple({ actionItem }) {
  const { euiTheme } = useEuiTheme();
  const { theme } = useAppTheme();

  return (
    <CanvasTooltip
      position={'right'}
      label={actionItem.description}
      shortcut={actionItem.shortcut}
    >
      <div
        onClick={actionItem.customRender ? () => {} : actionItem.action}
        css={css`
          width: ${panelWidthClosed}px;
          height: ${panelWidthClosed}px;
          color: ${shade('#fff', 0.3)};
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;

          &:hover {
            ${activeStyle(theme, euiTheme)}
          }
        `}
      >
        {actionItem.customRender ? (
          actionItem.icon
        ) : (
          <EuiIcon size={'l'} type={actionItem.icon} />
        )}
      </div>
    </CanvasTooltip>
  );
}

export default SidebarItem;
