export function scaleBack(canvas, newWidth) {
  const scaleMultiplier = newWidth / canvas.width;
  scaleToRatio(canvas, scaleMultiplier);
}

export function scaleToRatio(canvas, ratio) {
  const objects = canvas.getObjects();
  for (let i in objects) {
    objects[i].scaleX = objects[i].scaleX * ratio;
    objects[i].scaleY = objects[i].scaleY * ratio;
    objects[i].left = objects[i].left * ratio;
    objects[i].top = objects[i].top * ratio;
    objects[i].setCoords();
  }

  canvas.setWidth(canvas.getWidth() * ratio);
  canvas.setHeight(canvas.getHeight() * ratio);
  canvas.renderAll();
  canvas.calcOffset();
}

export function scaleObject(canvas, object, ratio) {
  object.scaleX = object.scaleX * ratio;
  object.scaleY = object.scaleY * ratio;
  object.left = object.left * ratio;
  object.top = object.top * ratio;
  object.setCoords();
  canvas.renderAll();
  canvas.calcOffset();
}
