import DismissibleCallout from 'components/DismissibleCallout';
import useGlobalProvider from 'lib/providers/globalProvider';

function PersistentNotification() {
  const { persistentNotification, setPersistentNotification } =
    useGlobalProvider();

  if (!persistentNotification) {
    return null;
  }
  return (
    <>
      <DismissibleCallout
        title={persistentNotification.title}
        color={persistentNotification.color}
        iconType={persistentNotification.iconType}
        text={persistentNotification.text}
        dismissMessage={() => setPersistentNotification(null)}
      />
    </>
  );
}

export default PersistentNotification;
