export const buildTemplateObject = ({
  name,
  description,
  height,
  width,
  dpi = 96,
  fields,
  jsonTemplate,
  fieldsObject,
  templateImage,
  customFonts = [],
}) => ({
  name,
  description,
  dimensions: { height, width, dpi },
  fields,
  jsonTemplate,
  fieldsObject,
  templateImage,
  customFonts,
});

export const buildTemplateMetadata = (templateData) => ({
  name: templateData.name,
  description: templateData.description,
  height: templateData.dimensions.height,
  width: templateData.dimensions.width,
  dpi: templateData.dimensions.dpi,
  fields: templateData.fields,
  customFonts: templateData.customFonts,
});
