import React from 'react';
import { EuiSwitch, EuiIcon, useEuiTheme } from '@elastic/eui';
import useAppTheme from 'lib/providers/appProvider';

function ThemeSwitcher({ compressed }) {
  const { euiTheme } = useEuiTheme();
  const { theme, setCurrentTheme } = useAppTheme();
  return (
    <EuiSwitch
      label={
        theme === 'light' ? (
          <EuiIcon type="moon" color={'#fff'} />
        ) : (
          <EuiIcon type="sun" color={'#fff'} />
        )
      }
      checked={theme === 'dark'}
      onChange={() => setCurrentTheme(theme === 'light' ? 'dark' : 'light')}
      compressed={compressed ? compressed : false}
    />
  );
}

export default ThemeSwitcher;
