import { fabric } from 'fabric';
import initCenteringGuidelines from 'lib/canvasEditor/dist/centering_guidelines';
import initAligningGuidelines from 'lib/canvasEditor/dist/aligning_guidelines';
import { DRAWING_BOX_FIELD } from 'lib/templateFieldsUtils';

export function getScaleMultiplier(
  originalW,
  originalH,
  canvasW,
  canvasH,
  wrapperW,
  wrapperH,
) {
  // original and canvas < wrapper, we do nothing
  if (
    originalW === canvasW &&
    originalW < wrapperW &&
    originalH === canvasH &&
    originalH < wrapperH
  ) {
    return 1;
  }
  // original > wrapper, we scale down
  let wDiff = wrapperW - originalW;
  let hDiff = wrapperH - originalH;
  if (wDiff < 0 || hDiff < 0) {
    if (wDiff <= hDiff) {
      return wrapperW / canvasW;
    } else {
      return wrapperH / canvasH;
    }
  }
  // canvas < original and wrapper > original, we scale up
  let scaleMultiplierW = 1;
  let scaleMultiplierH = 1;
  if (canvasW < originalW && wrapperW > originalW) {
    scaleMultiplierW = originalW / canvasW;
  }
  if (canvasH < originalH && wrapperH > originalH) {
    scaleMultiplierH = originalH / canvasH;
  }
  return scaleMultiplierW > scaleMultiplierH
    ? scaleMultiplierW
    : scaleMultiplierH;
}

export function getScaleFromOriginal(originalW, originalH, canvasW, canvasH) {
  let wDiff = originalW - canvasW;
  let hDiff = originalH - canvasH;
  let originalScale = 1;
  if (wDiff > 0 || hDiff > 0) {
    if (wDiff > hDiff) {
      originalScale = canvasW / originalW;
    } else {
      originalScale = canvasH / originalH;
    }
  }
  return originalScale;
}

function drawLimitBox(canvas, width, height, accentColor) {
  canvas.add(
    new fabric.Rect({
      left: -2,
      top: -2,
      originX: 'left',
      originY: 'top',
      width: width + 2,
      height: height + 2,
      angle: 0,
      fill: 'rgba(255,0,0,0)',
      stroke: accentColor,
      strokeDashArray: [10],
      strokeWidth: 2,
      transparentCorners: false,
      selectable: false,
      evented: false,
      exportIgnore: true,
      fieldMetadata: { type: DRAWING_BOX_FIELD, isTemplateField: false },
    }),
  );
}

export function initCanvas(
  canvas,
  wrapperComponent,
  {
    height,
    width,
    backgroundColor,
    accentColor,
    objectAddedEvent,
    objectRemovedEvent,
    selectionClearedEvent,
    selectionEvent,
    handleObjectUpdated,
    selectionUpdatedEvent,
  },
) {
  canvas.setHeight(height);
  canvas.setWidth(width);
  canvas.originalW = canvas.width;
  canvas.originalH = canvas.height;
  canvas.backgroundColor = backgroundColor;
  canvas.on('object:added', (e) => objectAddedEvent(e));
  canvas.on('object:removed', (e) => objectRemovedEvent(e));
  canvas.on('selection:cleared', () => selectionClearedEvent());
  canvas.on('selection:created', (e) => selectionEvent(e));
  canvas.on('selection:updated', (e) => selectionUpdatedEvent(e));
  canvas.on('object:scaling', () => handleObjectUpdated());
  // snap to an angle on rotate if shift key is down
  canvas.on('object:rotating', (e) => {
    if (e.e.shiftKey) {
      e.target.snapAngle = 15;
    } else {
      e.target.snapAngle = false;
    }
    handleObjectUpdated();
    canvas.fire('object:modified');
  });
  initCenteringGuidelines(canvas);
  initAligningGuidelines(canvas);
}
