import { OptionTypeEnum, OptionGroupEnum } from '@hello-label/api-client';
import { useOptions } from 'lib/hooks/api/options';
import useAuth from 'lib/providers/authProvider';
import { createContext, useContext, useEffect, useState } from 'react';

const initialState = {
  bankTransferOptions: {},
  appInfoOptions: {},
  userOptions: {},
};

const OptionsContext = createContext({
  bankTransferOptions: initialState.bankTransferOptions,
  appInfoOptions: initialState.appInfoOptions,
  userOptions: initialState.userOptions,
});

export const OptionsProvider = ({ children }) => {
  const { user } = useAuth();
  const [bankTransferOptions, setBankTransferOptions] = useState(
    initialState.bankTransferOptions,
  );
  const [appInfoOptions, setAppInfoOptions] = useState(
    initialState.appInfoOptions,
  );
  const [userOptions, setUserOptions] = useState(initialState.userOptions);
  const { options: bankTransferOptionsData } = useOptions({
    pageSize: 10,
    page: 0,
    query: {
      type: OptionTypeEnum.Global,
      group: OptionGroupEnum.BankTransfer,
    },
  });
  const { options: appInfoOptionsData } = useOptions({
    pageSize: 0,
    page: 0,
    query: {
      type: OptionTypeEnum.Global,
      group: OptionGroupEnum.AppInfo,
    },
  });
  const { options: userOptionsData } = useOptions({
    pageSize: 0,
    page: 0,
    query: {
      type: OptionTypeEnum.User,
      group: OptionGroupEnum.None,
      user: user?._id,
    },
  });

  useEffect(() => {
    if (bankTransferOptionsData?.data.length > 0) {
      const options = {};
      bankTransferOptionsData.data.forEach((option) => {
        options[option.name] = option.value;
      });
      setBankTransferOptions(options);
    }
  }, [bankTransferOptionsData]);

  useEffect(() => {
    if (appInfoOptionsData?.data.length > 0) {
      const options = {};
      appInfoOptionsData.data.forEach((option) => {
        options[option.name] = option.value;
      });
      setAppInfoOptions(options);
    }
  }, [appInfoOptionsData]);

  useEffect(() => {
    if (userOptionsData?.data.length > 0) {
      const options = {};
      userOptionsData.data.forEach((option) => {
        options[option.name] = option.value;
      });
      setUserOptions(options);
    }
  }, [userOptionsData]);

  return (
    <OptionsContext.Provider
      value={{
        bankTransferOptions,
        appInfoOptions,
        userOptions,
      }}
    >
      {children}
    </OptionsContext.Provider>
  );
};

export default function useOptionsProvider() {
  return useContext(OptionsContext);
}
