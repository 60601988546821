import React from 'react';
import AlignActions from 'components/Creator/CreatorCanvas/CanvasHeader/UtilityHeaderComponents/DefaultActions/AlignActions';
import PositionActions from 'components/Creator/CreatorCanvas/CanvasHeader/UtilityHeaderComponents/DefaultActions/PositionActions';
import FlipActions from 'components/Creator/CreatorCanvas/CanvasHeader/UtilityHeaderComponents/DefaultActions/FlipActions';
import AngleAction from 'components/Creator/CreatorCanvas/CanvasHeader/UtilityHeaderComponents/DefaultActions/AngleAction';
import PositionPanelActions from 'components/Creator/CreatorCanvas/CanvasHeader/UtilityHeaderComponents/DefaultActions/PositionPanelActions';

function DefaultActions() {
  return (
    <>
      {/*<AlignActions />
      <PositionActions />
      <FlipActions />*/}
      <PositionPanelActions />
      <AngleAction />
    </>
  );
}

export default DefaultActions;
