import {
  CODES_ALIASES,
  SUPPORTED_CODES_CODE_ONLY,
} from '@hello-label/common-web-libs/src/barcode';

export const DEFAULT_FIELD_TYPE = 'text';

export const SUFFIX_SPECIAL_CHARACTER = '#';

export const CUSTOM_TEXT_FIELD = 'custom_text';
export const CUSTOM_TEXT_BOX_FIELD = 'custom_text_box';
export const CUSTOM_LINE_FIELD = 'custom_line';
export const CUSTOM_SHAPE_FIELD = 'custom_shape';
export const CUSTOM_IMAGE_FIELD = 'custom_image';
export const CUSTOM_FIELD = 'custom_field';
export const DRAWING_BOX_FIELD = 'drawing_box';
export const GRID_FIELD = 'grid';

export const TEXT_FIELDS = [
  DEFAULT_FIELD_TYPE,
  CUSTOM_TEXT_FIELD,
  CUSTOM_TEXT_BOX_FIELD,
];
export const EXPORT_IGNORE_FIELDS = [DRAWING_BOX_FIELD, GRID_FIELD];

export function getFieldInfo(field, sampleValue) {
  const fieldInfo = {
    name: field,
    type: DEFAULT_FIELD_TYPE,
    sampleValue: sampleValue,
  };
  const fieldNoSuffix = field.split(SUFFIX_SPECIAL_CHARACTER)[0];
  // check if fieldNoSuffix is an alis
  let searchValue = fieldNoSuffix;
  if (CODES_ALIASES.hasOwnProperty(fieldNoSuffix)) {
    searchValue = CODES_ALIASES[fieldNoSuffix];
  }
  const codes_filter = SUPPORTED_CODES_CODE_ONLY.filter(
    (code) => code.toLowerCase() === searchValue.toLowerCase().trim(),
  );
  if (codes_filter.length === 1) {
    fieldInfo.type = codes_filter[0];
  }
  return fieldInfo;
}
