export function getECommercePlanCode(nLabels) {
  return `e-commerce-${nLabels}`;
}

export function getECommercePlanPricePerLabel(nLabels) {
  let pricePerLabel;
  if (nLabels !== '') {
    if (nLabels <= 1000) {
      pricePerLabel = 0.05;
    } else if (nLabels <= 5000) {
      pricePerLabel = 0.03;
    } else {
      pricePerLabel = 0.02;
    }
  }

  return pricePerLabel;
}

export function getECommercePlan(nLabels) {
  return {
    code: getECommercePlanCode(nLabels),
    name: `E-Commerce ${nLabels}`,
    description: `E-Commerce ${nLabels} plan allows generating ${nLabels} labels with as many as needed templates.`,
    price: getECommercePlanPricePerLabel(nLabels) * nLabels,
    enabled: true,
    visible: true,
    rules: {
      validity: -1,
      n_labels: nLabels,
      n_templates: -1,
      n_downloads: -1,
    },
  };
}

export const DEMO_PLAN_CODE = 'demo';

export const CUSTOM_ECOMMERCE_PLAN_CODE = 'custom-ecommerce-plan';
