import { EuiText } from '@elastic/eui';
import { logError } from 'lib/ErrorService/errorLogger';

export function handleApiError(
  error,
  setMessage,
  errorMessage,
  textMessage = 'The following error occurred:',
  messagesTimeout = 15000,
) {
  logError(error);
  let message = error.message;
  if (error.response) {
    message = error.response.data.message;
  }
  setMessage({
    title: errorMessage,
    color: 'danger',
    iconType: 'alert',
    text: textMessage ? (
      <>
        <EuiText>
          {textMessage}
          <br />
        </EuiText>
        <EuiText size="s">{message}</EuiText>
      </>
    ) : (
      <EuiText>{message}</EuiText>
    ),
    toastLifeTimeMs: messagesTimeout,
  });
}
