import { EuiComboBox } from '@elastic/eui';
import { getCode, getNames, getName } from 'country-list';
import { useEffect, useState } from 'react';

const defaultOptions = getNames()
  .sort()
  .map((country) => ({ label: country }));

function CountrySelect({ country, setCountry, isReset, fullWidth = false }) {
  const [options, setOptions] = useState(defaultOptions);
  const [selectedOptions, setSelected] = useState(
    country === '' ? [] : [{ label: getName(country) }],
  );

  const onChange = (selectedOptions) => {
    setSelected(selectedOptions);
    if (selectedOptions.length > 0) {
      setCountry(getCode(selectedOptions[0].label));
    }
  };

  const onCreateOption = (searchValue, flattenedOptions = []) => {
    const normalizedSearchValue = searchValue.trim().toLowerCase();

    if (!normalizedSearchValue) {
      return;
    }

    const newOption = {
      label: searchValue,
    };

    // Create the option if it doesn't exist.
    if (
      flattenedOptions.findIndex(
        (option) => option.label.trim().toLowerCase() === normalizedSearchValue,
      ) === -1
    ) {
      setOptions([...options, newOption]);
    }

    // Select the option.
    setSelected([...selectedOptions, newOption]);
  };

  useEffect(() => {
    setSelected(country === '' ? [] : [{ label: getName(country) }]);
  }, [isReset]);

  return (
    <EuiComboBox
      fullWidth={fullWidth}
      aria-label="Country select"
      placeholder="Select country"
      options={options}
      selectedOptions={selectedOptions}
      onChange={onChange}
      onCreateOption={onCreateOption}
      isClearable={true}
      singleSelection={{ asPlainText: true }}
    />
  );
}

export default CountrySelect;
