import { fabric } from 'fabric';
import { initCanvas } from 'lib/canvasEditor/dist/init_canvas';
import {
  CUSTOM_IMAGE_FIELD,
  CUSTOM_LINE_FIELD,
  CUSTOM_SHAPE_FIELD,
  EXPORT_IGNORE_FIELDS,
  TEXT_FIELDS,
} from 'lib/templateFieldsUtils';
import { scaleToRatio } from 'lib/canvasEditor/dist/scaling_utils';
import { processSingleFile } from 'lib/canvasEditor/dist/upload';
import {
  addLine,
  lineDrawing,
} from 'lib/canvasEditor/dist/drawing/drawingLine';
import { pathDrawing } from 'lib/canvasEditor/dist/drawing/drawingPath';
import 'lib/canvasEditor/dist/history';
import { addShape } from 'lib/canvasEditor/dist/drawing/shapes';
import { drawBarcode } from 'lib/canvasEditor/dist/drawing/barcode';
import { addText, addTextBox } from 'lib/canvasEditor/dist/drawing/text';
import { drawLimitBox } from 'lib/canvasEditor/dist/drawing/limitBox';
import { drawGrid } from 'lib/canvasEditor/dist/drawing/grid';
import {
  handleKeydownEvents,
  handleWheelEvents,
} from 'lib/canvasEditor/dist/keyboardAndWheelActions';
import {
  handleCopyEvent,
  handlePasteEvent,
} from 'lib/canvasEditor/dist/copyPaste';
import {
  resetZoom,
  setZoomWheel,
  setZoomValue,
  zoomInKeyboard,
  zoomOutKeyboard,
} from 'lib/canvasEditor/dist/zoom';
import { alignObject, changePosition } from 'lib/canvasEditor/dist/utils';
import { canvasOffset } from 'lib/providers/canvasProvider';

const defaultOptions = {
  width: 400,
  height: 400,
  backgroundColor: '#ffffff',
  accentColor: '#e16c6c',
};

class Editor {
  updateZoomCallback = null;
  _clipboard = null;
  fitZoomValue = null;
  originalZoomValue = 1;
  fitScreenIcon = 'fullScreen';
  fitScreenText = 'Fit to screen';

  constructor(canvas, wrapperComponent, options) {
    this.options = {
      ...defaultOptions,
      ...options,
    };
    this.wrapperComponent = wrapperComponent;
    this.canvas = canvas;
    this.scaleMultiplier = 1;
    this.activeTool = '';
  }

  initCanvas(
    objectAddedEvent,
    objectRemovedEvent,
    selectionClearedEvent,
    selectionEvent,
    updateZoomCallback,
    handleObjectUpdated,
    selectionUpdatedEvent,
  ) {
    this.updateZoomCallback = updateZoomCallback;
    initCanvas(this.canvas, this.wrapperComponent, {
      ...this.options,
      objectAddedEvent,
      objectRemovedEvent,
      selectionClearedEvent,
      selectionEvent,
      handleObjectUpdated,
      selectionUpdatedEvent,
    });
    document.addEventListener('keydown', (event) => {
      handleKeydownEvents(event, this);
    });
    this.wrapperComponent.addEventListener('copy', (e) => {
      this.copy();
    });
    this.wrapperComponent.addEventListener('paste', (e) => {
      this.paste();
    });
    document.addEventListener('wheel', (e) => handleWheelEvents(e, this));
  }

  getObjects() {
    return this.canvas.getObjects();
  }

  getActiveObject() {
    return this.canvas.getActiveObject();
  }

  close() {
    try {
      this.canvas.dispose();
    } catch (err) {}
  }

  async addText(text, options = {}) {
    return await addText(this.canvas, text, options);
  }

  async addTextBox(text, options = {}) {
    return await addTextBox(this.canvas, text, options);
  }

  async addLine(options = {}) {
    await addLine(this.canvas, options);
  }

  drawGrid(canvasHeight, canvasWidth, strokeColor) {
    this.historyPrevent();
    const grid = drawGrid(this.canvas, canvasHeight, canvasWidth, strokeColor);
    this.historyEnable();
    return grid;
  }

  async drawImage(file, callback = null) {
    return await processSingleFile(this.canvas, file, callback);
  }

  async drawShape(svg, stroke = '#000', fill = '#fff') {
    return await addShape(this.canvas, svg, stroke, fill);
  }

  drawLimitBox(width, height, accentColor) {
    drawLimitBox(this.canvas, width, height, accentColor);
  }

  async drawBarcode(barcodeSvg, displayText, objOptions) {
    return await drawBarcode(this, barcodeSvg, displayText, objOptions);
  }

  centerView() {
    this.canvas.setZoom(1);
    this.canvas.viewportTransform[0] = 1;
    this.canvas.viewportTransform[1] = 0;
    this.canvas.viewportTransform[2] = 0;
    this.canvas.viewportTransform[3] = 1;
    this.canvas.viewportTransform[4] = 0;
    this.canvas.viewportTransform[5] = 0;
  }

  historyPrevent() {
    this.canvas.historyProcessing = true;
  }

  historyEnable() {
    this.canvas.historyProcessing = false;
  }

  alignObjects(objects, pos) {
    let selected = objects;
    if (objects === null) {
      selected = this.canvas.getActiveObject() || this.canvas.getActiveGroup();
    }
    alignObject(this.canvas, selected, pos);
  }

  changePosition(pos) {
    changePosition(this.canvas, pos);
  }

  flipSelection(flip) {
    const activeObject = this.canvas.getActiveObject();
    activeObject.set(flip, !activeObject[flip]);
    this.renderAll();
    this.canvas.fire('object:modified');
  }

  rotateActiveObject(angle) {
    const activeObject = this.canvas.getActiveObject();
    activeObject.rotate(angle);
    this.renderAll();
    this.canvas.fire('object:modified');
  }

  getActiveStyle(styleName, object) {
    object = object || this.canvas.getActiveObject();
    if (!object) return '';

    return object.getSelectionStyles && object.isEditing
      ? object.getSelectionStyles()[styleName] || ''
      : object[styleName] || '';
  }

  setActiveStyle(styleName, value, object) {
    object = object || this.canvas.getActiveObject();
    if (!object) return;

    if (object.setSelectionStyles && object.isEditing) {
      const style = {};
      style[styleName] = value;
      object.setSelectionStyles(style);
      object.setCoords();
    } else {
      object.set(styleName, value);
    }

    object.setCoords();
    this.renderAll();
    this.canvas.fire('object:modified');
  }

  getActiveProp(name, object) {
    object = object || this.canvas.getActiveObject();
    if (!object) return '';

    return object[name] || '';
  }

  setActiveProp(name, value, object) {
    object = object || this.canvas.getActiveObject();
    if (!object) return;
    object.set(name, value).setCoords();
    this.renderAll();
  }

  getObjectStrokeType(object) {
    let type = 'solid';
    const strokeWidth = object.strokeWidth;
    if (object.strokeDashArray) {
      if (object.strokeDashArray[0] === strokeWidth) {
        type = 'dotted';
      } else {
        type = 'dashed';
      }
    }
    return type;
  }

  setObjectStrokeType(object, type) {
    const strokeWidth = this.getActiveStyle('strokeWidth', object);
    switch (type) {
      case 'dotted':
        this.setActiveStyle('strokeDashArray', [strokeWidth], object);
        break;
      case 'dashed':
        this.setActiveStyle('strokeDashArray', [strokeWidth * 5], object);
        break;
      default:
        this.setActiveStyle('strokeDashArray', null, object);
    }
  }

  getObjectType(obj) {
    if (obj.fieldMetadata) {
      if (TEXT_FIELDS.includes(obj.fieldMetadata.type)) {
        return 'text';
      } else {
        switch (obj.fieldMetadata.type) {
          case CUSTOM_LINE_FIELD:
            return 'line';
          case CUSTOM_SHAPE_FIELD:
            return 'shape';
          case CUSTOM_IMAGE_FIELD:
            return 'image';
          default:
            return 'barcode';
        }
      }
    } else {
      return obj.type;
    }
  }

  getWidthFromScale(object) {
    return object.scaleX * object.width;
  }

  getHeightFromScale(object) {
    return object.scaleY * object.height;
  }

  deselectAllObjects() {
    this.canvas.discardActiveObject();
    this.renderAll();
  }

  deleteSelected() {
    const selectedObjects = this.canvas.getActiveObjects();
    if (selectedObjects.length > 0) {
      const removeAlso = [];
      selectedObjects.forEach((obj) => {
        if (obj.fieldMetadata) {
          if (obj.fieldMetadata.connectedTo) {
            removeAlso.push(obj.fieldMetadata.connectedTo.object);
          }
        }
      });
      selectedObjects.forEach((object) => this.canvas.remove(object));
      removeAlso.forEach((object) => this.canvas.remove(object));
      this.canvas.discardActiveObject();
      this.renderAll();
      this.fireEvent('object:modified');
    }
  }

  deleteAll() {
    this.canvas.getObjects().forEach((object) => this.canvas.remove(object));
    this.canvas.discardActiveObject();
    this.canvas.renderAll();
    this.renderAll();
  }

  canUndo() {
    return this.canvas.canUndo();
  }

  undo() {
    this.canvas.undo(() => {
      this.canvas.backgroundColor = this.options.backgroundColor;
      // drawLimitBox(this.canvas, width, height, accentColor);
    });
  }

  canRedo() {
    return this.canvas.canRedo();
  }

  redo() {
    this.canvas.redo();
  }

  clearHistory() {
    this.canvas.clearHistory();
  }

  copy() {
    const self = this;
    const activeObject = this.canvas.getActiveObject();
    if (activeObject.fieldMetadata) {
      if (activeObject.fieldMetadata.isTemplateField) {
        return;
      }
    } else {
      if (activeObject._objects) {
        let shouldContinue = true;
        activeObject._objects.forEach((obj) => {
          if (obj.fieldMetadata.isTemplateField) {
            shouldContinue = false;
          }
        });
        if (!shouldContinue) {
          return;
        }
      } else {
        if (activeObject.fieldMetadata.isTemplateField) {
          return;
        }
      }
    }
    this.canvas.getActiveObject().clone(
      function (cloned) {
        self._clipboard = cloned;
      },
      ['fieldMetadata'],
    );
  }

  paste() {
    const self = this;
    if (this._clipboard) {
      // clone again, so you can do multiple copies.
      this._clipboard.clone(
        function (clonedObj) {
          self.canvas.discardActiveObject();
          clonedObj.set({
            left: clonedObj.left + 10,
            top: clonedObj.top + 10,
            evented: true,
          });
          if (clonedObj.type === 'activeSelection') {
            // active selection needs a reference to the canvas.
            clonedObj.canvas = self.canvas;
            clonedObj.forEachObject(function (obj) {
              self.canvas.add(obj);
            });
            // this should solve the unselectability
            clonedObj.setCoords();
          } else {
            self.canvas.add(clonedObj);
          }
          self._clipboard.top += 10;
          self._clipboard.left += 10;
          self.canvas.setActiveObject(clonedObj);
          self.canvas.requestRenderAll();
          self.canvas.fire('object:modified');
        },
        ['fieldMetadata'],
      );
    }
  }

  scaleCanvasToRatio(newRatio) {
    this.scaleMultiplier = newRatio;
    scaleToRatio(this.canvas, newRatio);
  }

  setZoom(zoom, noCallback = false) {
    setZoomValue(
      this.canvas,
      zoom,
      noCallback ? undefined : this.updateZoomCallback,
    );
  }

  setZoomWheel(zoom) {
    setZoomWheel(this.canvas, zoom, this.updateZoomCallback);
  }

  resetZoom() {
    resetZoom(this.canvas, this.updateZoomCallback);
  }

  keyboardZoomIn() {
    zoomInKeyboard(this.canvas, this.updateZoomCallback);
  }

  keyboardZoomOut() {
    zoomOutKeyboard(this.canvas, this.updateZoomCallback);
  }

  fitToScreen(canvasOffset) {
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;
    const canvasWidth = this.canvas.originalW;
    const canvasHeight = this.canvas.originalH;
    const fitWidth = windowWidth - 48 - canvasOffset;
    const fitHeight = windowHeight - 48 * 2 - canvasOffset;
    let fitZoom;
    if (fitWidth < fitHeight) {
      fitZoom = fitWidth / canvasWidth;
      const newHeight = canvasHeight * fitZoom;
      if (newHeight > fitHeight) {
        fitZoom = fitHeight / canvasHeight;
      }
    } else {
      fitZoom = fitHeight / canvasHeight;
      const newWidth = canvasWidth * fitZoom;
      if (newWidth > fitWidth) {
        fitZoom = fitWidth / canvasWidth;
      }
    }
    /*if (this.canvas.getZoom() === fitZoom) {
      this.setZoom(this.originalZoomValue);
      this.fitScreenText = 'Fit to screen';
      this.fitScreenIcon = 'fullScreen';
    } else {
      this.originalZoomValue = this.canvas.getZoom();
      this.setZoom(fitZoom);
      this.fitScreenIcon = 'fullScreenExit';
      this.fitScreenText = 'Zoom back';
    }
    this.fitZoomValue = fitZoom;*/
    this.setZoom(fitZoom);
  }

  renderAll() {
    this.canvas.renderAll();
  }

  fireEvent(eventName) {
    this.canvas.fire(eventName);
  }

  toJSON() {
    const results = this.canvas.toJSON(['fieldMetadata']);
    results.objects = results.objects.filter(
      (object) => !EXPORT_IGNORE_FIELDS.includes(object.fieldMetadata.type),
    );
    return results;
  }

  toPNG() {
    return this.canvas.toDataURL({
      type: 'image/png',
      quality: 0.8,
    });
  }

  loadFromJson(jsonData, callback = null) {
    this.canvas.loadFromJSON(jsonData, () => {
      this.clearHistory();
      this.renderAll();
      if (callback) {
        callback();
      }
    });
  }
}

export default Editor;
