/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import {
  EuiBadge,
  EuiButton,
  EuiFlyout,
  EuiFlyoutBody,
  EuiFlyoutHeader,
  EuiHeaderAlert,
  EuiHeaderSectionItemButton,
  EuiIcon,
  EuiLink,
  EuiPortal,
  EuiTitle,
  useGeneratedHtmlId,
  EuiToolTip,
  EuiSpacer,
} from '@elastic/eui';
import useNotificationProvider from 'lib/providers/notificationsProvider';
import moment from 'moment';
import { API_MAPPING } from 'lib/api';
import { useNavigate } from 'react-router-dom';
import useAuth from 'lib/providers/authProvider';
import useGlobalProvider from 'lib/providers/globalProvider';
import { css } from '@emotion/react';
import { useQueryClient } from '@tanstack/react-query';

const notificationToAlert = (
  notification,
  apiClient,
  setMessage,
  setRefreshNotifications,
  navigate,
  queryClient,
) => ({
  title: notification.title,
  text: notification.text,
  badge: (
    <EuiBadge color={notification.opened ? 'default' : 'success'}>
      {notification.opened ? 'Read' : 'New'}
    </EuiBadge>
  ),
  date: moment(notification.createdAt).format('D MMM YYYY HH:mm:ss'),
  action: defineNotificationAction(
    notification.action,
    apiClient,
    setMessage,
    setRefreshNotifications,
    notification.title,
    notification.code,
    navigate,
    queryClient,
  ),
  opened: notification.opened,
});

const defineNotificationAction = (
  notificationAction,
  apiClient,
  setMessage,
  setRefreshNotifications,
  title,
  notificationCode,
  navigate,
  queryClient,
) => {
  let notificationText;
  switch (notificationAction.type) {
    case 'api-action':
      const onClickHandler = async () => {
        await API_MAPPING[notificationAction.link](
          apiClient,
          notificationAction.linkParams,
          setMessage,
          queryClient,
        );
        setRefreshNotifications((prevValue) => !prevValue);
      };
      if (notificationAction.enabled) {
        notificationText = (
          <EuiLink
            onClick={onClickHandler}
            disabled={!notificationAction.enabled}
          >
            {notificationAction.text}
          </EuiLink>
        );
      } else {
        notificationText = null;
      }
      break;
    case 'internal-link':
      notificationText = (
        <EuiLink
          onClick={async () => {
            await apiClient.notificationsUsersControllerUpdate(
              notificationCode,
              { opened: true },
            );
            setRefreshNotifications((prevValue) => !prevValue);
            navigate(notificationAction.link);
          }}
        >
          {notificationAction.text}
        </EuiLink>
      );
      break;
    case 'external-link':
      notificationText = (
        <EuiLink
          target="_blank"
          external
          href={notificationAction.link}
          onClick={async () => {
            await apiClient.notificationsUsersControllerUpdate(
              notificationCode,
              { opened: true },
            );
            setRefreshNotifications((prevValue) => !prevValue);
          }}
        >
          {notificationAction.text}
        </EuiLink>
      );
      break;
    default:
      notificationText = 'No action required';
  }
  if (notificationAction.enabled) {
    notificationText = (
      <EuiToolTip position="top" content={title}>
        {notificationText}
      </EuiToolTip>
    );
  }
  return notificationText;
};

function NotificationMenu() {
  const {
    notifications,
    newNotifications,
    setRefreshNotifications,
    setLoadMoreCounter,
    disableLoadMore,
  } = useNotificationProvider();
  const { apiClient, user } = useAuth();
  const { setMessage } = useGlobalProvider();
  const [isFlyoutVisible, setIsFlyoutVisible] = useState(false);
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const notificationFeedFlyoutId = useGeneratedHtmlId({
    prefix: 'notificationFeedFlyout',
  });
  const notificationFeedFlyoutTitleId = useGeneratedHtmlId({
    prefix: 'notificationFeedFlyoutTitle',
  });

  const closeFlyout = () => {
    setIsFlyoutVisible(false);
  };

  const showFlyout = () => {
    setIsFlyoutVisible(!isFlyoutVisible);
  };

  return (
    <>
      <NotificationButton
        isFlyoutVisible={isFlyoutVisible}
        showFlyout={showFlyout}
        newNotifications={newNotifications}
      />
      {isFlyoutVisible ? (
        <NotificationPanel
          closeFlyout={closeFlyout}
          notificationFeedFlyoutId={notificationFeedFlyoutId}
          notificationFeedFlyoutTitleId={notificationFeedFlyoutTitleId}
          notifications={notifications.map((notification) =>
            notificationToAlert(
              notification,
              apiClient,
              setMessage,
              setRefreshNotifications,
              navigate,
              queryClient,
            ),
          )}
          setLoadMoreCounter={setLoadMoreCounter}
          disableLoadMore={disableLoadMore}
        />
      ) : null}
    </>
  );
}

const NotificationButton = ({
  isFlyoutVisible,
  showFlyout,
  newNotifications,
}) => (
  <EuiHeaderSectionItemButton
    aria-controls="headerFlyoutNotificationFeed"
    aria-expanded={isFlyoutVisible}
    aria-haspopup="true"
    aria-label={'Notification feed: Check it out'}
    onClick={() => showFlyout()}
    notification={newNotifications}
  >
    <EuiIcon type="bell" />
  </EuiHeaderSectionItemButton>
);

const NotificationPanel = ({
  notifications,
  closeFlyout,
  notificationFeedFlyoutId,
  notificationFeedFlyoutTitleId,
  setLoadMoreCounter,
  disableLoadMore,
}) => (
  <EuiPortal>
    <EuiFlyout
      onClose={closeFlyout}
      size="s"
      id={notificationFeedFlyoutId}
      aria-labelledby={notificationFeedFlyoutTitleId}
    >
      <EuiFlyoutHeader hasBorder>
        <EuiTitle size="s">
          <h2 id={notificationFeedFlyoutTitleId}>What&apos;s new</h2>
        </EuiTitle>
      </EuiFlyoutHeader>
      <EuiFlyoutBody>
        {notifications.map((alert, i) => (
          <EuiHeaderAlert
            key={`alert-${i}`}
            title={alert.title}
            action={alert.action}
            text={alert.text}
            date={alert.date}
            badge={alert.badge}
            css={css`
              padding-bottom: 8px !important;
              .euiHeaderAlert__action {
                margin-bottom: 8px !important;
              }
            `}
          />
        ))}
        {disableLoadMore ? null : (
          <>
            <EuiSpacer />
            <div
              css={css`
                text-align: center;
              `}
            >
              <EuiButton
                color="primary"
                onClick={() => setLoadMoreCounter((prevState) => prevState + 1)}
              >
                Load more
              </EuiButton>
            </div>
          </>
        )}
      </EuiFlyoutBody>
      {/*<EuiFlyoutFooter>*/}
      {/*  <EuiFlexGroup justifyContent="flexEnd" alignItems="center">*/}
      {/*    <EuiFlexItem grow={false}>*/}
      {/*      <EuiButtonEmpty iconType="cross" onClick={closeFlyout} flush="left">*/}
      {/*        Close*/}
      {/*      </EuiButtonEmpty>*/}
      {/*    </EuiFlexItem>*/}
      {/*    /!*<EuiFlexItem grow={false}>*!/*/}
      {/*    /!*  <EuiText color="subdued" size="s">*!/*/}
      {/*    /!*    <p>Version 7.0</p>*!/*/}
      {/*    /!*  </EuiText>*!/*/}
      {/*    /!*</EuiFlexItem>*!/*/}
      {/*  </EuiFlexGroup>*/}
      {/*</EuiFlyoutFooter>*/}
    </EuiFlyout>
  </EuiPortal>
);

export default NotificationMenu;
