import DimensionsAction from 'components/Creator/CreatorCanvas/CanvasHeader/UtilityHeaderComponents/ActionsComponents/DimensionsAction';

function ImageObjectActions({ objects: object }) {
  return (
    <DimensionsAction
      objects={object}
      lockRatioDisabled={false}
      initialLockRatio={false}
    />
  );
}

export default ImageObjectActions;
