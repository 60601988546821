import {
  EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiSwitch,
  useEuiI18n,
} from '@elastic/eui';
import CountrySelect from 'components/Profile/ProfileForm/CountrySelect';
import { REDUCER_ACTIONS } from 'components/Profile/ProfileForm/index';
import LabelHelper from 'components/Profile/ProfileForm/LabelHelper';
import { useEffect } from 'react';

const DEFAULT_INVOICE_RECIPIENT_CODE = 'XXXXXXX';

export const billingDataDefaultState = {
  email: {
    value: '',
    isInvalid: false,
    errors: [],
    label: 'Email',
    is_required: true,
    edit_disabled: false,
  },
  is_company: {
    value: false,
    isInvalid: false,
    errors: [],
    label: 'Switch between company and private billing address',
    is_required: false,
    edit_disabled: false,
  },
  first_name: {
    value: '',
    isInvalid: false,
    errors: [],
    label: 'First name',
    is_required: true,
    edit_disabled: false,
  },
  last_name: {
    value: '',
    isInvalid: false,
    errors: [],
    label: 'Last name',
    is_required: true,
    edit_disabled: false,
  },
  company_name: {
    value: '',
    isInvalid: false,
    errors: [],
    label: 'Company name',
    is_required: true,
    edit_disabled: false,
  },
  tax_code: {
    value: '',
    isInvalid: false,
    errors: [],
    label: 'Tax code',
    is_required: true,
    edit_disabled: false,
  },
  country: {
    value: '',
    isInvalid: false,
    errors: [],
    label: 'Country',
    is_required: true,
    edit_disabled: false,
  },
  invoice_recipient_code: {
    value: '',
    isInvalid: false,
    errors: [],
    label: 'Invoice recipient code',
    is_required: true,
    edit_disabled: false,
  },
  address: {
    value: '',
    isInvalid: false,
    errors: [],
    label: 'Address',
    is_required: true,
    edit_disabled: false,
  },
  city: {
    value: '',
    isInvalid: false,
    errors: [],
    label: 'City',
    is_required: true,
    edit_disabled: false,
  },
  postcode: {
    value: '',
    isInvalid: false,
    errors: [],
    label: 'Postcode',
    is_required: true,
    edit_disabled: false,
  },
};

export function billingDataReducer(state, action) {
  switch (action.type) {
    case REDUCER_ACTIONS.change: {
      return {
        ...state,
        [action.field]: {
          ...state[action.field],
          value: action.value,
          isInvalid: false,
          errors: [],
        },
      };
    }
    case REDUCER_ACTIONS.error: {
      return {
        ...state,
        [action.field]: {
          ...state[action.field],
          isInvalid: true,
          errors: action.errors,
        },
      };
    }
    case REDUCER_ACTIONS.reset: {
      return {
        ...initBillingDataState({ user: action.user, isEdit: action.isEdit }),
      };
    }
    default: {
      throw Error('Unknown action: ' + action.type);
    }
  }
}

export function initBillingDataState({ user, isEdit }) {
  const state = {};
  Object.keys(billingDataDefaultState).forEach((key) => {
    if (user && isEdit) {
      state[key] = {
        ...billingDataDefaultState[key],
        value: user.billing_address[key],
      };
    } else {
      state[key] = billingDataDefaultState[key];
    }
  });
  return state;
}

export const billingValidationRules = {
  email: ['required', 'email'],
  is_company: ['required', 'boolean'],
  first_name: [{ required_if: ['is_company', false] }],
  last_name: [{ required_if: ['is_company', false] }],
  company_name: [{ required_if: ['is_company', true] }],
  tax_code: ['required'],
  country: ['required'],
  invoice_recipient_code: ['required'],
  address: ['required'],
  city: ['required'],
  postcode: ['required', 'isPostalCode'],
};

function BillingForm({
  handleBillingDataChange,
  email,
  isCompany,
  firstName,
  lastName,
  companyName,
  taxCode,
  country,
  invoiceRecipientCode,
  address,
  city,
  postcode,
  isReset,
  isEdit,
}) {
  const vatLabel = useEuiI18n('VAT', 'VAT');
  const taxIdLabel = useEuiI18n('Tax ID', 'Tax ID');

  useEffect(() => {
    if (country.value !== 'IT') {
      handleBillingDataChange(
        'invoice_recipient_code',
        DEFAULT_INVOICE_RECIPIENT_CODE,
      );
    } else {
      if (invoiceRecipientCode.value === DEFAULT_INVOICE_RECIPIENT_CODE) {
        handleBillingDataChange('invoice_recipient_code', '');
      }
    }
  }, [country]);

  return (
    <>
      <EuiFormRow fullWidth>
        <EuiFlexGroup>
          <EuiFlexItem>
            <EuiFormRow
              label={<LabelHelper field={email} isEdit={isEdit} />}
              isDisabled={isEdit && email.edit_disabled}
              isInvalid={email.isInvalid}
              error={email.errors}
              fullWidth
            >
              <EuiFieldText
                fullWidth
                type={'email'}
                name="billing_address:email"
                value={email.value}
                isInvalid={email.isInvalid}
                onChange={(e) =>
                  handleBillingDataChange('email', e.target.value)
                }
              />
            </EuiFormRow>
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiFormRow
              fullWidth
              label={<LabelHelper field={isCompany} isEdit={isEdit} />}
              isDisabled={isEdit && isCompany.edit_disabled}
              hasChildLabel={false}
            >
              <EuiSwitch
                name="billing_address:is_company"
                label={isCompany.value ? 'Company address' : 'Private address'}
                checked={isCompany.value}
                onChange={(e) =>
                  handleBillingDataChange('is_company', !isCompany.value)
                }
              />
            </EuiFormRow>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFormRow>

      <EuiFormRow fullWidth>
        <EuiFlexGroup>
          <EuiFlexItem>
            {isCompany.value ? (
              <EuiFormRow
                fullWidth
                label={<LabelHelper field={companyName} isEdit={isEdit} />}
                isDisabled={isEdit && companyName.edit_disabled}
                error={companyName.errors}
              >
                <EuiFieldText
                  fullWidth
                  name="company_name"
                  value={companyName.value}
                  isInvalid={companyName.isInvalid}
                  onChange={(e) =>
                    handleBillingDataChange('company_name', e.target.value)
                  }
                />
              </EuiFormRow>
            ) : (
              <EuiFormRow
                fullWidth
                label={<LabelHelper field={firstName} isEdit={isEdit} />}
                isDisabled={isEdit && firstName.edit_disabled}
                isInvalid={firstName.isInvalid}
                error={firstName.errors}
              >
                <EuiFieldText
                  fullWidth
                  name="first_name"
                  value={firstName.value}
                  isInvalid={firstName.isInvalid}
                  onChange={(e) =>
                    handleBillingDataChange('first_name', e.target.value)
                  }
                />
              </EuiFormRow>
            )}
          </EuiFlexItem>
          {isCompany.value ? null : (
            <EuiFlexItem>
              <EuiFormRow
                fullWidth
                label={<LabelHelper field={lastName} isEdit={isEdit} />}
                isDisabled={isEdit && lastName.edit_disabled}
                isInvalid={lastName.isInvalid}
                error={lastName.errors}
              >
                <EuiFieldText
                  fullWidth
                  name="last_name"
                  value={lastName.value}
                  isInvalid={lastName.isInvalid}
                  onChange={(e) =>
                    handleBillingDataChange('last_name', e.target.value)
                  }
                />
              </EuiFormRow>
            </EuiFlexItem>
          )}
        </EuiFlexGroup>
      </EuiFormRow>

      <EuiFormRow fullWidth>
        <EuiFlexGroup>
          <EuiFlexItem>
            <EuiFormRow
              fullWidth
              label={`${isCompany.value ? vatLabel : taxIdLabel}${
                !isEdit && taxCode.is_required ? ' *' : ''
              }`}
              isDisabled={isEdit && taxCode.edit_disabled}
              isInvalid={taxCode.isInvalid}
              error={taxCode.errors}
            >
              <EuiFieldText
                fullWidth
                name="tax_code"
                value={taxCode.value}
                isInvalid={taxCode.isInvalid}
                onChange={(e) =>
                  handleBillingDataChange('tax_code', e.target.value)
                }
              />
            </EuiFormRow>
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiFormRow
              label={
                <LabelHelper field={invoiceRecipientCode} isEdit={isEdit} />
              }
              fullWidth
              isDisabled={isEdit && invoiceRecipientCode.edit_disabled}
              isInvalid={invoiceRecipientCode.isInvalid}
              error={invoiceRecipientCode.errors}
              helpText="Code used for italian electronic bill"
            >
              <EuiFieldText
                fullWidth
                name="invoice_recipient_code"
                value={invoiceRecipientCode.value}
                isInvalid={invoiceRecipientCode.isInvalid}
                onChange={(e) =>
                  handleBillingDataChange(
                    'invoice_recipient_code',
                    e.target.value,
                  )
                }
              />
            </EuiFormRow>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFormRow>

      <EuiFormRow
        fullWidth
        label={<LabelHelper field={country} isEdit={isEdit} />}
        isDisabled={isEdit && country.edit_disabled}
      >
        <CountrySelect
          fullWidth={true}
          country={country.value}
          setCountry={(value) => handleBillingDataChange('country', value)}
          isReset={isReset}
        />
      </EuiFormRow>

      <EuiFormRow fullWidth>
        <EuiFlexGroup>
          <EuiFlexItem>
            <EuiFormRow
              fullWidth
              label={<LabelHelper field={city} isEdit={isEdit} />}
              isDisabled={isEdit && city.edit_disabled}
              isInvalid={city.isInvalid}
              error={city.errors}
            >
              <EuiFieldText
                name="city"
                fullWidth
                value={city.value}
                isInvalid={city.isInvalid}
                onChange={(e) =>
                  handleBillingDataChange('city', e.target.value)
                }
              />
            </EuiFormRow>
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiFormRow
              fullWidth
              label={<LabelHelper field={postcode} isEdit={isEdit} />}
              isDisabled={isEdit && postcode.edit_disabled}
              isInvalid={postcode.isInvalid}
              error={postcode.errors}
            >
              <EuiFieldText
                fullWidth
                name="postcode"
                value={postcode.value}
                isInvalid={postcode.isInvalid}
                onChange={(e) =>
                  handleBillingDataChange('postcode', e.target.value)
                }
              />
            </EuiFormRow>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFormRow>

      <EuiFormRow
        fullWidth
        label={<LabelHelper field={address} isEdit={isEdit} />}
        isDisabled={isEdit && address.edit_disabled}
        isInvalid={address.isInvalid}
        error={address.errors}
      >
        <EuiFieldText
          fullWidth
          name={'address'}
          value={address.value}
          isInvalid={address.isInvalid}
          onChange={(e) => handleBillingDataChange('address', e.target.value)}
        />
      </EuiFormRow>
    </>
  );
}

export default BillingForm;
