/** @jsxImportSource @emotion/react */
import useCanvas from 'lib/providers/canvasProvider';
import { useEffect, useState } from 'react';
import {
  EuiFieldNumber,
  EuiHeaderSectionItem,
  EuiIcon,
  EuiSwitch,
  EuiToolTip,
} from '@elastic/eui';
import { css } from '@emotion/react';

function DimensionsAction({
  objects: object,
  initialLockRatio = false,
  lockRatioDisabled = false,
}) {
  const { editor, activeObjectUpdated } = useCanvas();
  const [width, setWidth] = useState(
    editor.getWidthFromScale(object[0]).toFixed(0),
  );
  const [height, setHeight] = useState(
    editor.getHeightFromScale(object[0]).toFixed(0),
  );
  const [lockRatio, setLockRatio] = useState(initialLockRatio);

  useEffect(() => {
    setWidth(editor.getWidthFromScale(object[0]).toFixed(0));
    setHeight(editor.getHeightFromScale(object[0]).toFixed(0));
  }, [object, activeObjectUpdated]);

  const handleWidthChange = (e) => {
    const value = parseFloat(e.target.value);
    if (!isNaN(value)) {
      editor.setActiveStyle('scaleX', (value * object[0].scaleX) / width);
      setWidth(value.toFixed(0));
      if (lockRatio) {
        const ratio = width / height;
        const heightValue = value / ratio;
        editor.setActiveStyle(
          'scaleY',
          (heightValue * object[0].scaleY) / height,
        );
        setHeight(heightValue.toFixed(0));
      }
    }
  };

  const handleHeightChange = (e) => {
    const value = parseFloat(e.target.value);
    if (!isNaN(value)) {
      editor.setActiveStyle('scaleY', (value * object[0].scaleY) / height);
      setHeight(value.toFixed(0));
      if (lockRatio) {
        const ratio = height / width;
        const widthValue = value / ratio;
        editor.setActiveStyle(
          'scaleX',
          (widthValue * object[0].scaleX) / width,
        );
        setWidth(widthValue.toFixed(0));
      }
    }
  };

  const handleRatioLockChange = (e) => {
    setLockRatio(e.target.checked);
  };

  const fieldWidth = css`
    width: 120px !important;
  `;

  return (
    <>
      <EuiHeaderSectionItem
        css={css`
          margin-right: 4px;
        `}
      >
        <EuiFieldNumber
          compressed
          css={css`
            ${fieldWidth};
          `}
          value={width}
          onChange={(e) => handleWidthChange(e)}
          aria-label="Width"
        />
      </EuiHeaderSectionItem>
      <EuiHeaderSectionItem
        css={css`
          margin-right: 4px;
        `}
      >
        <EuiToolTip
          position={'bottom'}
          content={lockRatio ? 'Unlock ratio' : 'Lock ratio'}
        >
          <EuiSwitch
            compressed={true}
            label={<EuiIcon type={lockRatio ? 'lock' : 'lockOpen'} />}
            showLabel={true}
            disabled={lockRatioDisabled}
            aria-label={'Lock ratio'}
            checked={lockRatio}
            onChange={(e) => handleRatioLockChange(e)}
          />
        </EuiToolTip>
      </EuiHeaderSectionItem>
      <EuiHeaderSectionItem
        css={css`
          margin-right: 4px;
        `}
      >
        <EuiFieldNumber
          css={css`
            ${fieldWidth};
          `}
          compressed
          value={height}
          onChange={(e) => handleHeightChange(e)}
          aria-label="Height"
        />
      </EuiHeaderSectionItem>
    </>
  );
}

export default DimensionsAction;
