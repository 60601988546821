import { fabric } from 'fabric';
import {
  CUSTOM_TEXT_BOX_FIELD,
  CUSTOM_TEXT_FIELD,
} from 'lib/templateFieldsUtils';
import { preventResizing } from 'lib/canvasEditor/dist/drawing/object_utils';
import {
  alignObject,
  getCenterLeftPosition,
  getCenterTopPosition,
  setObjectInitialPosition,
} from 'lib/canvasEditor/dist/utils';

export async function addText(canvas, text, options = {}) {
  const textObject = new fabric.Text(text, {
    top: 10,
    left: 10,
    scaleX: 1,
    scaleY: 1,
    fill: '#000000',
    fontSize: 24,
    fontFamily: 'Helvetica',
    strokeUniform: true,
    noScaleCache: false,
    lockScalingX: true,
    lockScalingY: true,
    lockSkewingX: true,
    lockSkewingY: true,
    fieldMetadata: { type: CUSTOM_TEXT_FIELD, isTemplateField: false },
    ...options,
  });
  textObject.set('top', await getCenterTopPosition(canvas, textObject));
  textObject.set('left', await getCenterLeftPosition(canvas, textObject));
  if (options.editable === false) {
    preventResizing(textObject);
  }
  canvas.add(textObject);
  setObjectInitialPosition(canvas, textObject);
  return textObject;
}

export async function addTextBox(canvas, text, options = {}) {
  const textAreaObject = new fabric.Textbox(text, {
    top: 10,
    left: 10,
    scaleX: 1,
    scaleY: 1,
    fill: '#000000',
    fontFamily: 'Helvetica',
    fontSize: 24,
    strokeUniform: true,
    noScaleCache: false,
    fieldMetadata: { type: CUSTOM_TEXT_BOX_FIELD, isTemplateField: false },
    ...options,
  });
  if (options.editable === false) {
    preventResizing(textAreaObject);
  }
  textAreaObject.set('top', await getCenterTopPosition(canvas, textAreaObject));
  textAreaObject.set(
    'left',
    await getCenterLeftPosition(canvas, textAreaObject),
  );
  canvas.add(textAreaObject);
  setObjectInitialPosition(canvas, textAreaObject);
  return textAreaObject;
}
