/** @jsxImportSource @emotion/react */
import useCanvas from 'lib/providers/canvasProvider';
import ActionNumberInput from 'components/Creator/CreatorCanvas/CanvasHeader/UtilityHeaderComponents/ActionNumberInput';
import { useEffect, useState } from 'react';
import { EuiHeaderSectionItem } from '@elastic/eui';
import { css } from '@emotion/react';

function AngleAction() {
  const { editor, activeObjectUpdated } = useCanvas();
  const activeObject = editor?.getActiveObject();
  const [angle, setAngle] = useState(
    activeObject ? activeObject.angle.toFixed(0).toString() : '0',
  );

  useEffect(() => {
    if (activeObject) {
      setAngle(activeObject.angle.toFixed(0).toString());
    } else {
      setAngle('0');
    }
  }, [activeObject, activeObjectUpdated]);

  const handleRotate = (value) => {
    try {
      editor.rotateActiveObject(value);
      setAngle(value.toString());
    } catch (e) {}
  };

  return (
    <EuiHeaderSectionItem
      css={css`
        margin-left: 4px;
      `}
    >
      <ActionNumberInput
        label={'Rotate'}
        step={1}
        compressed={true}
        value={angle}
        onChange={handleRotate}
        max={360}
        min={-360}
        width={50}
      />
    </EuiHeaderSectionItem>
  );
}

export default AngleAction;
