/** @jsxImportSource @emotion/react */
import {
  EuiHeader,
  EuiHeaderSection,
  EuiHeaderSectionItem,
  EuiHeaderSectionItemButton,
  EuiIcon,
} from '@elastic/eui';
import { css } from '@emotion/react';
import {
  panelWidthClosed,
  panelWidthExpanded,
} from 'components/Creator/CreatorCanvas/CanvasPanel';
import useCanvas from 'lib/providers/canvasProvider';
import DefaultActions from 'components/Creator/CreatorCanvas/CanvasHeader/UtilityHeaderComponents/DefaultActions';
import { useMemo, useState } from 'react';
import ShapeObjectActions from 'components/Creator/CreatorCanvas/CanvasHeader/UtilityHeaderComponents/ShapeObjectActions';
import TextObjectActions from 'components/Creator/CreatorCanvas/CanvasHeader/UtilityHeaderComponents/TextObjectActions';
import BarcodeObjectActions from 'components/Creator/CreatorCanvas/CanvasHeader/UtilityHeaderComponents/BarcodeObjectActions';
import ImageObjectActions from 'components/Creator/CreatorCanvas/CanvasHeader/UtilityHeaderComponents/ImageObjectActions';
import LineObjectActions from 'components/Creator/CreatorCanvas/CanvasHeader/UtilityHeaderComponents/LineObjectActions';
import { hideScrollBar } from 'lib/cssUtils';

const MULTIPLE_HANDLING_TYPES = ['shape', 'line', 'text'];

function UtilityHeader({ isCanvasPanelExtended }) {
  const { isMobile, editor, selectedObjects, activeObjectUpdated } =
    useCanvas();
  const [objectSelected, setObjectSelected] = useState(null);

  const ObjectActions = useMemo(() => {
    let objectType = null;
    if (selectedObjects.length >= 1) {
      const validObjects = selectedObjects.filter((obj) => !obj.exportIgnore);
      // this work only if a single element is selected
      switch (validObjects.length) {
        case 0:
          setObjectSelected(null);
          break;
        case 1:
          setObjectSelected([validObjects[0]]);
          objectType = editor.getObjectType(validObjects[0]);
          break;
        default:
          const selectedObjectTypes = {};
          let type;
          validObjects.forEach((obj) => {
            type = editor.getObjectType(obj);
            if (MULTIPLE_HANDLING_TYPES.includes(type)) {
              if (selectedObjectTypes.hasOwnProperty(type)) {
                selectedObjectTypes[type]++;
              } else {
                selectedObjectTypes[type] = 1;
              }
            }
          });
          if (selectedObjectTypes[type] === validObjects.length) {
            setObjectSelected(validObjects);
            objectType = type;
          } else {
            setObjectSelected(null);
          }
      }
    } else {
      setObjectSelected(null);
    }
    switch (objectType) {
      case 'shape':
        return ShapeObjectActions;
      case 'line':
        return LineObjectActions;
      case 'text':
        return TextObjectActions;
      case 'barcode':
        return BarcodeObjectActions;
      case 'image':
        return ImageObjectActions;
      default:
        return null;
    }
  }, [selectedObjects, activeObjectUpdated]);

  const marginLeft = isCanvasPanelExtended
    ? `${panelWidthExpanded}px`
    : `${panelWidthClosed}px`;

  return (
    <EuiHeader
      position={'static'}
      css={css`
        position: fixed !important;
        margin-left: ${marginLeft};
        width: 100%;
        ${isMobile
          ? css`
              bottom: 0;
              overflow-x: auto;
              padding-right: 60px;
              ${hideScrollBar()}
              .euiHeaderSectionItem {
                margin-right: 10px !important;
              }
            `
          : 'top: 48px'}
      `}
    >
      <EuiHeaderSection grow={false}>
        {ObjectActions ? <ObjectActions objects={objectSelected} /> : null}
        {selectedObjects.length > 0 ? <DefaultActions /> : null}
      </EuiHeaderSection>
    </EuiHeader>
  );
}

export default UtilityHeader;
