export function keepAspectRatio(obj) {
  obj.setControlsVisibility({
    mt: false, // middle top
    mb: false, // middle bottom
    ml: false, // middle left
    mr: false, // middle right
    tl: true, //top left
    tr: true, //top right
    bl: true, //bottom left
    br: true, //bottom right
    mtr: true, // rotate
  });
}

export function preventResizing(obj) {
  obj.setControlsVisibility({
    mt: false, // middle top
    mb: false, // middle bottom
    ml: false, // middle left
    mr: false, // middle right
    tl: false, //top left
    tr: false, //top right
    bl: false, //bottom left
    br: false, //bottom right
    mtr: true, // rotate
  });
}

export function preventLineResizing(obj) {
  obj.setControlsVisibility({
    mt: false, // middle top
    mb: false, // middle bottom
    ml: false, // middle left
    mr: false, // middle right
    tl: true, //top left
    tr: true, //top right
    bl: true, //bottom left
    br: true, //bottom right
    mtr: true, // rotate
  });
}
