/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import PlanSummaryViewer from 'components/Plans/PlanSummaryViewer';
import SideUserMenu from 'layout/PageHeader/SideUserMenu';
import useOptionsProvider from 'lib/providers/optionsProvider';
import React, { useState } from 'react';
import {
  EuiAvatar,
  EuiFlexGroup,
  EuiFlexItem,
  EuiHeaderSectionItemButton,
  EuiHorizontalRule,
  EuiI18n,
  EuiLink,
  EuiPopover,
  EuiSpacer,
  EuiText,
  useEuiMinBreakpoint,
  useGeneratedHtmlId,
} from '@elastic/eui';
import { capitalize } from 'lib/utils';
import { getProfileMenuPages, getLogoutPage } from 'lib/data/pages';
import { useNavigate } from 'react-router-dom';

function UserMenu({ user, logout }) {
  const userPopoverId = useGeneratedHtmlId({ prefix: 'userPopover' });
  const { appInfoOptions } = useOptionsProvider();
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const profilePages = getProfileMenuPages();
  const logoutPage = getLogoutPage();

  const onMenuButtonClick = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  const handleLinkNavigation = (route) => {
    navigate(route);
    closeMenu();
  };

  const userFullName = user
    ? user.is_company
      ? `${capitalize(user.company_name)}`
      : `${capitalize(user.first_name)} ${capitalize(user.last_name)}`
    : '';

  const button = (
    <EuiHeaderSectionItemButton
      aria-controls={userPopoverId}
      aria-expanded={isOpen}
      aria-haspopup="true"
      aria-label="Account menu"
      onClick={onMenuButtonClick}
    >
      <EuiAvatar name={userFullName} size="s" />
    </EuiHeaderSectionItemButton>
  );

  return (
    <EuiPopover
      id={userPopoverId}
      repositionOnScroll
      button={button}
      isOpen={isOpen}
      anchorPosition="downRight"
      closePopover={closeMenu}
      panelPaddingSize="m"
    >
      <div>
        <EuiFlexGroup gutterSize="l" responsive={false} alignItems="center">
          <EuiFlexItem grow={false}>
            <EuiAvatar name={userFullName} size="xl" />
          </EuiFlexItem>

          <EuiFlexItem>
            <EuiText>
              <p className="hello-label_header-font">{userFullName}</p>
            </EuiText>

            <EuiSpacer size="m" />

            <EuiFlexGroup gutterSize={'l'}>
              <EuiFlexItem>
                <EuiFlexGroup
                  justifyContent="spaceBetween"
                  alignItems="flexStart"
                >
                  <EuiFlexItem grow={false}>
                    {profilePages.map((page, index) =>
                      index < profilePages.length - 1 ? (
                        <span key={index}>
                          <EuiLink
                            className={'hello-label_header-font'}
                            onClick={() => handleLinkNavigation(page.url)}
                          >
                            <EuiI18n token={page.label} default={page.label} />
                          </EuiLink>
                          <EuiSpacer size="s" />
                        </span>
                      ) : (
                        <EuiLink
                          key={index}
                          className="hello-label_header-font"
                          onClick={() => handleLinkNavigation(page.url)}
                        >
                          <EuiI18n token={page.label} default={page.label} />
                        </EuiLink>
                      ),
                    )}
                    <EuiSpacer size="s" />
                    <EuiHorizontalRule margin={'none'} />
                    <SideUserMenu />
                  </EuiFlexItem>

                  <EuiFlexItem
                    css={css`
                      ${useEuiMinBreakpoint('m')} {
                        height: 100%;
                      }
                    `}
                    grow={false}
                  >
                    <EuiFlexGroup direction="column" gutterSize="xs">
                      <EuiFlexItem grow={true}>
                        <PlanSummaryViewer size={'xs'} />
                      </EuiFlexItem>
                      <EuiFlexItem grow={false}>
                        <EuiText size={'xs'} color={'subdued'}>
                          Powered by: {appInfoOptions.powered_by}
                        </EuiText>
                      </EuiFlexItem>
                      <EuiFlexItem grow={false}>
                        <EuiText size={'xs'} color={'subdued'}>
                          Version: {appInfoOptions.app_version}
                        </EuiText>
                      </EuiFlexItem>
                      <EuiFlexItem grow={false}>
                        <EuiHorizontalRule margin={'none'} />
                        <EuiSpacer size="s" />
                        <EuiLink
                          className="hello-label_header-font"
                          onClick={logout}
                        >
                          <EuiI18n
                            token={logoutPage.label}
                            default={logoutPage.label}
                          />
                        </EuiLink>
                      </EuiFlexItem>
                    </EuiFlexGroup>
                  </EuiFlexItem>
                </EuiFlexGroup>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexItem>
        </EuiFlexGroup>
      </div>
    </EuiPopover>
  );
}

export default UserMenu;
