import { EuiI18n } from '@elastic/eui';
import PageBanner from 'components/PageBanner';
import useOptionsProvider from 'lib/providers/optionsProvider';
import React from 'react';

export const PageError = ({ pageName }) => {
  const { appInfoOptions } = useOptionsProvider();

  return (
    <PageBanner
      title={
        <EuiI18n
          token="Unable to load your {pageName}"
          default="Unable to load your {pageName}"
          values={{ pageName }}
        />
      }
      body={
        <EuiI18n
          token="There was an error loading the {pageName}. Try to refresh the page, if the error persists, please contact support at {supportMail}."
          default="There was an error loading the {pageName}. Try to refresh the page, if the error persists, please contact support at {supportMail}."
          values={{ pageName, supportMail: appInfoOptions.support_email }}
        />
      }
    />
  );
};
