import {
  getEntityItemCacheKey,
  QUERY_KEY_MAPPING,
  usePaginatedApiCall,
} from 'lib/hooks/api/common';
import useAuth from 'lib/providers/authProvider';
import useGlobalProvider from 'lib/providers/globalProvider';
import { useQuery } from '@tanstack/react-query';
import { handleApiError } from 'lib/ErrorService';
import { useEffect } from 'react';
import { blobToDataURL } from 'lib/utils';

export function useFonts(
  { pageSize, page, query, sort, populate, useSpecialQuery },
  useSetLoading = false,
  paginationEnabled = true,
  axiosOptions = {},
) {
  const {
    isLoading,
    isFetching,
    data: fonts,
    fetchStatus,
    error,
  } = usePaginatedApiCall(
    'fontsUsersControllerFindAll',
    QUERY_KEY_MAPPING.fonts,
    { pageSize, page, query, sort, populate, useSpecialQuery },
    useSetLoading,
    paginationEnabled,
    axiosOptions,
    'Error while loading fonts',
  );

  return {
    isLoading,
    isFetching,
    fonts,
    fetchStatus,
    error,
  };
}

export function useFont(
  fontId,
  populate = [],
  useSetLoading = true,
  axiosOptions = {},
) {
  const { apiClient } = useAuth();
  const { setLoading, setMessage } = useGlobalProvider();

  const {
    isLoading,
    isFetching,
    fetchStatus,
    data: font,
    error,
  } = useQuery({
    queryKey: getEntityItemCacheKey(QUERY_KEY_MAPPING.fonts, fontId),
    queryFn: fetchQuery,
    staleTime: 60 * 1000 * 10, // 10 minute
    enabled: !!fontId && !!apiClient,
    onError: (err) =>
      handleApiError(
        err,
        setMessage,
        `Error while loading font with id ${fontId}`,
      ),
  });

  async function fetchQuery() {
    const { data } = await apiClient.fontsUsersControllerFindOne(
      fontId,
      populate,
      axiosOptions,
    );
    return data;
  }

  useEffect(() => {
    if (useSetLoading) {
      setLoading(isLoading && fetchStatus !== 'idle', useFont.name);
    }
    return () => {
      if (isLoading && fetchStatus !== 'idle') {
        setLoading(false, useFont.name);
      }
    };
  }, [isLoading, fetchStatus]);

  return {
    font,
    isFetching,
    isLoading: isLoading && fetchStatus !== 'idle',
    error,
  };
}

export function useFontDownload(
  fontId,
  useSetLoading = true,
  axiosOptions = { responseType: 'blob' },
) {
  const { apiClient } = useAuth();
  const { setLoading, setMessage } = useGlobalProvider();
  const queryKey = getEntityItemCacheKey(QUERY_KEY_MAPPING.fonts, fontId);
  queryKey.push('download');

  const {
    isLoading,
    isFetching,
    fetchStatus,
    data: fontFile,
    error,
  } = useQuery({
    queryKey,
    retry: false,
    queryFn: fetchQuery,
    staleTime: 60 * 1000 * 10, // 10 minute
    enabled: !!fontId && !!apiClient,
    onError: (err) => {},
  });

  async function fetchQuery() {
    try {
      const { data } = await apiClient.fontsUsersControllerDownload(
        fontId,
        axiosOptions,
      );
      return await blobToDataURL(new Blob([data]));
    } catch (err) {
      return null;
    }
  }

  useEffect(() => {
    if (useSetLoading) {
      setLoading(isLoading && fetchStatus !== 'idle', useFontDownload.name);
    }
    return () => {
      if (isLoading && fetchStatus !== 'idle') {
        setLoading(false, useFontDownload.name);
      }
    };
  }, [isLoading, fetchStatus]);

  return {
    fontFile,
    isFetching,
    isLoading: isLoading && fetchStatus !== 'idle',
    error,
  };
}

export function useFontsListDownload(
  fontsList,
  useSetLoading = true,
  axiosOptions = { responseType: 'blob' },
) {
  const { apiClient } = useAuth();
  const { setLoading, setMessage } = useGlobalProvider();
  const queryKey = getEntityItemCacheKey(QUERY_KEY_MAPPING.fonts, fontsList);
  queryKey.push('download');

  const {
    isLoading,
    isFetching,
    fetchStatus,
    data: fontsListFiles,
    error,
  } = useQuery({
    queryKey,
    retry: false,
    queryFn: fetchQuery,
    staleTime: 60 * 1000 * 10, // 10 minute
    enabled: !!fontsList && fontsList.length > 0 && !!apiClient,
    onError: (err) => {},
  });

  async function fetchQuery() {
    try {
      const result = await Promise.all(
        fontsList.map(async (font) => {
          const fontId = font._id;
          const { data } = await apiClient.fontsUsersControllerDownload(
            fontId,
            axiosOptions,
          );
          const dataUrl = await blobToDataURL(new Blob([data]));
          return {
            font,
            dataUrl,
          };
        }),
      );
      return result;
    } catch (err) {
      return null;
    }
  }

  useEffect(() => {
    if (useSetLoading) {
      setLoading(isLoading && fetchStatus !== 'idle', useFontDownload.name);
    }
    return () => {
      if (isLoading && fetchStatus !== 'idle') {
        setLoading(false, useFontDownload.name);
      }
    };
  }, [isLoading, fetchStatus]);

  return {
    fontsListFiles,
    isFetching,
    isLoading: isLoading && fetchStatus !== 'idle',
    error,
  };
}

export function getFontFamilyFromFont(font) {
  return `${font.family}${font.weight.toLowerCase() === 'bold' ? ' Bold' : ''}${
    font.style.toLowerCase() === 'italic' ? ' Italic' : ''
  }`;
}
