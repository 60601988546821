import logo from 'assets/logo.svg';
import LangSwitcherFlag from 'components/LangSwitcherFlags';
import FullPageLoader from 'components/Loading/FullPageLoader';
import ThemeSwitcher from 'components/ThemeSwitcher';
import ErrorBoundary from 'lib/ErrorService/ErrorBoundary';
import React from 'react';
import {
  useEuiI18n,
  EuiPageTemplate,
  EuiHeader,
  EuiHeaderSection,
  EuiHeaderSectionItem,
  EuiHeaderLogo,
} from '@elastic/eui';

import AppMessage from 'components/AppMessage';
import useGlobalProvider from 'lib/providers/globalProvider';
import PageBanner from 'components/PageBanner';

function PageHeader() {
  return (
    <EuiHeader position="fixed">
      <EuiHeaderSection grow={false}>
        <EuiHeaderSectionItem border="right">
          <EuiHeaderLogo
            iconType={logo}
            iconTitle="Hello Label"
            onClick={(e) => e.preventDefault()}
          >
            Hello Label
          </EuiHeaderLogo>
        </EuiHeaderSectionItem>
      </EuiHeaderSection>
      <EuiHeaderSection side="right">
        <EuiHeaderSectionItem>
          <ThemeSwitcher compressed={true} />
        </EuiHeaderSectionItem>
        {/*<EuiHeaderSectionItem>
          <LangSwitcherFlag />
        </EuiHeaderSectionItem>*/}
      </EuiHeaderSection>
    </EuiHeader>
  );
}

function PublicPageLayout({ pageTitleCode, children }) {
  const { fatalError } = useGlobalProvider();
  const pageTitle = useEuiI18n(pageTitleCode, pageTitleCode);
  return (
    <EuiPageTemplate paddingSize={'l'} offset={48} restrictWidth>
      <PageHeader />
      <EuiPageTemplate.Header
        restrictWidth
        pageTitle={pageTitle}
        bottomBorder
      />
      <EuiPageTemplate.Section restrictWidth>
        {fatalError ? (
          <PageBanner title={fatalError.title} body={fatalError.body} />
        ) : (
          <ErrorBoundary>{children}</ErrorBoundary>
        )}
        <AppMessage />
        <FullPageLoader visible={true} isEmptyPage={true} />
      </EuiPageTemplate.Section>
    </EuiPageTemplate>
  );
}

export default PublicPageLayout;
