/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { EuiButton } from '@elastic/eui';

function TableFooterActions({
  selectedEntities,
  handleSelectedAction,
  selectedText,
  color = 'danger',
}) {
  if (selectedEntities.length === 0) {
    return null;
  }

  return (
    <div
      css={css`
        text-align: right;
      `}
    >
      <EuiButton
        color={color}
        onClick={() => handleSelectedAction(selectedEntities)}
      >
        {selectedText}
      </EuiButton>
    </div>
  );
}

export default TableFooterActions;
