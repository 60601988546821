import { useQuery } from '@tanstack/react-query';
import { handleApiError } from 'lib/ErrorService';
import {
  getEntityItemCacheKey,
  QUERY_KEY_MAPPING,
  usePaginatedApiCall,
} from 'lib/hooks/api/common';
import useGlobalProvider from 'lib/providers/globalProvider';
import { useEffect } from 'react';
import useAuth from 'lib/providers/authProvider';

export function usePlanUsages(
  { pageSize, page, query, sort, populate, useSpecialQuery },
  useSetLoading = true,
  paginationEnabled = true,
  axiosOptions = {},
) {
  const {
    isLoading,
    isFetching,
    data: planUsages,
    fetchStatus,
    error,
  } = usePaginatedApiCall(
    'planUsagesUsersControllerFindAll',
    QUERY_KEY_MAPPING.planUsages,
    { pageSize, page, query, sort, populate, useSpecialQuery },
    useSetLoading,
    paginationEnabled,
    axiosOptions,
    'Error while loading plans history',
  );

  return {
    isLoading,
    isFetching,
    planUsages,
    fetchStatus,
    error,
  };
}

export function useUserPlanUsageSummary(
  enabled,
  apiClient,
  useSetLoading = false,
  axiosOptions = {},
) {
  const { setLoading, setMessage } = useGlobalProvider();

  const {
    isLoading,
    isFetching,
    fetchStatus,
    data: planUsageSummary,
    error,
  } = useQuery({
    queryKey: getEntityItemCacheKey(QUERY_KEY_MAPPING.planUsages, 'summary'),
    queryFn: fetchQuery,
    staleTime: 60 * 1000 * 10, // 10 minute
    enabled: enabled,
    retry: false,
    onError: (err) =>
      handleApiError(err, setMessage, `Error while loading plan summary`),
  });

  async function fetchQuery() {
    try {
      const { data } =
        await apiClient.planUsagesUsersControllerUserSummary(axiosOptions);
      return data;
    } catch (err) {
      if (err?.response?.data?.message === 'User has no active plan.') {
        console.log('No active plan for user');
        return null;
      } else {
        throw err;
      }
    }
  }

  useEffect(() => {
    if (useSetLoading) {
      setLoading(
        isLoading && fetchStatus !== 'idle',
        useUserPlanUsageSummary.name,
      );
    }
    return () => {
      if (isLoading && fetchStatus !== 'idle') {
        setLoading(false, useUserPlanUsageSummary.name);
      }
    };
  }, [isLoading, isFetching]);

  return {
    planUsageSummary,
    isFetching,
    isLoading: isLoading && fetchStatus !== 'idle',
    error,
  };
}

export function usePlanUsageAvailability() {
  const { userPlanUsageSummary } = useAuth();

  const checkPlanUsage = () => {
    if (userPlanUsageSummary) {
      if (
        userPlanUsageSummary.expires_in >= 0 ||
        userPlanUsageSummary.expires_in === -1
      ) {
        return true;
      }
    }
    return false;
  };

  const canCreateTemplate = () => {
    if (!checkPlanUsage()) {
      return false;
    }
    if (userPlanUsageSummary.remaining_templates === -1) {
      return true;
    }
    return userPlanUsageSummary.remaining_templates > 0;
  };

  const canGenerateLabels = (labelsToGenerate) => {
    if (!checkPlanUsage()) {
      return false;
    }
    if (userPlanUsageSummary.remaining_labels === -1) {
      return true;
    }
    return userPlanUsageSummary.remaining_labels >= labelsToGenerate;
  };

  return { canCreateTemplate, canGenerateLabels };
}
