import { Configuration, PublicApi } from '@hello-label/api-client';
import { useQuery } from '@tanstack/react-query';
import { handleApiError } from 'lib/ErrorService';
import {
  getEntityItemCacheKey,
  getEntityListCacheKey,
  parseQueryParam,
  QUERY_KEY_MAPPING,
} from 'lib/hooks/api/common';
import useAuth from 'lib/providers/authProvider';
import useGlobalProvider from 'lib/providers/globalProvider';
import { useEffect } from 'react';

export function useOptions(
  { pageSize, page, query, sort, populate, useSpecialQuery },
  useSetLoading = false,
  paginationEnabled = true,
  axiosOptions = {},
  usePublicApi = false,
) {
  const { user, apiClient } = useAuth();
  const { setLoading, setMessage, loading } = useGlobalProvider();
  const params = {
    pageSize,
    page,
    query,
    sort,
    populate,
    useSpecialQuery: useSpecialQuery ? '1' : '0',
  };

  const initialData = paginationEnabled
    ? {
        data: [],
        meta: {
          totalItems: 0,
          currentPage: page,
          pageSize: pageSize,
          totalPages: 0,
          query: {},
          sort: { createdAt: -1 },
        },
      }
    : undefined;

  const isEnabled = () => {
    if (usePublicApi) {
      return true;
    } else {
      return !!user && !!apiClient;
    }
  };

  const {
    isLoading,
    isFetching,
    data: options,
    fetchStatus,
    error,
  } = useQuery({
    queryKey: [getEntityListCacheKey(QUERY_KEY_MAPPING.options), params],
    queryFn: usePublicApi ? fetchQueryPublic : fetchQuery,
    placeholderData: initialData,
    staleTime: 60 * 1000 * 10, // 10 minute
    keepPreviousData: paginationEnabled,
    enabled: isEnabled(),
    onError: (err) =>
      handleApiError(err, setMessage, 'Error while loading the options'),
  });

  async function fetchQuery() {
    const { data } = await apiClient.optionsUsersControllerFindAll(
      params.pageSize,
      params.page,
      parseQueryParam(params.query),
      parseQueryParam(params.sort),
      params.populate,
      params.useSpecialQuery,
      axiosOptions,
    );
    return data;
  }

  async function fetchQueryPublic() {
    const api = new PublicApi(
      new Configuration({
        basePath: process.env.REACT_APP_API_CLIENT_BASE_PATH,
      }),
    );
    const { data } = await api.optionsPublicControllerFindAll(
      params.pageSize,
      params.page,
      parseQueryParam(params.query),
      parseQueryParam(params.sort),
      params.populate,
      params.useSpecialQuery,
      axiosOptions,
    );
    return data;
  }

  useEffect(() => {
    if (useSetLoading) {
      if (paginationEnabled) {
        setLoading(isLoading || isFetching, useOptions.name);
      } else {
        setLoading(isLoading, useOptions.name);
      }
    }
    return () => {
      if (loading) {
        setLoading(false, useOption.name);
      }
    };
  }, [isLoading, isFetching]);

  return {
    options,
    isFetching,
    isLoading: isLoading && fetchStatus !== 'idle',
    fetchStatus,
    error,
  };
}

export function useOption(
  optionName,
  query = {},
  populate = [],
  useSetLoading = true,
  axiosOptions = {},
  usePublicApi = false,
) {
  const { apiClient } = useAuth();
  const { setLoading, setMessage } = useGlobalProvider();

  const isEnabled = () => {
    if (usePublicApi) {
      return true;
    } else {
      return !!optionName && !!apiClient;
    }
  };

  const {
    isLoading,
    isFetching,
    fetchStatus,
    data: option,
    error,
  } = useQuery({
    queryKey: getEntityItemCacheKey(QUERY_KEY_MAPPING.options, optionName),
    queryFn: usePublicApi ? fetchQueryPublic : fetchQuery,
    staleTime: 60 * 1000 * 10, // 10 minute
    enabled: isEnabled(),
    onError: (err) =>
      handleApiError(
        err,
        setMessage,
        `Error while loading options ${optionName}`,
      ),
  });

  async function fetchQuery() {
    const { data } = await apiClient.optionsUsersControllerFindOne(
      optionName,
      parseQueryParam(query),
      populate,
      axiosOptions,
    );
    return data;
  }

  async function fetchQueryPublic() {
    const api = new PublicApi(
      new Configuration({
        basePath: process.env.REACT_APP_API_CLIENT_BASE_PATH,
      }),
    );
    const { data } = await api.optionsPublicControllerFindOne(
      optionName,
      parseQueryParam(query),
      populate,
      axiosOptions,
    );
    return data;
  }

  useEffect(() => {
    if (useSetLoading) {
      setLoading(isLoading && fetchStatus !== 'idle', useOption.name);
    }
    return () => {
      if (isLoading && fetchStatus !== 'idle') {
        setLoading(false, useOption.name);
      }
    };
  }, [isLoading, fetchStatus]);

  return {
    option,
    isFetching,
    isLoading: isLoading && fetchStatus !== 'idle',
    error,
  };
}
