import Keycloak from "keycloak-js";

// Setup Keycloak instance as needed
// Pass initialization options as required or leave blank to load from 'keycloak.json'
const keycloak = new Keycloak("/keycloak.json");

export const initOptions = {
  onLoad: "login-required",
  silentCheckSsoRedirectUri: window.location.origin + "/silent-check-sso.html",
  pkceMethod: "S256",
  checkLoginIframe: false,
};

export const eventLogger = (event, error) => {
  if (error) {
    console.log("Keycloak error", error);
  }
};

export const tokenLogger = (tokens) => {
  // console.log("onKeycloakTokens", tokens);
};

export default keycloak;
