import { EuiGlobalToastList } from "@elastic/eui";
import useGlobalProvider from "lib/providers/globalProvider";

function AppMessage() {
  const { messages, setMessages } = useGlobalProvider();

  const removeToast = (removedMessage) => {
    setMessages(messages.filter((message) => message.id !== removedMessage.id));
  };

  return (
    <EuiGlobalToastList
      toasts={messages}
      dismissToast={removeToast}
      toastLifeTimeMs={8000}
    />
  );
}

export default AppMessage;
