/** @jsxImportSource @emotion/react */
import {
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiI18n,
  EuiText,
  useEuiI18n,
  EuiButtonEmpty,
} from '@elastic/eui';
import { css } from '@emotion/react';

function FormActions({
  handleReset,
  resetLabel = 'Reset',
  submitDisabled = false,
  submitLabel = 'Next',
  showReset = true,
  showRequired = true,
  showCancel = false,
  handleCancel = null,
  cancelLabel = 'Cancel',
  handleSubmit = undefined,
  cancelButtonEmptyStyle = false,
}) {
  const requiredFields = useEuiI18n('requiredField', 'required field');

  let CancelButton = (
    <EuiButton
      css={css`
        margin-right: 8px;
      `}
      color="text"
      onClick={handleCancel}
    >
      <EuiI18n default={cancelLabel} token={cancelLabel} />
    </EuiButton>
  );
  if (cancelButtonEmptyStyle) {
    CancelButton = (
      <EuiButtonEmpty
        css={css`
          margin-right: 8px;
        `}
        color="primary"
        onClick={handleCancel}
        aria-label={cancelLabel}
      >
        <EuiI18n default={cancelLabel} token={cancelLabel} />
      </EuiButtonEmpty>
    );
  }

  return (
    <EuiFlexGroup justifyContent="spaceBetween" alignItems="center">
      {showRequired ? (
        <EuiFlexItem>
          <EuiText grow={false} size="s">
            <p>* {requiredFields}.</p>
          </EuiText>
        </EuiFlexItem>
      ) : null}
      <EuiFlexItem grow={true}>
        <div
          css={css`
            text-align: right;
          `}
        >
          {showCancel ? CancelButton : null}
          {showReset ? (
            <EuiButton
              css={css`
                margin-right: 8px;
              `}
              type="reset"
              onClick={handleReset}
            >
              <EuiI18n default={resetLabel} token={resetLabel} />
            </EuiButton>
          ) : null}
          <EuiButton
            type="submit"
            fill
            disabled={submitDisabled}
            onClick={handleSubmit}
          >
            <EuiI18n default={submitLabel} token={submitLabel} />
          </EuiButton>
        </div>
      </EuiFlexItem>
    </EuiFlexGroup>
  );
}

export default FormActions;
