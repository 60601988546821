import PageBanner from 'components/PageBanner';
import React, { useState } from 'react';
import '@elastic/eui/dist/eui_theme_light.css';
import { EuiErrorBoundary, EuiLink, EuiText, EuiProvider } from '@elastic/eui';
import { logError } from 'lib/ErrorService/errorLogger';

function GlobalErrorBoundary({ children }) {
  const [hasError, setHasError] = useState(false);

  const supportMail = 'support@hello-label.com';

  const handleError = (err) => {
    logError(err);
    setHasError(true);
  };

  if (hasError) {
    return (
      <EuiProvider colorMode="light">
        <div
          style={{
            width: '100vw',
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <PageBanner
            title="An error occurred"
            body={
              <EuiText>
                Try to reload the page, if the error persists try to contact the
                support at{' '}
                <EuiLink color="danger" href={`mailto:${supportMail}`}>
                  {supportMail}
                </EuiLink>
              </EuiText>
            }
          />
        </div>
      </EuiProvider>
    );
  }

  return (
    <EuiErrorBoundary onError={(err) => handleError(err)}>
      {children}
    </EuiErrorBoundary>
  );
}

export default GlobalErrorBoundary;
