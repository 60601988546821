import React, { createContext, useState, useContext, useEffect } from 'react';
import io from 'socket.io-client';
import useAuth from 'lib/providers/authProvider';
import useGlobalProvider from 'lib/providers/globalProvider';
import { EuiButton, EuiFlexGroup, EuiFlexItem, EuiLink } from '@elastic/eui';
import { API_MAPPING } from 'lib/api';
import useNotificationProvider from 'lib/providers/notificationsProvider';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';

const initialState = {
  socket: null,
};

const ServerBridgeContext = createContext({
  socket: initialState.socket,
});

export const ServerBridgeProvider = ({ children }) => {
  const { userToken, user, apiClient } = useAuth();
  const { setMessage } = useGlobalProvider();
  const { setNotifications, setRefreshNotifications } =
    useNotificationProvider();
  const [socket, setSocket] = useState(null);
  const [socketInitialized, setSocketInitialized] = useState(false);
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  useEffect(() => {
    if (user && !socketInitialized) {
      const socketUrl = `${process.env.REACT_APP_API_SOCKET_PATH}`;
      const newSocket = io(socketUrl, {
        auth: {
          token: userToken,
          userId: user._id,
        },
        transports: ['websocket'],
      });
      newSocket.on('connect', () => {
        console.log('Socket connected');
      });

      newSocket.on('disconnect', () => {
        console.log('Socket disconnected');
      });

      newSocket.on('notification', (msg) => {
        const notification = JSON.parse(msg);
        handleNotification(notification);
      });

      setSocket(newSocket);
      setSocketInitialized(true);
    }

    return () => {
      if (socket) {
        socket.disconnect();
      }
    };
  }, [user]);

  const handleNotification = (notification) => {
    setNotifications((prevState) => [notification, ...prevState]);
    let notificationText = notification.text;
    if (notification.action) {
      switch (notification.action.type) {
        case 'api-action':
          const onClickHandler = async () => {
            await API_MAPPING[notification.action.link](
              apiClient,
              notification.action.linkParams,
              setMessage,
              queryClient,
            );
            setRefreshNotifications((prevValue) => !prevValue);
          };
          notificationText = (
            <>
              <p>{notification.text}</p>
              <EuiFlexGroup justifyContent="flexEnd" gutterSize="s">
                <EuiFlexItem grow={false}>
                  <EuiButton
                    size="s"
                    disabled={!notification.action.enabled}
                    onClick={onClickHandler}
                  >
                    {notification.action.text}
                  </EuiButton>
                </EuiFlexItem>
              </EuiFlexGroup>
            </>
          );
          break;
        case 'internal-link':
          notificationText = (
            <>
              <p>{notification.text}</p>
              <EuiLink
                onClick={async () => {
                  await apiClient.notificationsUsersControllerUpdate(
                    notification.code,
                    { opened: true },
                  );
                  navigate(notification.action.link);
                  setRefreshNotifications((prevValue) => !prevValue);
                }}
              >
                {notification.action.text}
              </EuiLink>
            </>
          );
          break;
        case 'external-link':
          notificationText = (
            <>
              <p>{notification.text}</p>
              <EuiLink
                target="_blank"
                external
                href={notification.action.link}
                onClick={async () => {
                  await apiClient.notificationsUsersControllerUpdate(
                    notification.code,
                    { opened: true },
                  );
                  setRefreshNotifications((prevValue) => !prevValue);
                }}
              >
                {notification.action.text}
              </EuiLink>
            </>
          );
          break;
        default:
          notificationText = notification.text;
      }
    }
    setMessage({
      title: notification.title,
      color: notification.color,
      iconType: notification.iconType,
      text: notificationText,
      toastLifeTimeMs: notification.toastLifeTimeMs,
      code: notification.code,
    });
  };

  return (
    <ServerBridgeContext.Provider
      value={{
        socket,
      }}
    >
      {children}
    </ServerBridgeContext.Provider>
  );
};

export default function useServerBridgeProvider() {
  return useContext(ServerBridgeContext);
}
