import { handleApiError } from 'lib/ErrorService';
import useAuth from 'lib/providers/authProvider';
import { isEmptyObject } from 'lib/utils';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import useGlobalProvider from '../../providers/globalProvider';
import { useEffect } from 'react';

export const QUERY_KEY_MAPPING = {
  labels: 'LABELS',
  notifications: 'NOTIFICATIONS',
  payments: 'PAYMENTS',
  plans: 'PLANS',
  planUsages: 'PLAN_USAGES',
  templates: 'TEMPLATES',
  options: 'OPTIONS',
  stats: 'STATS',
  fonts: 'FONTS',
};

export const getEntityListCacheKey = (key) => `${key}-list`;

export const getEntityItemCacheKey = (key, id) => [`${key}-item`, { id: id }];

export function buildApiCall(apiClient, fn, args) {
  return apiClient[fn](...args);
}

export function useCreateApiCall(queryKey, useSetLoading = true) {
  const { setLoading } = useGlobalProvider();
  const queryClient = useQueryClient();
  const { isLoading, mutateAsync } = useMutation({
    mutationFn: ({ apiClient, fn, args }) => buildApiCall(apiClient, fn, args),
    onSuccess: (data) => {
      // After insert new element, list of entities need to be update
      invalidateCacheEntry(queryClient, queryKey, 'list');
    },
  });

  useEffect(() => {
    if (useSetLoading) {
      setLoading(isLoading, `${useCreateApiCall.name}-${queryKey}`);
    }
  }, [isLoading]);

  return { isLoading, mutateAsync };
}

export function useUpdateApiCall(
  queryKey,
  useSetLoading = true,
  invalidateData = true,
  idField = '_id',
) {
  const { setLoading } = useGlobalProvider();
  const queryClient = useQueryClient();
  const { isLoading, mutateAsync } = useMutation({
    mutationFn: ({ apiClient, fn, args }) => buildApiCall(apiClient, fn, args),
    onSuccess: (data) => {
      if (invalidateData) {
        const updatedData = data.data;
        // After update we replace the cached value of the updated entity and we invalidate the list
        const itemId = updatedData[idField];
        queryClient.setQueryData(
          getEntityItemCacheKey(queryKey, itemId),
          updatedData,
        );
        invalidateCacheEntry(queryClient, queryKey, 'list');
      }
    },
  });

  useEffect(() => {
    if (useSetLoading) {
      setLoading(isLoading, `${useUpdateApiCall.name}-${queryKey}`);
    }
  }, [isLoading]);

  return { isLoading, mutateAsync };
}

export function useRemoveApiCall(
  queryKey,
  useSetLoading = true,
  idField = '_id',
) {
  const { setLoading } = useGlobalProvider();
  const queryClient = useQueryClient();
  const { isLoading, mutateAsync } = useMutation({
    mutationFn: ({ apiClient, fn, args }) => buildApiCall(apiClient, fn, args),
    onSuccess: (data) => {
      const entityRemoved = data.data;
      const itemId = entityRemoved[idField];
      invalidateCacheEntry(queryClient, queryKey, 'item', itemId);
      invalidateCacheEntry(queryClient, queryKey, 'list');
    },
  });

  useEffect(() => {
    if (useSetLoading) {
      setLoading(isLoading, `${useRemoveApiCall.name}-${queryKey}`);
    }
  }, [isLoading]);

  return { isLoading, mutateAsync };
}

export function parseQueryParam(value) {
  return isEmptyObject(value) ? undefined : JSON.stringify(value);
}

export function usePaginatedApiCall(
  apiFn,
  cacheKey,
  { pageSize, page, query, sort, populate, useSpecialQuery } = {
    pageSize: 0,
    page: 0,
    query: {},
    sort: { createdAt: -1 },
    populate: [],
    useSpecialQuery: false,
  },
  useSetLoading = false,
  paginationEnabled = false,
  axiosOptions = {},
  errorMessage = 'Error while loading the data',
) {
  const { user, apiClient } = useAuth();
  const { setLoading, setMessage, loading } = useGlobalProvider();
  const params = {
    pageSize,
    page,
    query,
    sort,
    populate,
    useSpecialQuery: useSpecialQuery ? '1' : '0',
  };

  const initialData = paginationEnabled
    ? {
        data: [],
        meta: {
          totalItems: 0,
          currentPage: page,
          pageSize: pageSize,
          totalPages: 0,
          query: {},
          sort: { createdAt: -1 },
        },
      }
    : undefined;

  const { isLoading, isFetching, data, fetchStatus, error } = useQuery({
    queryKey: [getEntityListCacheKey(cacheKey), params],
    queryFn: fetchQuery,
    placeholderData: initialData,
    staleTime: 60 * 1000 * 10, // 10 minute
    keepPreviousData: paginationEnabled,
    enabled: !!user && !!apiClient,
    onError: (err) => handleApiError(err, setMessage, errorMessage),
  });

  async function fetchQuery() {
    const { data } = await apiClient[apiFn](
      params.pageSize,
      params.page,
      parseQueryParam(params.query),
      parseQueryParam(params.sort),
      params.populate,
      params.useSpecialQuery,
      axiosOptions,
    );
    return data;
  }

  useEffect(() => {
    if (useSetLoading) {
      if (paginationEnabled) {
        setLoading(isLoading || isFetching, apiFn);
      } else {
        setLoading(isLoading, apiFn);
      }
    }
    return () => {
      if (loading) {
        setLoading(false, apiFn);
      }
    };
  }, [isLoading, isFetching]);

  return {
    data,
    isFetching,
    isLoading: isLoading && fetchStatus !== 'idle',
    fetchStatus,
    error,
  };
}

export function invalidateCacheEntry(
  queryClient,
  key,
  keyType,
  keyId = null,
  opt = null,
) {
  let queryKey;
  switch (keyType.toLowerCase()) {
    case 'list':
      queryKey = [getEntityListCacheKey(key)];
      break;
    case 'item':
      queryKey = getEntityItemCacheKey(key, keyId);
      break;
    default:
      queryKey = key;
  }
  queryClient.invalidateQueries({
    queryKey: queryKey,
  });
}
