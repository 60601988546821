import { EuiFilePicker, EuiFormRow } from '@elastic/eui';
import Label from 'components/forms/Common/Label';

function InputFileField({
  field,
  handleOnChange,
  fieldRef,
  accept,
  helpText,
  compressed = false,
  display = null,
  fullWidth = false,
  append = null,
  showLabel = true,
  css = null,
}) {
  let displayValue = compressed ? 'default' : 'large';
  if (display) {
    displayValue = display;
  }
  return (
    <EuiFormRow
      css={css}
      label={showLabel ? <Label field={field} /> : null}
      isInvalid={field.isInvalid}
      error={field.errors}
      helpText={helpText}
      isDisabled={field.disabled}
      fullWidth={fullWidth}
      display={compressed ? 'rowCompressed' : 'row'}
    >
      <EuiFilePicker
        compressed={compressed}
        display={displayValue}
        fullWidth={fullWidth}
        initialPromptText={field.label}
        accept={accept}
        ref={fieldRef}
        onChange={(e) => handleOnChange(e)}
        aria-label={field.label.toString()}
        name={field.name}
        isInvalid={field.isInvalid}
        append={append}
      />
    </EuiFormRow>
  );
}

export default InputFileField;
