import { useState } from 'react';
import {
  EuiHeaderSectionItemButton,
  EuiIcon,
  EuiPopover,
  EuiPopoverTitle,
  EuiToolTip,
  useGeneratedHtmlId,
} from '@elastic/eui';

function BottomToolbarPopover({
  icon,
  description,
  Component,
  componentProps,
  width = 300,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const popoverId = useGeneratedHtmlId();

  const onMenuButtonClick = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  const button = (
    <EuiHeaderSectionItemButton
      aria-controls={popoverId}
      aria-expanded={isOpen}
      aria-haspopup="true"
      aria-label={description}
      onClick={onMenuButtonClick}
    >
      <EuiToolTip position="bottom" content={description}>
        <EuiIcon type={icon} size="l" aria-hidden="true" />
      </EuiToolTip>
    </EuiHeaderSectionItemButton>
  );

  return (
    <EuiPopover
      id={popoverId}
      repositionOnScroll
      button={button}
      isOpen={isOpen}
      anchorPosition="upLeft"
      closePopover={closeMenu}
      panelPaddingSize="m"
    >
      <EuiPopoverTitle>{description}</EuiPopoverTitle>
      <div style={{ width: width }}>
        <Component {...componentProps} callback={closeMenu} />
      </div>
    </EuiPopover>
  );
}

export default BottomToolbarPopover;
