import { EuiI18n, EuiSpacer, useEuiI18n } from '@elastic/eui';
import useApp from 'lib/providers/appProvider';
import ActivePlan from 'pages/Plans/ActivePlan';
import NewPlan from 'pages/Plans/NewPlan';
import PlansView from 'pages/Plans/Plans.view';
import PlansHistory from 'pages/Plans/PlansHistory';
import { Fragment, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export const PLAN_TABS_IDS = {
  Active: 'plan_usages_tab-active_plan',
  History: 'plan_usages_tab-plans_history',
  New: 'plan_usages_tab-new_plan',
};

const tabsGenerator = (spaceSize = 'l') => [
  {
    id: PLAN_TABS_IDS.Active,
    name: <EuiI18n token={'Active Plan'} default={'Active Plan'} />,
    content: (
      <Fragment>
        <EuiSpacer size={spaceSize} />
        <ActivePlan />
      </Fragment>
    ),
  },
  {
    id: PLAN_TABS_IDS.History,
    name: <EuiI18n token={'Plans History'} default={'Plans History'} />,
    content: (
      <Fragment>
        <EuiSpacer size={spaceSize} />
        <PlansHistory />
      </Fragment>
    ),
  },
  {
    id: PLAN_TABS_IDS.New,
    name: <EuiI18n token={'New Plan'} default={'New Plan'} />,
    content: (
      <Fragment>
        <EuiSpacer size={spaceSize} />
        <NewPlan />
      </Fragment>
    ),
  },
];

const tabs = tabsGenerator();

function Plans() {
  const location = useLocation();
  const [selectedTab, setSelectedTab] = useState(tabs[0]);
  const { setHeadTitle } = useApp();
  const currentPage = useEuiI18n('Plans', 'Plans');

  useEffect(() => {
    setHeadTitle(currentPage);
  }, []);

  const handleTabChange = (tab) => {
    setSelectedTab(tab);
  };

  useEffect(() => {
    if (location.state?.tab) {
      const requestedTab = tabs.filter((tab) => tab.id === location.state.tab);
      if (requestedTab.length === 1) {
        setSelectedTab(requestedTab[0]);
      }
    }
  }, [location.state]);

  return (
    <PlansView
      tabs={tabs}
      selectedTab={selectedTab}
      handleTabChange={handleTabChange}
    />
  );
}

export default Plans;
