/** @jsxImportSource @emotion/react */
import {
  EuiButton,
  EuiCallOut,
  EuiFlexGroup,
  EuiFlexItem,
  EuiHorizontalRule,
  EuiI18n,
  EuiText,
} from '@elastic/eui';
import { css } from '@emotion/react';
import { useQueryClient } from '@tanstack/react-query';
import CurrencyRender from 'components/CurrencyRender';
import PaymentForm from 'components/Payments/PaymentForm';
import PlanActivationDate from 'components/Plans/PlanActivationDate';
import PlansList from 'components/Plans/PlansList';
import { CUSTOM_ECOMMERCE_PLAN_CODE, DEMO_PLAN_CODE } from 'lib/api/plans';
import { handleApiError } from 'lib/ErrorService';
import { PageError } from 'lib/ErrorService/PageError';
import {
  getEntityListCacheKey,
  invalidateCacheEntry,
  QUERY_KEY_MAPPING,
} from 'lib/hooks/api/common';
import { usePlans } from 'lib/hooks/api/plans';
import useAuth from 'lib/providers/authProvider';
import useGlobalProvider from 'lib/providers/globalProvider';
import { PLAN_TABS_IDS } from 'pages/Plans';
import moment from 'moment/moment';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function PlansSelector({}) {
  const { setLoading, setMessage } = useGlobalProvider();
  const { apiClient, setRefreshUser } = useAuth();
  const queryClient = useQueryClient();
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [removeUnselected, setRemoveUnselected] = useState(false);
  const [activationDate, setActivationDate] = useState(
    moment().subtract(moment().minute() % 30, 'minutes'),
  );
  const navigate = useNavigate();
  const {
    plans,
    error,
    isLoading: plansIsLoading,
  } = usePlans(
    {
      pageSize: 0,
      page: 0,
      query: {},
      sort: { price: 1 },
      populate: [],
      useSpecialQuery: false,
    },
    false,
    false,
    {},
    false,
  );

  const handleProceedClick = async () => {
    if (selectedPlan.code === DEMO_PLAN_CODE) {
      await handlePaymentSuccess();
    } else {
      setRemoveUnselected(true);
    }
  };

  const handleBackClick = () => {
    setRemoveUnselected(false);
  };

  const createCustomECommercePlan = async () => {
    try {
      const { data: planData } =
        await apiClient.plansUsersControllerCreateCustomECommerce({
          n_labels: selectedPlan.n_labels,
        });
      return planData;
    } catch (err) {
      throw err;
    }
  };

  const handlePaymentSuccess = async (paymentData) => {
    try {
      setLoading(true, PlansSelector.name);
      let planId = selectedPlan._id;
      if (selectedPlan.code === CUSTOM_ECOMMERCE_PLAN_CODE) {
        const customPlan = await createCustomECommercePlan();
        planId = customPlan._id;
      }
      const { data: planUsage } =
        await apiClient.planUsagesUsersControllerCreate({
          activation_date: activationDate.toDate(),
          plan: planId,
          payment: paymentData ? paymentData._id : undefined,
        });
      invalidateCacheEntry(queryClient, QUERY_KEY_MAPPING.planUsages, 'list');
      setRefreshUser((prev) => !prev);
      const date = moment(planUsage.activation_date);
      setMessage({
        title:
          planUsage.status === 'enabled'
            ? 'Plan activated with success'
            : 'Plan requested with success',
        color: 'success',
        iconType: 'check',
        text:
          planUsage.status === 'enabled'
            ? `Your plan is starting on ${date.format('DD/MM/YYYY hh:mm A')}`
            : `Your plan will be activated once we process your payment `,
      });
      navigate('/plans', { state: { tab: PLAN_TABS_IDS.Active } });
    } catch (err) {
      handleApiError(err, setMessage, 'Error while adding the new plan');
    } finally {
      setLoading(false, PlansSelector.name);
    }
  };

  const handlePaymentCancel = () => {
    setRemoveUnselected(false);
  };

  if (error) {
    return <PageError pageName={'plans'} />;
  }

  return (
    <>
      <EuiFlexGroup alignItems="flexStart" justifyContent="flexStart">
        <EuiFlexItem grow={3}>
          <PlansList
            plans={plans}
            removeUnselected={removeUnselected}
            selectedPlan={selectedPlan}
            setSelectedPlan={setSelectedPlan}
            isLoading={plansIsLoading}
          />
          <EuiHorizontalRule size="half" margin={'l'} />
          <PlanActivationDate
            removeUnselected={removeUnselected}
            activationDate={activationDate}
            setActivationDate={setActivationDate}
          />
        </EuiFlexItem>
        {removeUnselected ? (
          <EuiFlexItem grow={9}>
            <PaymentForm
              usePublicApi={false}
              description={
                <SelectedPlanSummary
                  amount={selectedPlan.price}
                  activationDate={activationDate}
                />
              }
              planId={selectedPlan._id}
              amount={selectedPlan.price}
              objectPurchased={`Hello Label plan ${
                selectedPlan.name
              } - activation date ${activationDate.format(
                'DD/MM/YYYY hh:mm A',
              )}`}
              onCancelAction={handlePaymentCancel}
              onSuccessAction={handlePaymentSuccess}
              additionalNotes={
                <p>Your plan will be activated once we receive your payment.</p>
              }
            />
          </EuiFlexItem>
        ) : null}
      </EuiFlexGroup>
      <div
        css={css`
          text-align: center;
        `}
      >
        {removeUnselected ? null : (
          <>
            <EuiHorizontalRule size={'half'} margin={'xl'} />
            <EuiButton
              fill
              onClick={handleProceedClick}
              disabled={selectedPlan === null}
            >
              <EuiI18n default={'Proceed'} token={'Proceed'} />
            </EuiButton>
          </>
        )}
      </div>
    </>
  );
}

export function SelectedPlanSummary({ amount, activationDate }) {
  const rowCss = `margin-block-end: 4px !important;`;
  return (
    <EuiCallOut color={'primary'}>
      <EuiText>
        <p css={css(rowCss)}>
          Amount to pay:{' '}
          <strong>
            {<CurrencyRender color="subdued" value={amount} addStyle={false} />}
          </strong>
        </p>
        <p css={css(rowCss)}>
          Activation date:{' '}
          <strong>{activationDate.format('DD/MM/YYYY hh:mm A')}</strong>
        </p>
      </EuiText>
    </EuiCallOut>
  );
}

export default PlansSelector;
