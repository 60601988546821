/** @jsxImportSource @emotion/react */
import {
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiHorizontalRule,
  EuiI18n,
  EuiSkeletonLoading,
  EuiSpacer,
  EuiText,
} from '@elastic/eui';
import { css } from '@emotion/react';
import PlanCard from 'components/Plans/PlanCard';
import { PlanCardSkeleton } from 'components/Plans/PlansList';
import PlanSummaryViewer from 'components/Plans/PlanSummaryViewer';
import useAuth from 'lib/providers/authProvider';
import useGlobalProvider from 'lib/providers/globalProvider';
import { getStaticServerFilePath } from 'lib/utils';
import { PLAN_TABS_IDS } from 'pages/Plans/index';
import { useNavigate } from 'react-router-dom';

function ActivePlan({}) {
  const { userPlanUsageSummary } = useAuth();
  const { loading } = useGlobalProvider();
  const navigate = useNavigate();

  if (!userPlanUsageSummary?.plan && !loading) {
    return (
      <EuiText size={'m'}>
        <h3>No active plan available</h3>
        <EuiButton
          size={'m'}
          color={'warning'}
          onClick={() =>
            navigate('/plans', { state: { tab: PLAN_TABS_IDS.New } })
          }
        >
          <EuiI18n token={'Upgrade plan'} default={'Upgrade plan'} />
        </EuiButton>
      </EuiText>
    );
  }

  return (
    <>
      <EuiText>
        <h3>Your current plan status</h3>
      </EuiText>
      <EuiSpacer size={'xl'} />
      <EuiFlexGroup>
        <EuiFlexItem grow={false}>
          <div
            css={css`
              width: 282px;
            `}
          >
            <EuiSkeletonLoading
              isLoading={loading}
              loadedContent={
                userPlanUsageSummary ? (
                  <PlanCard
                    planData={{
                      ...userPlanUsageSummary?.plan,
                      icon: getStaticServerFilePath(
                        userPlanUsageSummary?.plan.icon,
                      ),
                    }}
                    removeUnselected={true}
                  />
                ) : (
                  ''
                )
              }
              loadingContent={
                <div
                  css={css`
                    text-align: center;
                  `}
                >
                  <PlanCardSkeleton />
                </div>
              }
            />
          </div>
        </EuiFlexItem>
        <EuiFlexItem>
          <PlanSummaryViewer size={'m'} showExpirationDate={true} />
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer size="xl" />
      <EuiHorizontalRule margin={'none'} />
      <EuiSpacer size={'s'} />
      <EuiText color={'subdued'} size={'s'}>
        <EuiI18n
          token={
            'Please note that the plan name and expiration date refer only to the last activated plan. Labels and templates instead take into account all your valid plans.'
          }
          default={
            'Please note that the plan name and expiration date refer only to the last activated plan. Labels and templates instead take into account all your valid plans.'
          }
        />
      </EuiText>
    </>
  );
}

export default ActivePlan;
