/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import {
  useEuiTheme,
  useEuiShadow,
  useIsWithinMaxBreakpoint,
} from '@elastic/eui';
import { css } from '@emotion/react';
import CanvasBottomToolbar from 'components/Creator/CreatorCanvas/CanvasBottomToolbar';
import useNavigation, {
  navigationInitialState,
} from 'lib/providers/navigationProvider';
import MainHeader from 'components/Creator/CreatorCanvas/CanvasHeader/MainHeader';
import useCanvas from 'lib/providers/canvasProvider';
import UtilityHeader from 'components/Creator/CreatorCanvas/CanvasHeader/UtilityHeader';
import CanvasPanel, {
  panelWidthClosed,
  panelWidthExpanded,
} from 'components/Creator/CreatorCanvas/CanvasPanel';
import { useEditorActions } from 'components/Creator/CreatorCanvas/editorActions';
import { useInterval } from 'ahooks';

function CanvasWrapper({ setCreatorMode }) {
  const {
    canvasEl,
    canvasWrapperEl,
    editor,
    initEditor,
    destroyEditor,
    currentZoom,
    setCurrentZoom,
    wrapperSizes,
    isCanvasPanelExpanded,
    setIsCanvasPanelExpanded,
    isMobile,
    marginLeft,
    setMarginLeft,
    canvasOffset,
    selectedObjects,
    templateMetadata,
    setTemplateMetadata,
    fieldsDisplayed,
    templateData,
    autoSaveEnabled,
  } = useCanvas();
  const marginTop = useIsWithinMaxBreakpoint(['s']) ? 0 : 48;
  const marginBottom = useIsWithinMaxBreakpoint(['s']) ? 48 : 0;
  const { euiTheme } = useEuiTheme();
  const { setMainHeader } = useNavigation();

  // AutoSave
  const { handleSaveConfirm } = useEditorActions(
    editor,
    selectedObjects,
    templateMetadata,
    fieldsDisplayed,
    setCurrentZoom,
    setTemplateMetadata,
    setCreatorMode,
    templateData,
  );
  const [lastSavedJSON, setLastSavedJSON] = useState(
    templateData ? JSON.stringify(templateData.jsonTemplate) : null,
  );

  const checkLastEditorJSON = async () => {
    if (editor && autoSaveEnabled) {
      const editorJSON = JSON.stringify(editor.toJSON());
      if (editorJSON !== lastSavedJSON) {
        setLastSavedJSON(editorJSON);
        await handleSaveConfirm(true, setLastSavedJSON);
      }
    }
  };

  const clearAutoSaveInterval = useInterval(
    () => checkLastEditorJSON(),
    60 * 1000, // 60 seconds
    {
      immediate: false,
    },
  );

  useEffect(() => {
    return () => clearAutoSaveInterval();
  }, [clearAutoSaveInterval]);

  // Change main navbar with canvas main header
  useEffect(() => {
    initEditor();
    setMainHeader(<MainHeader setCreatorMode={setCreatorMode} />);
    return () => {
      destroyEditor();
      setMainHeader(navigationInitialState.mainNavbar);
    };
  }, []);

  useEffect(() => {
    if (editor) {
      const usableBody =
        document.body.clientWidth -
        (isCanvasPanelExpanded ? panelWidthExpanded : panelWidthClosed);
      const canvasFullWidth = editor.canvas.getWidth() + canvasOffset;
      if (usableBody < canvasFullWidth) {
        let additionalMargin = canvasFullWidth - usableBody;
        if (isCanvasPanelExpanded) {
          if (additionalMargin > panelWidthExpanded) {
            additionalMargin = panelWidthExpanded;
          }
        } else {
          if (additionalMargin > panelWidthClosed) {
            additionalMargin = panelWidthClosed;
          }
        }
        setMarginLeft(canvasOffset / 2 + additionalMargin);
      } else {
        setMarginLeft(canvasOffset / 2);
      }
    }
  }, [editor, currentZoom, document.body.clientWidth, isCanvasPanelExpanded]);

  return (
    <>
      <UtilityHeader isCanvasPanelExtended={isCanvasPanelExpanded} />
      <CanvasPanel
        isExpanded={isCanvasPanelExpanded}
        setIsExpanded={setIsCanvasPanelExpanded}
        setCreatorMode={setCreatorMode}
      />
      <div
        ref={canvasWrapperEl}
        className="canvas-wrapper"
        css={css`
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          overflow: auto;
          background-color: ${euiTheme.colors.lightShade};
          width: calc(
            ${wrapperSizes.width} -
              ${isCanvasPanelExpanded ? panelWidthExpanded : panelWidthClosed}px
          );
          margin-left: ${isCanvasPanelExpanded
            ? panelWidthExpanded
            : panelWidthClosed}px;
          min-height: calc(100vh - ${48 * 2}px);
          margin-top: ${marginTop}px;
          margin-bottom: ${marginBottom}px;
        `}
      >
        <div
          css={css`
            margin: ${canvasOffset / 2}px;
            margin-left: ${marginLeft}px;
            z-index: ${euiTheme.levels.content};
          `}
        >
          <canvas
            ref={canvasEl}
            css={css`
              ${useEuiShadow('s')}
            `}
          />
        </div>
      </div>
      <div
        css={css`
          width: 100%;
          position: fixed;
          bottom: 0;
        `}
      >
        <CanvasBottomToolbar
          editor={editor}
          currentZoom={currentZoom}
          setCurrentZoom={setCurrentZoom}
        />
      </div>
    </>
  );
}

export default CanvasWrapper;
