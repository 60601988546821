/** @jsxImportSource @emotion/react */
import useCanvas from 'lib/providers/canvasProvider';
import {
  EuiHorizontalRule,
  EuiSpacer,
  EuiText,
  shade,
  tint,
  useEuiI18n,
  useEuiTheme,
} from '@elastic/eui';
import PanelButton from 'components/Creator/CreatorCanvas/CanvasPanel/ActionPanels/PanelButton';

function TextBoxSelector() {
  const { editor, setIsCanvasPanelExpanded, isMobile, setActivePanelAction } =
    useCanvas();
  const { euiTheme } = useEuiTheme();
  const digitHereLabel = useEuiI18n('Digit here', 'digit here');
  const addATextBoxLabel = useEuiI18n('Add a text box', 'Add a text box');
  const addAHeadingLabel = useEuiI18n('Add a heading', 'Add a heading');
  const addASubHeadingLabel = useEuiI18n(
    'Add a sub heading',
    'Add a sub heading',
  );
  const addATextBodyLabel = useEuiI18n('Add a text body', 'Add a text body');

  const addTextBox = async () => {
    await editor.addTextBox(digitHereLabel, {
      fontSize: 24,
      width: editor.canvas.getWidth() * 0.8,
      textAlign: 'center',
    });
    closeCanvasPanel();
  };

  const addAHeading = async () => {
    await editor.addTextBox(addAHeadingLabel, {
      fontSize: 64,
      width: editor.canvas.getWidth() * 0.8,
      textAlign: 'center',
    });
    closeCanvasPanel();
  };

  const addASubHeading = async () => {
    await editor.addTextBox(addASubHeadingLabel, {
      fontSize: 32,
      width: editor.canvas.getWidth() * 0.8,
      textAlign: 'center',
    });
    closeCanvasPanel();
  };

  const addATextBody = async () => {
    await editor.addTextBox(addATextBodyLabel, {
      fontSize: 18,
      width: editor.canvas.getWidth() * 0.8,
      textAlign: 'center',
    });
    closeCanvasPanel();
  };

  const closeCanvasPanel = () => {
    if (isMobile) {
      setIsCanvasPanelExpanded(false);
      setActivePanelAction(null);
    }
  };

  return (
    <div>
      <PanelButton
        color={euiTheme.colors.primary}
        textColor={euiTheme.colors.text}
        hoverColor={shade(euiTheme.colors.primary, 0.1)}
        fill={true}
        content={addATextBoxLabel}
        onClick={addTextBox}
      />
      <EuiHorizontalRule margin={'l'} />
      <PanelButton
        color={euiTheme.colors.mediumShade}
        textColor={euiTheme.colors.text}
        hoverColor={tint(euiTheme.colors.mediumShade, 0.1)}
        fill={true}
        content={
          <EuiText>
            <h2>{addAHeadingLabel}</h2>
          </EuiText>
        }
        onClick={addAHeading}
      />
      <EuiSpacer size={'l'} />
      <PanelButton
        color={euiTheme.colors.mediumShade}
        textColor={euiTheme.colors.text}
        hoverColor={tint(euiTheme.colors.mediumShade, 0.1)}
        fill={true}
        content={
          <EuiText>
            <h4>{addASubHeadingLabel}</h4>
          </EuiText>
        }
        onClick={addASubHeading}
      />
      <EuiSpacer size={'l'} />
      <PanelButton
        color={euiTheme.colors.mediumShade}
        textColor={euiTheme.colors.text}
        hoverColor={tint(euiTheme.colors.mediumShade, 0.1)}
        fill={true}
        content={addATextBodyLabel}
        onClick={addATextBody}
      />
    </div>
  );
}

export default TextBoxSelector;
