import { EuiText } from '@elastic/eui';
import { OptionGroupEnum, OptionTypeEnum } from '@hello-label/api-client';
import CurrencyRender from 'components/CurrencyRender';
import { useOptions } from 'lib/hooks/api/options';
import { isEmptyObject } from 'lib/utils';
import { useEffect, useState } from 'react';

function BankTransfer({
  amount,
  objectPurchased,
  additionalNotes,
  usePublicApi = false,
}) {
  const [bankTransferOptions, setBankTransferOptions] = useState({});
  const { options: bankTransferOptionsData } = useOptions(
    {
      pageSize: 10,
      page: 0,
      query: {
        type: OptionTypeEnum.Global,
        group: OptionGroupEnum.BankTransfer,
      },
    },
    false,
    false,
    {},
    usePublicApi,
  );

  useEffect(() => {
    if (bankTransferOptionsData?.data.length > 0) {
      const options = {};
      bankTransferOptionsData.data.forEach((option) => {
        options[option.name] = option.value;
      });
      setBankTransferOptions(options);
    }
  }, [bankTransferOptionsData]);

  if (isEmptyObject(bankTransferOptions)) {
    return null;
  }

  return (
    <EuiText size="m">
      <h5>Bank transfer payment instructions:</h5>
      <ul>
        <RowView label={'IBAN'} value={bankTransferOptions.iban} />
        <RowView
          label={'IBAN holder'}
          value={bankTransferOptions.iban_holder}
        />
        <RowView
          label={'Amount'}
          value={<CurrencyRender value={amount} addStyle={false} />}
        />
        <RowView
          label={'Reason for transfer'}
          value={`Purchase of ${objectPurchased}`}
        />
        <RowView
          label={'SEPA (if required by your bank)'}
          value={bankTransferOptions.sepa}
        />
        <RowView
          label={'SWIFT (if required by your bank)'}
          value={bankTransferOptions.swift}
        />
      </ul>
      {additionalNotes}
    </EuiText>
  );
}

const RowView = ({ label, value }) => (
  <li>
    {label}: <strong>{value}</strong>
  </li>
);

export default BankTransfer;
