/** @jsxImportSource @emotion/react */
import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useRef,
  useLayoutEffect,
  useMemo,
} from 'react';
import { useEuiTheme, useIsWithinMaxBreakpoint } from '@elastic/eui';
import { fabric } from 'fabric';
import Editor from 'lib/canvasEditor/Editor';
import { useFonts } from 'lib/hooks/api/fonts';
import { getSupportedFonts } from 'lib/fonts';
import FontFaceLoader from 'components/Fonts/FontFaceLoader';

export const canvasInitialState = {
  editor: null,
  canvasObjects: [],
  selectedObjects: [],
  fieldsDisplayed: {},
  currentZoom: 1,
  wrapperSizes: { width: '100%', height: '100%' },
  canvasEl: null,
  canvasWrapperEl: null,
  setFieldsDisplayed: null,
  setCurrentZoom: null,
  setWrapperSizes: null,
  templateMetadata: {},
  setTemplateMetadata: null,
  templateData: null,
};

const CanvasContext = createContext({
  editor: canvasInitialState.editor,
  canvasObjects: canvasInitialState.canvasObjects,
  selectedObjects: canvasInitialState.selectedObjects,
  fieldsDisplayed: canvasInitialState.fieldsDisplayed,
  currentZoom: canvasInitialState.currentZoom,
  wrapperSizes: canvasInitialState.wrapperSizes,
  canvasEl: canvasInitialState.canvasEl,
  canvasWrapperEl: canvasInitialState.canvasWrapperEl,
  setFieldsDisplayed: canvasInitialState.setFieldsDisplayed,
  setCurrentZoom: canvasInitialState.setCurrentZoom,
  setWrapperSizes: canvasInitialState.setWrapperSizes,
  templateMetadata: canvasInitialState.templateMetadata,
  setTemplateMetadata: canvasInitialState.setTemplateMetadata,
  templateData: canvasInitialState.templateData,
});

export const CanvasProvider = ({ children }) => {
  const { euiTheme } = useEuiTheme();
  const [editor, setEditor] = useState(null);
  const [canvasObjects, setCanvasObjects] = useState([]);
  const [selectedObjects, setSelectedObjects] = useState([]);
  const [activeObjectUpdated, setActiveObjectUpdated] = useState(0);
  const [templateMetadata, setTemplateMetadata] = useState(
    null,
    /*JSON.parse(
      '{"name":"Test","description":"","height":1400,"width":800,"dpi":96,"fields":[{"name":"nation","type":"text","sampleValue":"ES"},{"name":"po","type":"text","sampleValue":"100000000"},{"name":"code128#1","type":"CODE128","sampleValue":"851502 LWK 81"},{"name":"quantity","type":"text","sampleValue":"84"},{"name":"code128#2","type":"CODE128","sampleValue":"318072 NHL 65"}]}',
    ),*/
  );
  const [templateData, setTemplateData] = useState(null);
  const [templateFonts, setTemplateFonts] = useState([]);
  const [availableFonts, setAvailableFonts] = useState({
    defaultFonts: getSupportedFonts(),
    userFonts: [],
  });
  const [fieldsDisplayed, setFieldsDisplayed] = useState(
    canvasInitialState.fieldsDisplayed,
  );
  const [currentZoom, setCurrentZoom] = useState(
    canvasInitialState.currentZoom,
  );
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [wrapperSizes, setWrapperSizes] = useState(
    canvasInitialState.wrapperSizes,
  );
  const isMobile = useIsWithinMaxBreakpoint(['s']);
  const [canvasOffset, setCanvasOffset] = useState(isMobile ? 80 : 120);
  const [isCanvasPanelExpanded, setIsCanvasPanelExpanded] = useState(false);
  const [marginLeft, setMarginLeft] = useState(canvasOffset / 2);
  const [activePanelAction, setActivePanelAction] = useState(null);
  const [autoSaveEnabled, setAutoSaveEnabled] = useState(true);

  const canvasEl = useRef(null);
  const canvasWrapperEl = useRef(null);

  const { fonts: userFonts } = useFonts(
    {
      page: 0,
      pageSize: 0,
      query: {},
      sort: { family: 1, weight: 1, style: 1 },
      populate: [],
      useSpecialQuery: false,
    },
    false,
    false,
    {},
  );

  const customFonts = useMemo(() => {
    return userFonts?.data?.map((font, index) => (
      <FontFaceLoader key={index} font={font} />
    ));
  }, [userFonts]);

  useLayoutEffect(() => {
    updateResizeCanvasSizes();
  }, [isMobile]);

  const updateResizeCanvasSizes = () => {
    let offset;
    if (isMobile) {
      offset = 60;
      setCanvasOffset(offset);
      setMarginLeft(offset / 2);
    } else {
      offset = 120;
      setCanvasOffset(offset);
      setMarginLeft(offset / 2);
    }
    return offset;
  };

  useEffect(() => {
    if (userFonts?.data?.length > 0) {
      setAvailableFonts({
        ...availableFonts,
        userFonts: userFonts.data.map((font) => font),
      });
    } else {
      setAvailableFonts({
        ...availableFonts,
        userFonts: [],
      });
    }
  }, [userFonts]);

  const setTemplateMetadataInternal = (value) => {
    setTemplateMetadata(value);
    if (value) {
      const fields = {};
      value.fields.forEach((fieldInfo, code) => (fields[code] = false));
      setFieldsDisplayed(fields);
    }
  };

  const objectAddedEvent = (e) => {
    if (e.target.fieldMetadata) {
      if (e.target.fieldMetadata.isTemplateField) {
        setFieldsDisplayed((prevState) => ({
          ...prevState,
          [e.target.fieldMetadata.code]: true,
        }));
      }
    }
  };

  const objectRemovedEvent = (e) => {
    if (e.target.fieldMetadata) {
      if (e.target.fieldMetadata.isTemplateField) {
        setFieldsDisplayed((prevState) => ({
          ...prevState,
          [e.target.fieldMetadata.code]: false,
        }));
      }
    }
  };

  const onZoomChange = (zoom) => {
    setCurrentZoom(zoom);
  };

  useEffect(() => {
    if (canvasObjects.length > 0 && templateData) {
      processObjects(canvasObjects);
      setIsFirstLoad(false);
    }
  }, [canvasObjects, templateData, isFirstLoad]);

  const updateWrapperSize = () => {
    const canvasWidth =
      editor?.canvas.getWidth() || templateMetadata?.width || 400;
    const originalWidth = document.body.clientWidth;
    let newWidth;
    if (canvasWidth > originalWidth - canvasOffset) {
      newWidth = `${canvasWidth + canvasOffset}px`;
    } else {
      newWidth = '100%';
    }
    setWrapperSizes({ ...wrapperSizes, width: newWidth });
  };

  const setInitialFit = () => {
    setWrapperSizes({ ...wrapperSizes, width: '100%' });
    let updatedOffset = updateResizeCanvasSizes();
    editor.fitToScreen(updatedOffset);
  };

  useEffect(() => {
    updateWrapperSize();
  }, [currentZoom]);

  useLayoutEffect(() => {
    function changeWrapperWidth() {
      const bodyWidth = document.body.clientWidth;
      const canvasWidth = canvasEl.current?.clientWidth || 0;
      if (bodyWidth > canvasWidth) {
        setWrapperSizes({ ...wrapperSizes, width: '100%' });
      } else {
        setWrapperSizes({
          ...wrapperSizes,
          width: `${canvasWidth + canvasOffset}px`,
        });
      }
    }
    window.addEventListener('resize', changeWrapperWidth);
    changeWrapperWidth();
    return () => window.removeEventListener('resize', changeWrapperWidth);
  }, []);

  useEffect(() => {
    if (editor) {
      if (templateData?.jsonTemplate) {
        editor.loadFromJson(templateData.jsonTemplate, () => {
          initCanvas();
          processObjects(editor.getObjects());
          setInitialFit();
        });
      } else {
        initCanvas();
        setInitialFit();
      }
    }
  }, [editor]);

  const processObjects = (objectsList) => {
    objectsList.forEach((object) => {
      if (object.fieldMetadata.isTemplateField) {
        setFieldsDisplayed((prevState) => ({
          ...prevState,
          [object.fieldMetadata.code]: true,
        }));
      }
    });
  };

  const initEditor = () => {
    if (canvasEl?.current && canvasWrapperEl?.current && templateMetadata) {
      const width = templateMetadata.width;
      const height = templateMetadata.height;
      const backgroundColor = '#ffffff';
      const accentColor = euiTheme.colors.accent;
      const canvas = new fabric.Canvas(canvasEl.current, {
        width,
        height,
        backgroundColor,
      });
      setEditor(
        new Editor(canvas, canvasWrapperEl.current, {
          width,
          height,
          backgroundColor,
          accentColor,
        }),
      );
    }
  };

  const initCanvas = () => {
    editor.initCanvas(
      handleObjectAdded,
      handleObjectRemoved,
      handleSelectionCleared,
      handleSelection,
      onZoomChange,
      handleObjectUpdated,
      handleSelectionUpdated,
    );
  };

  const handleObjectAdded = (e) => {
    objectAddedEvent(e);
    if (editor) {
      // editor.scaleObject(e.target)
      setCanvasObjects(editor.getObjects());
    }
  };

  const handleObjectRemoved = (e) => {
    objectRemovedEvent(e);
    if (editor) {
      setCanvasObjects(editor.getObjects());
    }
  };

  const handleSelectionCleared = () => {
    setSelectedObjects([]);
  };

  const handleSelection = (e) => {
    setSelectedObjects(editor.canvas.getActiveObjects());
  };

  const handleSelectionUpdated = (e) => {
    setSelectedObjects(editor.canvas.getActiveObjects());
  };

  const handleObjectUpdated = () => {
    setActiveObjectUpdated((oldValue) =>
      oldValue + 1 < 100000 ? oldValue + 1 : 0,
    );
  };

  const destroyEditor = () => {
    if (editor) {
      editor.close();
      setEditor(null);
      setTemplateData(null);
      setCanvasObjects([]);
      setSelectedObjects([]);
      setTemplateMetadata(null);
    }
  };

  return (
    <CanvasContext.Provider
      value={{
        canvasEl,
        canvasWrapperEl,
        editor,
        initEditor,
        destroyEditor,
        canvasObjects,
        selectedObjects,
        fieldsDisplayed,
        setFieldsDisplayed,
        currentZoom,
        setCurrentZoom,
        wrapperSizes,
        setWrapperSizes,
        templateMetadata,
        setTemplateMetadata: setTemplateMetadataInternal,
        templateData,
        setTemplateData,
        isCanvasPanelExpanded,
        setIsCanvasPanelExpanded,
        isMobile,
        marginLeft,
        setMarginLeft,
        activeObjectUpdated,
        setActiveObjectUpdated,
        templateFonts,
        setTemplateFonts,
        availableFonts,
        setAvailableFonts,
        canvasOffset,
        activePanelAction,
        setActivePanelAction,
        autoSaveEnabled,
        setAutoSaveEnabled,
      }}
    >
      {customFonts}
      {children}
    </CanvasContext.Provider>
  );
};

export default function useCanvas() {
  return useContext(CanvasContext);
}
