import {
  EuiHeaderSectionItem,
  EuiHeaderSectionItemButton,
  EuiIcon,
} from '@elastic/eui';
import CanvasTooltip from 'components/Creator/CanvasTooltip';

function HeaderAction({ actionItem }) {
  return (
    <EuiHeaderSectionItem>
      {actionItem.hasPopover ? (
        actionItem.popover(actionItem)
      ) : actionItem.customRender ? (
        <CanvasTooltip
          position={'bottom'}
          label={actionItem.description}
          shortcut={actionItem.shortcut}
        >
          {actionItem.icon}
        </CanvasTooltip>
      ) : (
        <EuiHeaderSectionItemButton
          aria-label={actionItem.description}
          onClick={actionItem.action}
          isDisabled={actionItem.disabled}
        >
          <CanvasTooltip
            position={'bottom'}
            label={actionItem.description}
            shortcut={actionItem.shortcut}
          >
            <EuiIcon type={actionItem.icon} size="m" aria-hidden="true" />
          </CanvasTooltip>
        </EuiHeaderSectionItemButton>
      )}
    </EuiHeaderSectionItem>
  );
}

export default HeaderAction;
