/** @jsxImportSource @emotion/react */
import { EuiFormRow, EuiFieldSearch } from '@elastic/eui';

import { css } from '@emotion/react';
import Label from 'components/forms/Common/Label';

function SearcField({
  field,
  handleOnChange,
  handleOnSearch,
  compressed = false,
  fullWidth = false,
  type = 'text',
  width = null,
  showPlaceholder = true,
  append = null,
  helpText = null,
  noLabel = false,
  isClearable = true,
  isLoading = false,
  incremental = false,
}) {
  const cssOptions = width ? `width: ${width}px` : '';
  return (
    <EuiFormRow
      label={noLabel ? null : <Label field={field} />}
      isInvalid={field.isInvalid}
      error={field.errors}
      isDisabled={field.disabled}
      fullWidth={fullWidth}
      display={compressed ? 'rowCompressed' : 'row'}
      helpText={helpText}
    >
      <EuiFieldSearch
        isLoading={isLoading}
        css={css(cssOptions)}
        compressed={compressed}
        fullWidth={fullWidth}
        isClearable={isClearable}
        value={field.value}
        placeholder={showPlaceholder ? field.label : ''}
        onChange={(e) => handleOnChange(e)}
        onSearch={(value) => handleOnSearch(value)}
        aria-label={field.label.toString()}
        name={field.name}
        isInvalid={field.isInvalid}
        type={type}
        incremental={incremental}
        append={append}
      />
    </EuiFormRow>
  );
}

export default SearcField;
