import { Configuration, UsersApi } from '@hello-label/api-client';
import { downloadLabels } from 'lib/api/labels';
import useAuth from 'lib/providers/authProvider';
import { useEffect, useState } from 'react';

export const API_MAPPING = {
  'users/labels/:labelsCode/download': async (
    apiClient,
    { labelsCode },
    setMessage,
    queryClient,
  ) => downloadLabels(apiClient, labelsCode, setMessage, queryClient),
};

/**
 *
 * @return {UsersApi}
 */
export function useApiClient(userTokenDirect = null) {
  const [apiClient, setApiClient] = useState(null);
  const { userToken } = useAuth();

  useEffect(() => {
    const token = userToken || userTokenDirect;
    if (token) {
      setApiClient(
        new UsersApi(
          new Configuration({
            basePath: process.env.REACT_APP_API_CLIENT_BASE_PATH,
            accessToken: token,
          }),
        ),
      );
    }
  }, [userToken, userTokenDirect]);

  return apiClient;
}
