/** @jsxImportSource @emotion/react */
import { EuiButtonIcon, EuiFieldText } from '@elastic/eui';
import CanvasTooltip from 'components/Creator/CanvasTooltip';

function ActionNumberInput({
  value,
  onChange,
  label,
  step = 1,
  compressed = true,
  min = null,
  max = null,
  width = 80,
}) {
  const onChangeInternal = (value, addValue = null) => {
    try {
      let parsed = parseFloat(value);
      if (addValue) {
        parsed += addValue;
      }
      if (min) {
        if (parsed < min) {
          parsed = min;
        }
      }
      if (max) {
        if (parsed > max) {
          parsed = max;
        }
      }
      if (!isNaN(parsed)) {
        onChange(parsed);
      }
    } catch (e) {}
  };

  return (
    <CanvasTooltip position={'bottom'} label={label}>
      <EuiFieldText
        style={{ width: width }}
        compressed={compressed}
        aria-label={label}
        prepend={
          <EuiButtonIcon
            iconType="minus"
            aria-label="Decrease"
            onClick={() => onChangeInternal(value, -step)}
          />
        }
        append={
          <EuiButtonIcon
            iconType="plus"
            aria-label="Increase"
            onClick={() => onChangeInternal(value, step)}
          />
        }
        value={value}
        onChange={(e) => onChangeInternal(e.target.value)}
      />
    </CanvasTooltip>
  );
}

export default ActionNumberInput;
