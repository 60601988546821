/** @jsxImportSource @emotion/react */
import { EuiHeaderSectionItem } from '@elastic/eui';
import React, { useEffect, useState } from 'react';
import useCanvas from 'lib/providers/canvasProvider';
import ActionIconGroup from 'components/Creator/CreatorCanvas/CanvasHeader/UtilityHeaderComponents/ActionIconGroup';
import { ReactComponent as Justify } from 'assets/customIcons/Justify.svg';
import { css } from '@emotion/react';

const alignActions = [
  {
    label: 'Align left',
    icon: 'editorAlignLeft',
    value: 'left',
  },
  {
    label: 'Align center',
    icon: 'editorAlignCenter',
    value: 'center',
  },
  {
    label: 'Align right',
    icon: 'editorAlignRight',
    value: 'right',
  },
  {
    label: 'Justify',
    icon: Justify,
    value: 'justify',
  },
];

function FontAlignActions({ objects }) {
  const { editor, activeObjectUpdated } = useCanvas();
  const [textAlign, setTextAlign] = useState(
    editor.getActiveStyle('textAlign', objects[0]),
  );

  useEffect(() => {
    setTextAlign(editor.getActiveStyle('textAlign', objects[0]));
  }, [objects, activeObjectUpdated]);

  const handleAlignClick = (value) => {
    setTextAlign(value);
    objects.forEach((object) => {
      editor.setActiveStyle('textAlign', value, object);
    });
  };

  return (
    <EuiHeaderSectionItem
      css={css`
        margin-right: 4px;
      `}
    >
      <ActionIconGroup
        actions={alignActions}
        onActionClick={handleAlignClick}
        keepActive={textAlign}
      />
    </EuiHeaderSectionItem>
  );
}

export default FontAlignActions;
