/** @jsxImportSource @emotion/react */
import { getFontFamilyFromFont, useFontDownload } from 'lib/hooks/api/fonts';
import { css, Global } from '@emotion/react';

function FontFaceLoader({ font }) {
  const { fontFile } = useFontDownload(font?._id, false);

  return (
    <Global
      styles={css`
        @font-face {
          font-family: ${getFontFamilyFromFont(font)};
          font-style: ${font.style};
          font-weight: ${font.weight};
          src: url(${fontFile});
        }
      `}
    />
  );
}

export default FontFaceLoader;
