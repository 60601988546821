import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import FullPageLoader from 'components/Loading/FullPageLoader';
import GlobalErrorBoundary from 'lib/ErrorService/GlobalErrorBoundary';
import { BrowserRouter as Router } from 'react-router-dom';
import { AppProvider } from 'lib/providers/appProvider';
import AppRouter from 'pages/AppRouter';
import { LocaleProvider } from 'lib/providers/localeProvider';
import { GlobalProvider } from 'lib/providers/globalProvider';

const queryClient = new QueryClient();

function App() {
  return (
    <GlobalErrorBoundary>
      <Router>
        <QueryClientProvider client={queryClient}>
          <GlobalProvider>
            <LocaleProvider>
              <AppProvider>
                <AppRouter />
                <FullPageLoader visible={true} isEmptyPage={true} />
              </AppProvider>
            </LocaleProvider>
          </GlobalProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </Router>
    </GlobalErrorBoundary>
  );
}

export default App;
