/** @jsxImportSource @emotion/react */
import {
  EuiFlexGroup,
  EuiFlexItem,
  useEuiTheme,
  useEuiShadow,
} from '@elastic/eui';
import BottomToolbarPopover from 'components/Creator/CreatorCanvas/CanvasBottomToolbar/BottomToolbarPopover';
import CanvasShortcutsAndTips from 'components/Creator/CreatorCanvas/CanvasBottomToolbar/CanvasShortcutsAndTips';
import React from 'react';
import ZoomSelector from 'components/Creator/CreatorCanvas/CanvasBottomToolbar/ZoomSelector';
import { css } from '@emotion/react';

function CanvasBottomToolbar({ editor, currentZoom, setCurrentZoom }) {
  const { euiTheme } = useEuiTheme();
  return (
    <EuiFlexGroup
      justifyContent={'spaceBetween'}
      alignItems={'center'}
      gutterSize={'l'}
    >
      <EuiFlexItem></EuiFlexItem>
      <EuiFlexItem grow={false}>
        <EuiFlexGroup
          gutterSize="s"
          alignItems={'center'}
          responsive={false}
          css={css`
            background-color: ${euiTheme.colors.emptyShade};
            padding: 4px 16px 4px 8px;
            border-radius: ${euiTheme.border.radius.small};
            ${useEuiShadow()}
          `}
        >
          <EuiFlexItem grow={false}>
            <BottomToolbarPopover
              icon={'questionInCircle'}
              description={'Shortcuts and Tips'}
              Component={CanvasShortcutsAndTips}
              componentProps={{}}
              width={500}
            />
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <ZoomSelector
              editor={editor}
              setCurrentZoom={setCurrentZoom}
              currentZoom={currentZoom}
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </EuiFlexGroup>
  );
}

export default CanvasBottomToolbar;
