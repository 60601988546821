import { fabric } from 'fabric';
import { CUSTOM_LINE_FIELD, CUSTOM_SHAPE_FIELD } from 'lib/templateFieldsUtils';
import {
  alignObject,
  getCenterLeftPosition,
  getCenterTopPosition,
  setObjectInitialPosition,
} from 'lib/canvasEditor/dist/utils';
import { preventLineResizing } from 'lib/canvasEditor/dist/drawing/object_utils';
/**
 * Define action to draw line by mouse actions
 */
export const lineDrawing = function (canvas) {
  let isDrawingLine = false,
    lineToDraw,
    pointer,
    pointerPoints;

  canvas.on('mouse:down', (o) => {
    if (!canvas.isDrawingLineMode) return;

    isDrawingLine = true;
    pointer = canvas.getPointer(o.e);
    pointerPoints = [pointer.x, pointer.y, pointer.x, pointer.y];

    lineToDraw = new fabric.Line(pointerPoints, {
      strokeWidth: 2,
      stroke: '#000000',
    });
    lineToDraw.selectable = false;
    lineToDraw.evented = false;
    lineToDraw.strokeUniform = true;
    canvas.add(lineToDraw);
  });

  canvas.on('mouse:move', (o) => {
    if (!isDrawingLine) return;

    pointer = canvas.getPointer(o.e);

    if (o.e.shiftKey) {
      // calc angle
      let startX = pointerPoints[0];
      let startY = pointerPoints[1];
      let x2 = pointer.x - startX;
      let y2 = pointer.y - startY;
      let r = Math.sqrt(x2 * x2 + y2 * y2);
      let angle = (Math.atan2(y2, x2) / Math.PI) * 180;

      angle = parseInt(((angle + 7.5) % 360) / 15) * 15;

      let cosx = r * Math.cos((angle * Math.PI) / 180);
      let sinx = r * Math.sin((angle * Math.PI) / 180);

      lineToDraw.set({
        x2: cosx + startX,
        y2: sinx + startY,
      });
    } else {
      lineToDraw.set({
        x2: pointer.x,
        y2: pointer.y,
      });
    }

    canvas.renderAll();
  });

  canvas.on('mouse:up', () => {
    if (!isDrawingLine) return;

    lineToDraw.setCoords();
    isDrawingLine = false;
    canvas.fire('object:modified');
  });
};

export async function addLine(canvas, options = {}) {
  const lineObj = new fabric.Line([0, 0, 0, 400], {
    left: 20,
    top: 25,
    stroke: '#000000',
    strokeWidth: 5,
    strokeDashArray: null,
    opacity: 1,
    strokeUniform: true,
    noScaleCache: false,
    fieldMetadata: { type: CUSTOM_LINE_FIELD, isTemplateField: false },
    ...options,
  });
  preventLineResizing(lineObj);
  lineObj.set('top', await getCenterTopPosition(canvas, lineObj));
  lineObj.set('left', await getCenterLeftPosition(canvas, lineObj));
  canvas.add(lineObj);
  setObjectInitialPosition(canvas, lineObj);
}
