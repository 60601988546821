import { EuiI18n, EuiLink, EuiSpacer, useEuiI18n } from '@elastic/eui';
import AppPoliciesModal from 'components/AppPoliciesModal';
import useOptionsProvider from 'lib/providers/optionsProvider';
import React, { useState } from 'react';

function SideUserMenu({}) {
  const { appInfoOptions } = useOptionsProvider();
  const [showModal, setShowModal] = useState(false);
  const [policyKey, setPolicyKey] = useState(null);
  const privacyPolicyLabel = useEuiI18n('Privacy Policy', 'Privacy Policy');
  const termsLabel = useEuiI18n('Terms of Usage', 'Terms of Usage');

  const handleModalOpenClick = (contentName) => {
    setPolicyKey(contentName);
    setShowModal(true);
  };

  return (
    <>
      <EuiSpacer size="s" />
      <EuiLink
        onClick={() => handleModalOpenClick('privacy_policy')}
        className={'hello-label_header-font'}
      >
        {privacyPolicyLabel}
      </EuiLink>
      <EuiSpacer size="s" />
      <EuiLink
        onClick={() => handleModalOpenClick('terms_of_usage')}
        className={'hello-label_header-font'}
      >
        {termsLabel}
      </EuiLink>
      <EuiSpacer size="s" />
      <EuiLink
        href={appInfoOptions?.documentation_link}
        target={'_blank'}
        className={'hello-label_header-font'}
      >
        <EuiI18n token={'Documentation'} default={'Documentation'} />
      </EuiLink>
      <AppPoliciesModal
        isVisible={showModal}
        setIsVisible={setShowModal}
        policyKey={policyKey}
      />
    </>
  );
}

export default SideUserMenu;
