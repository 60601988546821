/** @jsxImportSource @emotion/react */
import {
  EuiConfirmModal,
  EuiHeader,
  EuiHeaderLogo,
  EuiHeaderSection,
  EuiHeaderSectionItem,
} from '@elastic/eui';
import logo from 'assets/logo.svg';
import React from 'react';
import HeaderAction from 'components/Creator/CreatorCanvas/CanvasHeader/HeaderAction';
import { useEditorActions } from 'components/Creator/CreatorCanvas/editorActions';
import { css } from '@emotion/react';
import useCanvas from 'lib/providers/canvasProvider';
import ThemeSwitcher from 'components/ThemeSwitcher';
import CanvasTooltip from 'components/Creator/CanvasTooltip';

function MainHeader({ setCreatorMode }) {
  const {
    templateMetadata,
    editor,
    selectedObjects,
    fieldsDisplayed,
    setCurrentZoom,
    setTemplateMetadata,
    templateData,
    currentZoom,
    isMobile,
  } = useCanvas();

  const {
    mainHeaderLeftActions,
    mainHeaderRightActions,
    backAction,
    confirmModal,
    setConfirmModal,
    handleDiscardConfirm,
    handleSaveConfirm,
  } = useEditorActions(
    editor,
    selectedObjects,
    templateMetadata,
    fieldsDisplayed,
    setCurrentZoom,
    setTemplateMetadata,
    setCreatorMode,
    templateData,
    currentZoom,
  );

  return (
    <>
      <EuiHeader position="fixed" theme="dark">
        <EuiHeaderSection grow={false}>
          <HeaderAction actionItem={backAction} />
          <EuiHeaderSectionItem border="right">
            <CanvasTooltip
              position={'bottom'}
              label={backAction.description}
              shortcut={backAction.shortcut}
            >
              <EuiHeaderLogo
                css={css`
                  cursor: pointer;
                `}
                iconType={logo}
                iconTitle="Hello Label"
                onClick={() => backAction.action()}
              >
                {isMobile ? null : 'Hello Label'}
              </EuiHeaderLogo>
            </CanvasTooltip>
          </EuiHeaderSectionItem>
          {isMobile
            ? null
            : mainHeaderLeftActions.map((actionItem, index) => (
                <HeaderAction key={index} actionItem={actionItem} />
              ))}
        </EuiHeaderSection>
        <EuiHeaderSection side="right">
          {isMobile
            ? null
            : mainHeaderRightActions.map((actionItem, index) => (
                <HeaderAction key={index} actionItem={actionItem} />
              ))}
          <EuiHeaderSectionItem>
            <ThemeSwitcher compressed={true} />
          </EuiHeaderSectionItem>
        </EuiHeaderSection>
      </EuiHeader>
      {confirmModal ? (
        <EuiConfirmModal
          title={confirmModal.title}
          onCancel={() => setConfirmModal(null)}
          onConfirm={
            confirmModal.buttonColor === 'danger'
              ? handleDiscardConfirm
              : handleSaveConfirm
          }
          cancelButtonText="No, don't do it"
          confirmButtonText="Yes, do it"
          buttonColor={confirmModal.buttonColor}
          defaultFocusedButton={confirmModal.defaultFocusedButton}
        >
          <p>{confirmModal.text}</p>
        </EuiConfirmModal>
      ) : null}
    </>
  );
}

export default MainHeader;
