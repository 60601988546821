/** @jsxImportSource @emotion/react */
import { EuiFormRow, EuiFieldText } from '@elastic/eui';

import { css } from '@emotion/react';
import Label from 'components/forms/Common/Label';

function TextField({
  field,
  handleOnChange,
  compressed = false,
  fullWidth = false,
  type = 'text',
  width = null,
  showPlaceholder = true,
  append = null,
  helpText = null,
  noLabel = false,
}) {
  const cssOptions = width ? `width: ${width}px` : '';
  return (
    <EuiFormRow
      label={noLabel ? null : <Label field={field} />}
      isInvalid={field.isInvalid}
      error={field.errors}
      isDisabled={field.disabled}
      fullWidth={fullWidth}
      display={compressed ? 'rowCompressed' : 'row'}
      helpText={helpText}
    >
      <EuiFieldText
        css={css(cssOptions)}
        compressed={compressed}
        fullWidth={fullWidth}
        value={field.value}
        placeholder={showPlaceholder ? field.label : ''}
        onChange={(e) => handleOnChange(e)}
        aria-label={field.label.toString()}
        name={field.name}
        isInvalid={field.isInvalid}
        type={type}
        append={append}
      />
    </EuiFormRow>
  );
}

export default TextField;
