/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import BillingForm, {
  billingDataReducer,
  initBillingDataState,
} from 'components/Profile/ProfileForm/BillingForm';
import ProfileDataForm, {
  initProfileDataState,
  profileDataReducer,
} from 'components/Profile/ProfileForm/ProfileDataForm';
import {
  EuiButton,
  EuiForm,
  EuiSpacer,
  EuiText,
  EuiDescribedFormGroup,
  EuiFlexItem,
  EuiFlexGroup,
  EuiHorizontalRule,
  useEuiI18n,
  EuiPanel,
} from '@elastic/eui';
import { handleApiError } from 'lib/ErrorService';
import useAuth from 'lib/providers/authProvider';
import useGlobalProvider from 'lib/providers/globalProvider';
import { valuesFromFieldObjects } from 'lib/utils';
import React, { useEffect, useReducer, useState } from 'react';

export const REDUCER_ACTIONS = {
  change: 'CHANGE',
  error: 'ERROR',
  reset: 'RESET',
};

function ProfileForm({ isEdit, user }) {
  const { setMessage, setLoading } = useGlobalProvider();
  const { apiClient, updateUserData } = useAuth();
  const requiredFiled = useEuiI18n('requiredField', 'required field');
  const [isReset, setIsReset] = useState(false);
  const [profileDataState, profileDataDispatcher] = useReducer(
    profileDataReducer,
    initProfileDataState({ user, isEdit }),
  );
  const { email, is_company, first_name, last_name, company_name } =
    profileDataState;
  const [billingDataState, billingDataDispatcher] = useReducer(
    billingDataReducer,
    initBillingDataState({ user, isEdit }),
  );
  const {
    email: billingEmail,
    is_company: billingIsCompany,
    first_name: billingFirstName,
    last_name: billingLastName,
    company_name: billingCompanyName,
    tax_code: billingTaxCode,
    country: billingCountry,
    invoice_recipient_code: billingInvoiceRecipientCode,
    address: billingAddress,
    city: billingCity,
    postcode: billingPostcode,
  } = billingDataState;

  const handleProfileDataChange = (field, value) => {
    profileDataDispatcher({ type: REDUCER_ACTIONS.change, field, value });
  };

  const handleProfileDataError = (field, errors) => {
    profileDataDispatcher({ type: REDUCER_ACTIONS.error, field, errors });
  };

  const handleProfileDataReset = () => {
    profileDataDispatcher({ type: REDUCER_ACTIONS.reset, user, isEdit });
  };

  const handleBillingDataChange = (field, value) => {
    billingDataDispatcher({ type: REDUCER_ACTIONS.change, field, value });
  };

  const handleBillingDataError = (field, errors) => {
    billingDataDispatcher({ type: REDUCER_ACTIONS.error, field, errors });
  };

  const handleBillingDataReset = () => {
    billingDataDispatcher({ type: REDUCER_ACTIONS.reset, user, isEdit });
  };

  useEffect(() => {
    if (is_company.value) {
      handleProfileDataChange('first_name', '');
      handleProfileDataChange('last_name', '');
    } else {
      handleProfileDataChange('company_name', '');
    }
  }, [is_company]);

  useEffect(() => {
    if (billingIsCompany.value) {
      handleBillingDataChange('first_name', '');
      handleBillingDataChange('last_name', '');
    } else {
      handleBillingDataChange('company_name', '');
    }
  }, [billingIsCompany]);

  const handleReset = () => {
    setIsReset(!isReset);
    handleProfileDataReset();
    handleBillingDataReset();
  };

  const handleEditSubmit = async () => {
    const profileData = valuesFromFieldObjects(profileDataState, true);
    const billingData = valuesFromFieldObjects(billingDataState, true);

    const userUpdateDto = {
      ...profileData,
      billing_address: billingData,
    };

    try {
      setLoading(true, ProfileForm.name);
      const { data } = await apiClient.usersProtectedControllerUpdate(
        userUpdateDto,
      );
      setMessage({
        title: 'Profile data updated with success',
        color: 'success',
        iconType: 'user',
        text: `Profile data have been updated correctly`,
      });
      updateUserData();
    } catch (err) {
      handleApiError(err, setMessage, 'Error during profile data update');
    } finally {
      setLoading(false, ProfileForm.name);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isEdit) {
      handleEditSubmit();
    }
  };

  return (
    <EuiPanel>
      <EuiForm component="form" onSubmit={handleSubmit}>
        <ProfileDataForm
          isEdit={isEdit}
          email={email}
          isCompany={is_company}
          handleFormFieldChange={handleProfileDataChange}
          companyName={company_name}
          firstName={first_name}
          lastName={last_name}
        />

        <EuiDescribedFormGroup
          ratio="third"
          fullWidth
          title={<h2>Billing Address</h2>}
          description={
            <EuiText size={'s'}>
              <p>Use this form to change your billing information</p>
            </EuiText>
          }
        >
          <BillingForm
            user={user}
            handleBillingDataChange={handleBillingDataChange}
            email={billingEmail}
            isCompany={billingIsCompany}
            firstName={billingFirstName}
            lastName={billingLastName}
            companyName={billingCompanyName}
            taxCode={billingTaxCode}
            country={billingCountry}
            invoiceRecipientCode={billingInvoiceRecipientCode}
            address={billingAddress}
            city={billingCity}
            postcode={billingPostcode}
            isReset={isReset}
            isEdit={isEdit}
          />
          <EuiSpacer size="xl" />
          <EuiHorizontalRule margin="s" />

          <EuiFlexGroup justifyContent="spaceBetween" alignItems="center">
            {isEdit ?? (
              <EuiFlexItem>
                <EuiText grow={false} size="s">
                  <p>* {requiredFiled}.</p>
                </EuiText>
              </EuiFlexItem>
            )}
            <EuiFlexItem>
              <div
                css={css`
                  text-align: right;
                `}
              >
                <EuiButton
                  css={css`
                    margin-right: 8px;
                  `}
                  type="reset"
                  onClick={handleReset}
                >
                  Reset
                </EuiButton>
                <EuiButton type="submit" fill>
                  Save
                </EuiButton>
              </div>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiDescribedFormGroup>
      </EuiForm>
    </EuiPanel>
  );
}

export default ProfileForm;
