import useGlobalProvider from 'lib/providers/globalProvider';
import { useEffect } from 'react';

function FallbackLoading({ caller = FallbackLoading.name }) {
  const { setLoading } = useGlobalProvider();

  useEffect(() => {
    setLoading(true, caller);
    return () =>
      setLoading(
        false,
        caller === FallbackLoading.name
          ? FallbackLoading.name
          : `${FallbackLoading.name}-${caller}`,
      );
  }, []);

  return null;
}

export default FallbackLoading;
