/** @jsxImportSource @emotion/react */
import { EuiBasicTable, EuiI18n, EuiSpacer } from '@elastic/eui';
import { useMemo, useState } from 'react';
import {
  handleAllChange,
  tableSortToDbSort,
  dbSortToTableSort,
} from 'components/table/reducer';
import TableFooterActions from 'components/table/Table/TableFooterActions';
import TableToolbar from 'components/table/Table/TableToolbar';
import { css } from '@emotion/react';

export const ACTIONS_LABEL = '';

function Table({
  tableColumns,
  entities,
  entityName,
  entitiesName,
  isLoading,
  tableState,
  setTableQuery,
  tableDispatcher,
  showDownloadButton,
  handleDownload,
  defaultVisibleColumns,
  canSelect = true,
  handleSelectedAction = null,
  tableLayout = 'auto',
  pageSizeOptions = [5, 10, 25, 0],
  sortSpecialFields = {},
  sortSpecialFieldsMapping = {},
  selectedEntitiesText = 'Selected',
  selectedEntitiesColor = 'primary',
  showNewEntityButton = false,
  handleNewEntityClick = null,
}) {
  const [selectedEntities, setSelectedEntities] = useState([]);
  const [compressTable, setCompressedTable] = useState(false);

  const columns = useMemo(() => tableColumns, [tableColumns]);

  const [visibleColumns, setVisibleColumns] = useState(columns);

  const onSelectionChange = (selectedItems) => {
    setSelectedEntities(selectedItems);
  };

  const onTableChange = ({ page = {}, sort = {} }) => {
    const { index: pageIndex, size: pageSize } = page;
    const { sortDb, useSpecialQuery } = tableSortToDbSort(
      sort,
      sortSpecialFields,
    );
    handleAllChange(
      pageIndex,
      pageSize,
      sortDb,
      tableDispatcher,
      useSpecialQuery,
    );
  };

  let selection = null;
  if (canSelect) {
    selection = {
      selectable: (entity) => true,
      onSelectionChange,
    };
  }

  return (
    <>
      <TableToolbar
        totalEntities={entities.meta.totalItems}
        originalColumns={columns}
        setColumns={setVisibleColumns}
        entityName={entityName}
        entitiesName={entitiesName}
        selectedEntities={selectedEntities}
        showDownloadButton={showDownloadButton}
        handleDownload={handleDownload}
        defaultVisibleColumns={defaultVisibleColumns}
        compressTable={compressTable}
        setCompressedTable={setCompressedTable}
        setTableQuery={setTableQuery}
        tableDispatcher={tableDispatcher}
        isLoading={isLoading}
        showNewEntityButton={showNewEntityButton}
        handleNewEntityClick={handleNewEntityClick}
      />
      <EuiBasicTable
        css={css`
          div {
            overflow-x: auto;
          }
        `}
        tableCaption={`${entitiesName} table`}
        items={entities.data}
        loading={isLoading}
        itemId="_id"
        rowHeader="_id"
        columns={visibleColumns}
        isSelectable={true}
        selection={selection}
        pagination={{
          pageIndex: tableState.currentPage,
          pageSize: tableState.pageSize,
          totalItemCount: entities.meta.totalItems,
          pageSizeOptions: pageSizeOptions,
          showPerPageOptions: true,
        }}
        sorting={{
          sort: dbSortToTableSort(tableState.sort, sortSpecialFieldsMapping),
          enableAllColumns: false,
          readOnly: false,
        }}
        onChange={onTableChange}
        tableLayout={tableLayout}
        hasActions={true}
        responsive={true}
        compressed={compressTable}
        noItemsMessage={
          <EuiI18n
            token={'table.noEntityFound'}
            default={`No {entityName} found`}
            values={{ entityName: entitiesName }}
          />
        }
      />
      <EuiSpacer size="l" />
      <TableFooterActions
        handleSelectedAction={handleSelectedAction}
        selectedEntities={selectedEntities}
        color={selectedEntitiesColor}
        selectedText={selectedEntitiesText}
      />
    </>
  );
}

export default Table;
