/** @jsxImportSource @emotion/react */
import ColorPicker from 'components/Creator/CreatorCanvas/CanvasHeader/UtilityHeaderComponents/ActionsComponents/ColorPicker';
import FontFamilySelect from 'components/Creator/CreatorCanvas/CanvasHeader/UtilityHeaderComponents/ActionsComponents/FontFamilySelect';
import FontSizeAction from 'components/Creator/CreatorCanvas/CanvasHeader/UtilityHeaderComponents/ActionsComponents/FontSizeAction';
import FontStyleAction from 'components/Creator/CreatorCanvas/CanvasHeader/UtilityHeaderComponents/ActionsComponents/FontStyleAction';
import FontAlignAction from 'components/Creator/CreatorCanvas/CanvasHeader/UtilityHeaderComponents/ActionsComponents/FontAlignAction';

function TextObjectActions({ objects }) {
  return (
    <>
      <FontFamilySelect
        objects={objects}
        label={'Font family'}
        marginRight={4}
      />
      <ColorPicker
        objects={objects}
        optionName={'fill'}
        label={'Text color'}
        marginRight={4}
      />
      <FontSizeAction objects={objects} />
      <FontStyleAction objects={objects} />
      <FontAlignAction objects={objects} />
    </>
  );
}

export default TextObjectActions;
