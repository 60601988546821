/** @jsxImportSource @emotion/react */
import { useEuiTheme } from '@elastic/eui';
import { css } from '@emotion/react';

function PanelButton({ content, color, onClick, textColor, hoverColor }) {
  const { euiTheme } = useEuiTheme();
  return (
    <div
      css={css`
        background-color: ${color};
        color: ${textColor}
        width: 100%;
        padding: ${euiTheme.size.base} ${euiTheme.size.base};
        display: flex;
        align-items: center;
        cursor: pointer;
        border-radius: ${euiTheme.border.radius.medium};

        &:hover {
          background-color: ${hoverColor};
        }
      `}
      onClick={onClick}
    >
      {content}
    </div>
  );
}

export default PanelButton;
