/** @jsxImportSource @emotion/react */
import { useFont } from 'lib/hooks/api/fonts';
import { REDUCER_ACTIONS } from 'components/Profile/ProfileForm';
import React, { useEffect, useReducer, useRef } from 'react';
import {
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiForm,
  EuiFormRow,
  EuiSpacer,
  EuiText,
  useEuiI18n,
} from '@elastic/eui';
import InputField from 'components/forms/fields/InputField';
import TextField from 'components/forms/fields/TextField';
import SelectField from 'components/forms/fields/SelectField';
import { css } from '@emotion/react';
import {
  getEntityListCacheKey,
  invalidateCacheEntry,
  QUERY_KEY_MAPPING,
  useCreateApiCall,
  useUpdateApiCall,
} from 'lib/hooks/api/common';
import { handleApiError } from 'lib/ErrorService';
import useAuth from 'lib/providers/authProvider';
import { useQueryClient } from '@tanstack/react-query';
import useGlobalProvider from 'lib/providers/globalProvider';

export const allowedFontFileTypes = ['font/otf', 'font/ttf'];

export const fontWeightOptions = [
  {
    value: 'regular',
    text: 'Regular',
  },
  {
    value: 'bold',
    text: 'Bold',
  },
];

export const fontStyleOptions = [
  {
    value: 'normal',
    text: 'Normal',
  },
  {
    value: 'italic',
    text: 'Italic',
  },
];

export const fontDataDefaultState = {
  file: {
    value: null,
    isInvalid: false,
    errors: [],
    label: 'Font file',
    is_required: true,
    edit_disabled: false,
  },
  family: {
    value: '',
    isInvalid: false,
    errors: [],
    label: 'Family',
    is_required: true,
    edit_disabled: false,
  },
  weight: {
    value: fontWeightOptions[0].value,
    isInvalid: false,
    errors: [],
    label: 'Weight',
    is_required: false,
    edit_disabled: false,
  },
  style: {
    value: fontStyleOptions[0].value,
    isInvalid: false,
    errors: [],
    label: 'Style',
    is_required: false,
    edit_disabled: false,
  },
};

export function fontDataReducer(state, action) {
  switch (action.type) {
    case REDUCER_ACTIONS.change: {
      return {
        ...state,
        [action.field]: {
          ...state[action.field],
          value: action.value,
          isInvalid: false,
          errors: [],
        },
      };
    }
    case REDUCER_ACTIONS.error: {
      return {
        ...state,
        [action.field]: {
          ...state[action.field],
          isInvalid: true,
          errors: action.errors,
        },
      };
    }
    case REDUCER_ACTIONS.reset: {
      return {
        ...initFontDataState({ font: action.font, isEdit: action.isEdit }),
      };
    }
    default: {
      throw Error('Unknown action: ' + action.type);
    }
  }
}

export function initFontDataState({ font, isEdit }) {
  const state = {};
  Object.keys(fontDataDefaultState).forEach((key) => {
    if (font && isEdit) {
      state[key] = { ...fontDataDefaultState[key], value: font[key] };
    } else {
      state[key] = fontDataDefaultState[key];
    }
  });
  return state;
}

function FontForm({
  fontId,
  isEdit,
  handleEditFontIdChange,
  direction = 'row',
  afterSubmitCallback = null,
}) {
  const { setMessage, setLoading } = useGlobalProvider();
  const { font } = useFont(fontId, [], true, {});
  const fontFileRef = useRef();
  const [fontFormState, fontFormDispatcher] = useReducer(
    fontDataReducer,
    initFontDataState({ font, isEdit }),
  );
  const { apiClient, setRefreshUser } = useAuth();
  const queryClient = useQueryClient();

  const { mutateAsync: createFontMutate } = useCreateApiCall(
    QUERY_KEY_MAPPING.fonts,
    false,
  );

  const { mutateAsync: updateFontMutate } = useUpdateApiCall(
    QUERY_KEY_MAPPING.fonts,
    false,
  );

  const handleFileChange = (files) => {
    if (files.length > 0) {
      fontFormDispatcher({
        type: REDUCER_ACTIONS.change,
        field: 'file',
        value: files[0],
      });
    }
  };

  const handleFieldChange = (field, value) => {
    fontFormDispatcher({
      type: REDUCER_ACTIONS.change,
      field,
      value,
    });
  };

  const handleReset = () => {
    if (fontFileRef.current) {
      fontFileRef.current.removeFiles();
    }
    fontFormDispatcher({
      type: REDUCER_ACTIONS.reset,
      font: null,
      isEdit: false,
    });
    handleEditFontIdChange(null);
  };

  const addFontTitle = useEuiI18n('Add new font', 'Add new font');
  const editFontTitle = useEuiI18n('Edit font', 'Edit font');
  const requiredFiled = useEuiI18n('requiredField', 'required field');

  useEffect(() => {
    if (font) {
      handleFieldChange('family', font.family);
      handleFieldChange('weight', font.weight);
      handleFieldChange('style', font.style);
    } else {
      handleReset();
    }
  }, [font]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let isValid = true;
    if (!isEdit) {
      if (!fontFormState.file.value) {
        isValid = false;
        fontFormDispatcher({
          type: REDUCER_ACTIONS.error,
          field: 'file',
          errors: ['The file field is required'],
        });
      }
      if (fontFormState.family.value === '') {
        isValid = false;
        fontFormDispatcher({
          type: REDUCER_ACTIONS.error,
          field: 'family',
          errors: ['The family field is required'],
        });
      }
    }
    try {
      if (direction === 'row') {
        setLoading(true, FontForm.name);
      }
      const fontData = {
        file: fontFormState.file.value,
        family: fontFormState.family.value,
        weight: fontFormState.weight.value,
        style: fontFormState.style.value,
      };
      if (fontData.file === null) {
        delete fontData['file'];
      }
      if (isValid) {
        if (isEdit) {
          const { data } = await updateFontMutate({
            apiClient,
            fn: 'fontsUsersControllerUpdate',
            args: [
              font._id,
              [],
              fontData.file,
              fontData.family,
              fontData.weight,
              fontData.style,
            ],
          });
          setMessage({
            title: 'Font updated with success',
            color: 'success',
            iconType: 'function',
            text: `Font "${data.family}" has been updated correctly`,
          });
          if (afterSubmitCallback) {
            afterSubmitCallback(data);
          }
        } else {
          const { data } = await createFontMutate({
            apiClient,
            fn: 'fontsUsersControllerCreate',
            args: [
              fontData.file,
              fontData.family,
              fontData.weight,
              fontData.style,
            ],
          });
          setMessage({
            title: 'Font uploaded with success',
            color: 'success',
            iconType: 'function',
            text: `Font "${data.family}" has been uploaded correctly`,
          });
          if (afterSubmitCallback) {
            afterSubmitCallback(data);
          }
        }
        invalidateCacheEntry(queryClient, QUERY_KEY_MAPPING.fonts, 'list');
        setRefreshUser((prev) => !prev);
        handleReset();
      }
    } catch (err) {
      handleApiError(err, setMessage, 'Error during font saving');
    } finally {
      if (direction === 'row') {
        setLoading(false, FontForm.name);
      }
    }
  };

  return (
    <>
      <EuiText>
        <h3>{isEdit ? editFontTitle : addFontTitle}</h3>
      </EuiText>
      <EuiSpacer size={'l'} />
      <EuiForm component={'form'} onSubmit={handleSubmit}>
        <EuiFormRow fullWidth>
          <EuiFlexGroup direction={direction}>
            <EuiFlexItem>
              <InputField
                showLabel={true}
                field={fontFormState.file}
                fieldRef={fontFileRef}
                display={'default'}
                compressed={true}
                accept={allowedFontFileTypes}
                fullWidth={true}
                handleOnChange={(files) => handleFileChange(files)}
              />
            </EuiFlexItem>
            <EuiFlexItem>
              <TextField
                field={fontFormState.family}
                compressed={true}
                fullWidth={true}
                handleOnChange={(e) =>
                  handleFieldChange('family', e.target.value)
                }
              />
            </EuiFlexItem>
            <EuiFlexItem>
              <SelectField
                options={fontWeightOptions}
                field={fontFormState.weight}
                compressed={true}
                fullWidth={true}
                handleOnChange={(e) =>
                  handleFieldChange('weight', e.target.value)
                }
              />
            </EuiFlexItem>
            <EuiFlexItem>
              <SelectField
                options={fontStyleOptions}
                field={fontFormState.style}
                compressed={true}
                fullWidth={true}
                handleOnChange={(e) =>
                  handleFieldChange('style', e.target.value)
                }
              />
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFormRow>
        <EuiSpacer size="l" />
        <EuiFlexGroup justifyContent="spaceBetween" alignItems="center">
          {isEdit ?? (
            <EuiFlexItem>
              <EuiText grow={false} size="s">
                <p>* {requiredFiled}.</p>
              </EuiText>
            </EuiFlexItem>
          )}
          <EuiFlexItem>
            <div
              css={css`
                text-align: right;
              `}
            >
              <EuiButton
                css={css`
                  ${direction === 'row'
                    ? `margin-right: 8px;`
                    : `margin-bottom: 8px`}
                `}
                type="reset"
                size={'s'}
                onClick={handleReset}
              >
                Reset
              </EuiButton>
              <EuiButton type="submit" fill size={'s'}>
                {isEdit ? 'Update' : 'Add'}
              </EuiButton>
            </div>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiForm>
    </>
  );
}

export default FontForm;
