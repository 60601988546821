import React from "react";
import {
  EuiHeaderSectionItem,
  EuiHeaderLinks,
  EuiHeaderLink,
  EuiI18n,
} from "@elastic/eui";
import { getMainNavbarPages } from "lib/data/pages";
import useNavigation from "lib/providers/navigationProvider";
import { Link } from "react-router-dom";

function PagesMenu() {
  const { currentPage } = useNavigation();
  const mainPages = getMainNavbarPages();

  return (
    <EuiHeaderSectionItem>
      <EuiHeaderLinks aria-label="Hello Label navigation">
        {mainPages.map((page, index) => (
          <Link key={index} to={page.url}>
            <EuiHeaderLink
              isActive={currentPage === page.code}
              iconType={page.icon}
            >
              <EuiI18n token={page.label} default={page.label} />
            </EuiHeaderLink>
          </Link>
        ))}
      </EuiHeaderLinks>
    </EuiHeaderSectionItem>
  );
}

export default PagesMenu;
