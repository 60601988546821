import Validator from 'validatorjs';
import isPostalCode from 'validator/es/lib/isPostalCode';

export function setUpValidator(locale) {
  Validator.register(
    'isPostalCode',
    function (value, requirement, attribute) {
      return isPostalCode(value, 'any');
    },
    'The :attribute is not a valid postCode.',
  );
}
