import { EuiHeaderSectionItem } from '@elastic/eui';
import React from 'react';
import useCanvas from 'lib/providers/canvasProvider';
import ActionIconGroup from 'components/Creator/CreatorCanvas/CanvasHeader/UtilityHeaderComponents/ActionIconGroup';
import { ReactComponent as FlipH } from 'assets/customIcons/FlipHorizontal.svg';
import { ReactComponent as FlipV } from 'assets/customIcons/FlipVertical.svg';

const positionActions = [
  {
    label: 'Flip horizontally',
    icon: FlipH,
    value: 'flipX',
  },
  {
    label: 'Flip vertically',
    icon: FlipV,
    value: 'flipY',
  },
];

function FlipActions() {
  const { editor } = useCanvas();

  const handleFlipActionClick = (value) => {
    editor.flipSelection(value);
  };

  return (
    <EuiHeaderSectionItem>
      <ActionIconGroup
        actions={positionActions}
        onActionClick={handleFlipActionClick}
      />
    </EuiHeaderSectionItem>
  );
}

export default FlipActions;
