/** @jsxImportSource @emotion/react */
import { EuiOverlayMask, useEuiTheme } from '@elastic/eui';
import { css } from '@emotion/react';
import {
  euiLoadingLogoIconStyles,
  euiLoadingLogoStyles,
} from 'components/Loading/Loading.style';
import LogoIcon from 'components/Loading/LogoIcon';
import useGlobalProvider from 'lib/providers/globalProvider';

export const LoadingComponent = ({ size = 'xl' }) => {
  const { euiTheme } = useEuiTheme();

  const styles = euiLoadingLogoStyles({ euiTheme });
  const cssStyles = [styles.euiLoadingLogo, styles[size]];

  const iconStyles = euiLoadingLogoIconStyles({ euiTheme });
  const iconCssStyles = [iconStyles.euiLoadingLogo__icon];

  return (
    <span
      className="euiLoadingLogo"
      css={cssStyles}
      role="progressbar"
      aria-label={'Loading...'}
    >
      <span css={iconCssStyles}>
        <LogoIcon
          css={css`
            width: calc(${euiTheme.size[size]} * 2.5);
            height: calc(${euiTheme.size[size]} * 2.5);
          `}
        />
      </span>
    </span>
  );
};

function FullPageLoader({ visible, isEmptyPage, size = 'xl' }) {
  const { loading } = useGlobalProvider();

  if (visible && loading) {
    return (
      <EuiOverlayMask
        headerZindexLocation="below"
        css={css`
          z-index: 999 !important;
        `}
      >
        <LoadingComponent size={size} />
      </EuiOverlayMask>
    );
  }

  if (isEmptyPage && loading) {
    return (
      <div
        css={css`
          width: 100vw;
          height: 100vh;
          display: flex;
          justify-content: center;
          align-items: center;
          background: rgba(0, 0, 0, 0.5);
          font-family: 'Inter', BlinkMacSystemFont, Helvetica, Arial, sans-serif;
        `}
      >
        <LoadingComponent size={size} />
      </div>
    );
  }

  return null;
}

export default FullPageLoader;
