/** @jsxImportSource @emotion/react */
import {
  EuiFlexGrid,
  EuiFlexItem,
  EuiSkeletonLoading,
  EuiSkeletonTitle,
  EuiSkeletonText,
  EuiSkeletonRectangle,
  EuiPanel,
  EuiSpacer,
} from '@elastic/eui';
import { css } from '@emotion/react';
import CustomECommercePlanCard from 'components/Plans/CustomECommercePlanCard';
import PlanCard from 'components/Plans/PlanCard';
import { CUSTOM_ECOMMERCE_PLAN_CODE } from 'lib/api/plans';
import { getStaticServerFilePath } from 'lib/utils';

function PlansList({
  plans,
  selectedPlan,
  setSelectedPlan,
  removeUnselected,
  isLoading = false,
  initialCustomNLabels = 0,
}) {
  if (plans || isLoading) {
    return (
      <PlansListSkeleton
        isLoading={isLoading}
        content={
          <EuiFlexGrid columns={removeUnselected ? 1 : 4} gutterSize="l">
            {plans?.data.map((planData, index) =>
              removeUnselected && planData.code !== selectedPlan.code ? null : (
                <EuiFlexItem key={index}>
                  <PlanCard
                    planData={{
                      ...planData,
                      icon: getStaticServerFilePath(planData.icon),
                    }}
                    removeUnselected={removeUnselected}
                    selected={selectedPlan}
                    setSelected={setSelectedPlan}
                  />
                </EuiFlexItem>
              ),
            )}
            {removeUnselected &&
            CUSTOM_ECOMMERCE_PLAN_CODE !== selectedPlan.code ? null : (
              <EuiFlexItem>
                <CustomECommercePlanCard
                  removeUnselected={removeUnselected}
                  selected={selectedPlan}
                  setSelected={setSelectedPlan}
                  initialLabels={initialCustomNLabels}
                />
              </EuiFlexItem>
            )}
          </EuiFlexGrid>
        }
      />
    );
  } else {
    return null;
  }
}

function PlansListSkeleton({ isLoading, content }) {
  return (
    <EuiSkeletonLoading
      isLoading={isLoading}
      loadedContent={content}
      loadingContent={
        <EuiFlexGrid gutterSize="l" columns={4}>
          {[1, 2, 3, 4, 5, 6, 7, 8].map((key) => (
            <EuiFlexItem key={key} grow={true}>
              <PlanCardSkeleton />
            </EuiFlexItem>
          ))}
        </EuiFlexGrid>
      }
    />
  );
}

export function PlanCardSkeleton() {
  const centered = css`
    margin: 0 auto;
  `;

  return (
    <EuiPanel
      css={css`
        text-align: center;
      `}
    >
      <EuiSkeletonRectangle width={40} height={40} css={centered} />
      <EuiSpacer size="s" />
      <EuiSkeletonTitle size="l" css={centered} />
      <EuiSpacer size="s" />
      <EuiSkeletonText lines={3} css={centered} />
      <EuiSpacer size="m" />
      <EuiSkeletonTitle size="l" css={centered} />
      <EuiSkeletonRectangle width="100%" height={40} css={centered} />
    </EuiPanel>
  );
}

export default PlansList;
