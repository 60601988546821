/** @jsxImportSource @emotion/react */
import { EuiSpacer, EuiText, EuiFlexGroup, EuiFlexItem } from '@elastic/eui';
import { css } from '@emotion/react';
import DateField from 'components/forms/fields/DataField';
import moment from 'moment/moment';

function PlanActivationDate({
  activationDate,
  setActivationDate,
  removeUnselected,
}) {
  return (
    <EuiFlexGroup
      alignItems={removeUnselected ? undefined : 'flexEnd'}
      direction={removeUnselected ? 'column' : 'row'}
    >
      <EuiFlexItem>
        <EuiText>
          <h4>Select the activation date for your new plan.</h4>
        </EuiText>
        <EuiSpacer size="m" />
        <EuiText color={'subdued'} size={'s'}>
          <p
            css={css`
              margin-block-end: 6px !important;
            `}
          >
            Use the activation date to specify the day and time on which you
            would like to start your new plan.
          </p>
          <p
            css={css`
              margin-block-end: 0px !important;
            `}
          >
            Please note that the activation date applies only to plans with a
            limited validity.
          </p>
        </EuiText>
      </EuiFlexItem>
      <EuiFlexItem>
        <DateField
          field={{
            value: activationDate,
            isInvalid: false,
            errors: [],
            label: 'Activation date',
            is_required: false,
          }}
          fullWidth={true}
          showTimeSelect={true}
          handleOnChange={(date) => setActivationDate(date)}
          maxDate={moment().add(1, 'y')}
          dateFormat={'DD/MM/YYYY hh:mm A'}
          showUtfOffset={false}
        />
      </EuiFlexItem>
    </EuiFlexGroup>
  );
}

export default PlanActivationDate;
