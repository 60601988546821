/** @jsxImportSource @emotion/react */
import useCanvas from 'lib/providers/canvasProvider';
import { useEffect, useState } from 'react';
import { EuiHeaderSectionItem } from '@elastic/eui';
import { css } from '@emotion/react';
import ActionNumberInput from 'components/Creator/CreatorCanvas/CanvasHeader/UtilityHeaderComponents/ActionNumberInput';

function FontSizeAction({ objects }) {
  const { editor, activeObjectUpdated } = useCanvas();
  const [fontSize, setFontSize] = useState(
    editor.getActiveStyle('fontSize', objects[0]),
  );

  useEffect(() => {
    setFontSize(editor.getActiveStyle('fontSize', objects[0]));
  }, [objects, activeObjectUpdated]);

  const handleFontSizeChange = (value) => {
    const newFontSize = parseInt(value);
    setFontSize(newFontSize);
    objects.forEach((object) => {
      editor.setActiveStyle('fontSize', newFontSize, object);
    });
  };

  return (
    <EuiHeaderSectionItem
      css={css`
        margin-right: 4px;
      `}
    >
      <ActionNumberInput
        label={'Font size'}
        step={1}
        compressed={true}
        value={fontSize}
        onChange={handleFontSizeChange}
        max={300}
        min={1}
        width={50}
      />
    </EuiHeaderSectionItem>
  );
}

export default FontSizeAction;
