import { EuiText } from '@elastic/eui';

function PayPal({ amount, objectPurchased, additionalNotes }) {
  return (
    <EuiText>
      <h3>PayPal payment method</h3>
    </EuiText>
  );
}

export default PayPal;
