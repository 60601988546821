import {
  EuiDescribedFormGroup,
  EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiHorizontalRule,
  EuiSpacer,
  EuiSwitch,
  EuiText,
} from '@elastic/eui';
import { REDUCER_ACTIONS } from 'components/Profile/ProfileForm/index';
import LabelHelper from 'components/Profile/ProfileForm/LabelHelper';

export const profileDataDefaultState = {
  email: {
    value: '',
    isInvalid: false,
    errors: [],
    label: 'Email',
    is_required: true,
    edit_disabled: true,
  },
  is_company: {
    value: false,
    isInvalid: false,
    errors: [],
    label: 'Switch between company and private account',
    is_required: false,
    edit_disabled: false,
  },
  first_name: {
    value: '',
    isInvalid: false,
    errors: [],
    label: 'First name',
    is_required: true,
    edit_disabled: false,
  },
  last_name: {
    value: '',
    isInvalid: false,
    errors: [],
    label: 'Last name',
    is_required: true,
    edit_disabled: false,
  },
  company_name: {
    value: '',
    isInvalid: false,
    errors: [],
    label: 'Company name',
    is_required: true,
    edit_disabled: false,
  },
};

export const profileDataValidationRules = {
  email: ['required', 'email'],
  is_company: ['required', 'boolean'],
  first_name: [{ required_if: ['is_company', false] }],
  last_name: [{ required_if: ['is_company', false] }],
  company_name: [{ required_if: ['is_company', true] }],
};

export function profileDataReducer(state, action) {
  switch (action.type) {
    case REDUCER_ACTIONS.change: {
      return {
        ...state,
        [action.field]: {
          ...state[action.field],
          value: action.value,
          isInvalid: false,
          errors: [],
        },
      };
    }
    case REDUCER_ACTIONS.error: {
      return {
        ...state,
        [action.field]: {
          ...state[action.field],
          isInvalid: true,
          errors: action.errors,
        },
      };
    }
    case REDUCER_ACTIONS.reset: {
      return {
        ...initProfileDataState({ user: action.user, isEdit: action.isEdit }),
      };
    }
    default: {
      throw Error('Unknown action: ' + action.type);
    }
  }
}

export function initProfileDataState({ user, isEdit }) {
  const state = {};
  Object.keys(profileDataDefaultState).forEach((key) => {
    if (user && isEdit) {
      state[key] = { ...profileDataDefaultState[key], value: user[key] };
    } else {
      state[key] = profileDataDefaultState[key];
    }
  });
  return state;
}

function ProfileDataForm({
  isEdit,
  email,
  isCompany,
  handleFormFieldChange,
  companyName,
  firstName,
  lastName,
  plainFields = false,
}) {
  const FormFields = (
    <>
      <EuiFormRow fullWidth>
        <EuiFlexGroup>
          <EuiFlexItem>
            <EuiFormRow
              label={<LabelHelper field={email} isEdit={isEdit} />}
              isDisabled={isEdit && email.edit_disabled}
              isInvalid={email.isInvalid}
              error={email.errors}
              fullWidth
            >
              <EuiFieldText
                name="email"
                type={'email'}
                fullWidth
                value={email.value}
                isInvalid={email.isInvalid}
                onChange={
                  isEdit
                    ? () => {}
                    : (e) => handleFormFieldChange('email', e.target.value)
                }
              />
            </EuiFormRow>
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiFormRow
              fullWidth
              label={<LabelHelper field={isCompany} isEdit={isEdit} />}
              hasChildLabel={false}
              isDisabled={isEdit && isCompany.edit_disabled}
            >
              <EuiSwitch
                name="is_company"
                label={isCompany.value ? 'Company account' : 'Private account'}
                checked={isCompany.value}
                onChange={(e) =>
                  handleFormFieldChange('is_company', !isCompany.value)
                }
              />
            </EuiFormRow>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFormRow>

      <EuiFormRow fullWidth>
        <EuiFlexGroup>
          <EuiFlexItem>
            {isCompany.value ? (
              <EuiFormRow
                fullWidth
                label={<LabelHelper field={companyName} isEdit={isEdit} />}
                isInvalid={companyName.isInvalid}
                isDisabled={isEdit && companyName.edit_disabled}
                error={companyName.errors}
              >
                <EuiFieldText
                  fullWidth
                  name="company_name"
                  value={companyName.value}
                  isInvalid={companyName.isInvalid}
                  onChange={(e) =>
                    handleFormFieldChange('company_name', e.target.value)
                  }
                />
              </EuiFormRow>
            ) : (
              <EuiFormRow
                fullWidth
                label={<LabelHelper field={firstName} isEdit={isEdit} />}
                isInvalid={firstName.isInvalid}
                isDisabled={isEdit && firstName.edit_disabled}
                error={firstName.errors}
              >
                <EuiFieldText
                  fullWidth
                  name="first_name"
                  value={firstName.value}
                  isInvalid={firstName.isInvalid}
                  onChange={(e) =>
                    handleFormFieldChange('first_name', e.target.value)
                  }
                />
              </EuiFormRow>
            )}
          </EuiFlexItem>
          {isCompany.value ? null : (
            <EuiFlexItem>
              <EuiFormRow
                fullWidth
                label={<LabelHelper field={lastName} isEdit={isEdit} />}
                isInvalid={lastName.isInvalid}
                isDisabled={isEdit && lastName.edit_disabled}
                error={lastName.errors}
              >
                <EuiFieldText
                  fullWidth
                  name="last_name"
                  value={lastName.value}
                  isInvalid={lastName.isInvalid}
                  onChange={(e) =>
                    handleFormFieldChange('last_name', e.target.value)
                  }
                />
              </EuiFormRow>
            </EuiFlexItem>
          )}
        </EuiFlexGroup>
      </EuiFormRow>
    </>
  );

  if (plainFields) {
    return FormFields;
  }

  return (
    <EuiDescribedFormGroup
      ratio="third"
      fullWidth
      title={<h2>Profile Data</h2>}
      description={
        <EuiText size={'s'}>
          <p>Use this form to change your personal information</p>
        </EuiText>
      }
    >
      {FormFields}
      <EuiSpacer size="xl" />
      <EuiHorizontalRule margin="s" />
    </EuiDescribedFormGroup>
  );
}

export default ProfileDataForm;
