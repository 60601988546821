import { fabric } from 'fabric';
import { DEFAULT_FIELD_TYPE } from 'lib/templateFieldsUtils';
import {
  keepAspectRatio,
  preventResizing,
} from 'lib/canvasEditor/dist/drawing/object_utils';
import {
  getCenterLeftPosition,
  getCenterTopPosition,
  setObjectInitialPosition,
  updateObjectPosition,
} from 'lib/canvasEditor/dist/utils';

export function drawBarcode(editor, barcodeSvg, displayText, objOptions) {
  return new Promise((resolve, reject) => {
    fabric.loadSVGFromString(barcodeSvg, async function (objects, options) {
      const svg = fabric.util.groupSVGElements(objects, options);

      svg.fieldMetadata = objOptions;
      svg.top = 10;
      svg.left = 10;
      const displayTextObject = new fabric.Text(displayText, {
        scaleX: 1,
        scaleY: 1,
        strokeUniform: true,
        noScaleCache: false,
        fontSize: 24,
        fill: '#000000',
        fontFamily: 'Helvetica',
        editable: false,
        lockScalingX: true,
        lockScalingY: true,
        lockSkewingX: true,
        lockSkewingY: true,
      });
      displayTextObject.fieldMetadata = {
        ...objOptions,
        type: DEFAULT_FIELD_TYPE,
      };
      displayTextObject.fieldMetadata.connectedTo = {
        object: svg,
        type: objOptions.type,
      };
      // allow rescaling only keeping aspect ratio
      svg.fieldMetadata.connectedTo = {
        object: displayTextObject,
        type: DEFAULT_FIELD_TYPE,
      };
      keepAspectRatio(svg);
      preventResizing(displayTextObject);
      editor.canvas.add(svg);
      editor.canvas.add(displayTextObject);
      const { left, top } = setObjectInitialPosition(editor.canvas, svg, true);
      updateObjectPosition(editor.canvas, svg, {
        left,
        top: top - (10 + displayTextObject.height),
      });
      updateObjectPosition(editor.canvas, displayTextObject, {
        left: svg.left + svg.width / 2 - displayTextObject.width / 2,
        top: svg.top + svg.height + 10,
      });
      editor.renderAll();
      resolve({ svg, displayTextObject });
    });
  });
}

export function drawBarcodePng(editor, barcodePng, displayText, objOptions) {
  fabric.Image.fromURL(barcodePng, function (img, isError) {
    if (isError) {
      console.log('An error occured');
    } else {
      img.fieldMetadata = objOptions;
      img.top = 10;
      img.left = 10;
      const displayTextObject = new fabric.Text(displayText, {
        scaleX: 1,
        scaleY: 1,
        strokeUniform: true,
        noScaleCache: false,
        fontSize: 24,
        fill: '#000000',
        fontFamily: 'Helvetica',
        editable: false,
        lockScalingX: true,
        lockScalingY: true,
        lockSkewingX: true,
        lockSkewingY: true,
      });
      displayTextObject.top = 10 + img.height + 10;
      displayTextObject.left = 10 + img.width / 2 - displayTextObject.width / 2;
      displayTextObject.fieldMetadata = {
        ...objOptions,
        type: DEFAULT_FIELD_TYPE,
      };
      displayTextObject.fieldMetadata.connectedTo = {
        object: img,
        type: objOptions.type,
      };
      img.fieldMetadata.connectedTo = {
        object: displayTextObject,
        type: DEFAULT_FIELD_TYPE,
      };
      editor.canvas.add(img);
      editor.canvas.add(displayTextObject);
      editor.renderAll();
    }
  });
}
