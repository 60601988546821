import { EuiSwitch, useEuiI18n, useEuiTheme } from '@elastic/eui';
import { useState } from 'react';
import HeaderPopover from 'components/Creator/CreatorCanvas/CanvasHeader/HeaderPopover';
import LogoUploader from 'components/Creator/CreatorCanvas/CanvasPanel/ActionPanels/LogoUploader';
import ShapeSelector from 'components/Creator/CreatorCanvas/CanvasPanel/ActionPanels/ShapeSelector';
import TemplateFieldsSelector from 'components/Creator/CreatorCanvas/CanvasPanel/ActionPanels/TemplateFieldsSelector';
import useGlobalProvider from 'lib/providers/globalProvider';
import useNavigation from 'lib/providers/navigationProvider';
import useAuth from 'lib/providers/authProvider';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import {
  invalidateCacheEntry,
  QUERY_KEY_MAPPING,
  useCreateApiCall,
  useUpdateApiCall,
} from 'lib/hooks/api/common';
import { buildTemplateObject } from 'lib/api/templates';
import { handleApiError } from 'lib/ErrorService';
import TemplateOptions from 'components/Creator/TemplateOptions';
import TextBoxSelector from 'components/Creator/CreatorCanvas/CanvasPanel/ActionPanels/TextBoxSelector';
import { getShortcutByCode } from 'lib/canvasEditor/dist/keyboardAndWheelActions';
import useCanvas from 'lib/providers/canvasProvider';

export function useEditorActions(
  editor,
  selectedObjects,
  templateMetadata,
  fieldsDisplayed,
  setCurrentZoom,
  setTemplateMetadata,
  setCreatorMode,
  templateData,
) {
  const { euiTheme } = useEuiTheme();
  const { setMessage, setLoading } = useGlobalProvider();
  const { setFullPageLayout } = useNavigation();
  const { apiClient, setRefreshUser } = useAuth();
  const [confirmModal, setConfirmModal] = useState(null);
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [gridVisible, setGridVisible] = useState(false);
  const [gridGroup, setGridGroup] = useState(null);
  const digitHere = useEuiI18n('Digit here', 'digit here');

  const { canvasOffset, autoSaveEnabled, setAutoSaveEnabled, destroyEditor } =
    useCanvas();

  const { mutateAsync: createTemplateMutate } = useCreateApiCall(
    QUERY_KEY_MAPPING.templates,
    false,
  );

  const { mutateAsync: updateTemplateMutate } = useUpdateApiCall(
    QUERY_KEY_MAPPING.templates,
    false,
    true,
    'code',
  );

  const handleDiscardClick = () => {
    setConfirmModal({
      title: 'Are you sure?',
      buttonColor: 'danger',
      defaultFocusedButton: 'cancel',
      text: 'Do you really want to discard this template',
    });
  };

  const handleSaveClick = () => {
    let nDisplayed = 0;
    for (const fieldName in fieldsDisplayed) {
      if (fieldsDisplayed[fieldName]) {
        nDisplayed++;
      }
    }
    if (nDisplayed === templateMetadata.fields.length) {
      setConfirmModal({
        title: 'Do you want to save?',
        buttonColor: 'primary',
        defaultFocusedButton: 'confirm',
        text: 'All the template fields have been placed and you will be able to use this template later on for generating labels',
      });
    } else {
      setConfirmModal({
        title: 'Are you sure?',
        buttonColor: 'primary',
        defaultFocusedButton: 'confirm',
        text: `${nDisplayed}/${templateMetadata.fields.length} have been placed, the other fields will be ignored when you will use this template for generating labels`,
      });
    }
  };

  const handleDiscardConfirm = () => {
    setConfirmModal(null);
    setTemplateMetadata(null);
    setCreatorMode('metadata');
  };

  const handleBackClick = () => {
    if (editor) {
      destroyEditor();
    }
    setFullPageLayout(false);
    navigate('/templates');
  };

  const handleSaveConfirm = async (
    isAutoSave = false,
    setLastSavedJSON = null,
  ) => {
    /*if (!isAutoSave) {
      setLoading(true, 'Canvas');
    }*/
    setConfirmModal(null);
    let template;
    const canvasJSON = editor.toJSON();
    try {
      template = buildTemplateObject({
        name: templateMetadata.name,
        description: templateMetadata.description,
        height: templateMetadata.height,
        width: templateMetadata.width,
        dpi: templateMetadata.dpi,
        fields: templateMetadata.fields,
        jsonTemplate: canvasJSON,
        templateImage: editor.toPNG(),
        customFonts: templateMetadata.customFonts,
      });
    } catch (err) {
      handleApiError(err, setMessage, 'Error during template saving');
      setLoading(false, 'Canvas');
    }

    try {
      if (templateData) {
        const { data } = await updateTemplateMutate({
          apiClient,
          fn: 'templatesControllerUpdate',
          args: [templateData.code, template],
        });
        setMessage({
          title: isAutoSave
            ? 'Template auto saved'
            : 'Template updated with success',
          color: 'success',
          iconType: 'documents',
          text: isAutoSave
            ? `Template "${data.name}" has been auto saved`
            : `Template "${data.name}" has been updated correctly`,
        });
      } else {
        const { data } = await createTemplateMutate({
          apiClient,
          fn: 'templatesControllerCreate',
          args: [template],
        });
        setMessage({
          title: 'Template saved with success',
          color: 'success',
          iconType: 'documents',
          text: `Template "${data.name}" has been saved correctly`,
        });
      }
      if (isAutoSave && setLastSavedJSON) {
        setLastSavedJSON(JSON.stringify(canvasJSON));
      }
      setLoading(false, 'Canvas');
      /*if (!isAutoSave) {
        setFullPageLayout(false);
      }*/
      invalidateCacheEntry(queryClient, QUERY_KEY_MAPPING.stats, 'list');
      /*if (!isAutoSave) {
        navigate('/templates');
      }*/
      setRefreshUser((prev) => !prev);
    } catch (err) {
      handleApiError(err, setMessage, 'Error during template saving');
      setLoading(false, 'Canvas');
    }
  };

  const handleAddText = () => {
    editor.addTextBox(digitHere);
  };

  const handleAddLine = () => {
    editor.addLine();
  };

  const handleRemoveSelected = () => {
    editor.deleteSelected();
  };

  const handleClearAll = () => {
    editor.deleteAll();
    editor.historyPrevent();
    editor.drawLimitBox(
      templateMetadata.width,
      templateMetadata.height,
      euiTheme.colors.accent,
    );
    const grid = editor.drawGrid(
      templateMetadata.height,
      templateMetadata.width,
      euiTheme.colors.lightShade,
    );
    setGridGroup(grid);
    if (gridVisible) {
      grid.visible = true;
      setGridVisible(true);
    } else {
      grid.visible = false;
      setGridVisible(false);
    }
    editor.historyEnable();
  };

  const handleUndoClick = () => {
    editor.undo();
  };

  const handleRedoClick = () => {
    editor.redo();
  };

  const toggleGrid = () => {
    if (gridGroup === null) {
      const grid = editor.drawGrid(
        templateMetadata.height,
        templateMetadata.width,
        euiTheme.colors.lightShade,
      );
      setGridGroup(grid);
      setGridVisible(true);
    } else {
      gridGroup.visible = !gridVisible;
      setGridVisible(!gridVisible);
      editor.renderAll();
    }
  };

  const fitToScreen = () => {
    editor.fitToScreen(canvasOffset);
  };

  const handleZoomClick = () => {
    console.log('Zoom');
  };

  const editorActions = [
    {
      code: 'templateFields',
      icon: 'documents',
      label: 'Template fields',
      description: 'Add template fields',
      disabled: false,
      hasComponent: true,
      component: <TemplateFieldsSelector />,
    },
    {
      code: 'text',
      icon: 'visText',
      label: 'Text',
      description: 'Draw a text area',
      action: handleAddText,
      disabled: false,
      hasComponent: true,
      component: <TextBoxSelector />,
    },
    /*{
      code: 'line',
      icon: 'lineSolid',
      label: 'Line',
      description: 'Add a line',
      action: handleAddLine,
      disabled: false,
    },*/
    {
      code: 'shape',
      icon: 'kubernetesPod',
      label: 'Add a shape',
      description: 'Add a shape',
      disabled: false,
      hasComponent: true,
      component: <ShapeSelector />,
    },
    {
      code: 'image',
      icon: 'image',
      label: 'Logo',
      description: 'Add your logo',
      disabled: false,
      hasComponent: true,
      component: <LogoUploader />,
    },
  ];

  const backAction = {
    icon: 'arrowLeft',
    label: 'Discard',
    description: 'Discard template',
    action: (editor) =>
      templateData ? handleBackClick() : handleDiscardClick(),
    disabled: false,
  };

  const mainHeaderLeftActions = [
    {
      code: 'save',
      icon: 'save',
      label: 'Save',
      description: 'Save template',
      action: (editor) =>
        templateData ? handleSaveConfirm() : handleSaveClick(),
      disabled: false,
    },
    {
      code: 'enableAutoSave',
      icon: (
        <EuiSwitch
          showLabel={false}
          label="Auto Saving"
          checked={autoSaveEnabled}
          onChange={() => setAutoSaveEnabled(!autoSaveEnabled)}
          compressed
        />
      ),
      label: 'Auto Saving',
      description: autoSaveEnabled
        ? 'Disable auto saving'
        : 'Enable auto saving',
      action: (editor) => setAutoSaveEnabled(!autoSaveEnabled),
      customRender: true,
      disabled: false,
    },
    {
      code: 'undo',
      icon: 'editorUndo',
      label: 'Undo',
      description: 'Undo',
      shortcut: getShortcutByCode('undo').shortcut,
      action: handleUndoClick,
      disabled: editor ? !editor.canUndo() : false,
    },
    {
      code: 'redo',
      icon: 'editorRedo',
      label: 'Redo',
      description: 'Redo',
      shortcut: getShortcutByCode('redo').shortcut,
      action: handleRedoClick,
      disabled: editor ? !editor.canRedo() : false,
    },
    {
      code: 'cut',
      icon: 'cut',
      label: 'Remove selected',
      description: 'Remove selected',
      shortcut: getShortcutByCode('remove-selected').shortcut,
      action: handleRemoveSelected,
      disabled: selectedObjects.length === 0,
    },
    {
      code: 'trash',
      icon: 'trash',
      label: 'Clear all',
      description: 'Clear all',
      action: handleClearAll,
      disabled: false,
    },
  ];

  const mainHeaderRightActions = [
    {
      code: 'fit',
      icon: 'fullScreen',
      label: 'Fit Screen',
      description: 'Fit to screen',
      action: fitToScreen,
      disabled: false,
    },
    {
      code: 'grid',
      icon: 'grid',
      label: 'Grid',
      description: 'Toggle grid',
      action: toggleGrid,
      disabled: false,
    },
    /*{
      code: 'gear',
      icon: 'gear',
      label: 'Options',
      description: 'Options',
      disabled: false,
      hasPopover: true,
      popover: (actionItem) => (
        <HeaderPopover
          actionItem={actionItem}
          Component={TemplateOptions}
          componentProps={{ editor }}
        />
      ),
    },*/
  ];

  const zoomPanelAction = {
    code: 'zoom',
    icon: 'magnifyWithPlus',
    label: 'Zoom',
    description: 'Zoom',
    action: handleZoomClick,
    disabled: false,
  };

  return {
    editorActions,
    confirmModal,
    setConfirmModal,
    backAction,
    mainHeaderLeftActions,
    mainHeaderRightActions,
    zoomPanelAction,
    handleDiscardConfirm,
    handleSaveConfirm,
    fitToScreen,
  };
}
