/** @jsxImportSource @emotion/react */

import React from "react";
import { css } from "@emotion/react";
import { useEuiTheme } from "@elastic/eui";

function Canvas({ canvasEl, canvasWrapperEl }) {
  const { euiTheme } = useEuiTheme();

  return (
    <div
      ref={canvasWrapperEl}
      className="canvas-wrapper"
      css={css`
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: ${euiTheme.colors.lightestShade};
        height: calc(100vh - 48px);
        //-webkit-transform: scale(0.5);
        //-webkit-transform-origin: 0 0;
      `}
    >
      <canvas ref={canvasEl} />
    </div>
  );
}

export default Canvas;
