/** @jsxImportSource @emotion/react */
import { EuiI18n, EuiText, EuiButton } from '@elastic/eui';
import { css } from '@emotion/react';
import PageBanner from 'components/PageBanner';
import useAuth from 'lib/providers/authProvider';
import useGlobalProvider from 'lib/providers/globalProvider';
import useNavigation from 'lib/providers/navigationProvider';
import { PLAN_TABS_IDS } from 'pages/Plans';
import React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

function PageContentChecker() {
  const { userPlanUsageSummary } = useAuth();
  const { loading } = useGlobalProvider();
  const { currentPageInfo } = useNavigation();
  const navigate = useNavigate();

  if (!userPlanUsageSummary && currentPageInfo?.needsPlan && !loading) {
    return (
      <PageBanner
        title={
          <EuiI18n
            token="Upgrade your plan to access {pageName} page."
            default="Upgrade your plan to access {pageName} page."
            values={{ pageName: currentPageInfo.label }}
          />
        }
        body={
          <EuiText>
            <p
              css={css`
                margin-block-end: 8px !important;
              `}
            >
              <EuiI18n
                token="Without an active plan it is not possible to access this section."
                default="Without an active plan it is not possible to access this section."
              />
            </p>
            <p
              css={css`
                margin-block-end: 8px !important;
              `}
            >
              <EuiI18n
                token="Please visit our plan page and select the one that fits your requirements."
                default="Please visit our plan page and select the one that fits your requirements."
              />
            </p>
          </EuiText>
        }
        color={'warning'}
        actions={[
          <EuiButton
            color="warning"
            fill
            onClick={() =>
              navigate('/plans', { state: { tab: PLAN_TABS_IDS.New } })
            }
          >
            <EuiI18n token="Upgrade" default="Upgrade" />
          </EuiButton>,
        ]}
      />
    );
  }

  return (
    <>
      <Outlet />
    </>
  );
}

export default PageContentChecker;
