import { getControlChar, isControlKey } from 'lib/canvasEditor/dist/utils';

export const KeyboardAndWheelActions = [
  {
    code: 'remove-selected',
    type: 'keyboard',
    name: 'Remove',
    shortcut: `\u232B`,
    condition: (keyboardEvent) => keyboardEvent.key === 'Backspace',
    description: 'Remove selected objects',
    action: (editor, keyboardEvent) => {
      if (
        document.querySelectorAll('textarea:focus, input:focus').length === 0
      ) {
        editor.deleteSelected();
      }
    },
  },
  {
    code: 'copy',
    type: 'keyboard',
    shortcut: `${getControlChar()} + c`,
    name: 'Copy',
    condition: (keyboardEvent) =>
      keyboardEvent.key === 'c' && isControlKey(keyboardEvent),
    description: 'Copy selected objects',
    preventEvent: true,
    action: (editor, keyboardEvent) => {
      const target = keyboardEvent.target;
      const inputs = ['input', 'select', 'button', 'textarea'];

      if (target && inputs.indexOf(target.tagName.toLowerCase()) !== -1) {
        return;
      }
      editor.copy();
    },
  },
  {
    code: 'paste',
    type: 'keyboard',
    shortcut: `${getControlChar()} + v`,
    name: 'Paste',
    preventEvent: false,
    condition: (keyboardEvent) =>
      keyboardEvent.key === 'v' && isControlKey(keyboardEvent),
    description: 'Paste objects from clipboard',
    action: (editor, keyboardEvent) => {
      const target = keyboardEvent.target;
      const inputs = ['input', 'select', 'button', 'textarea'];

      if (target && inputs.indexOf(target.tagName.toLowerCase()) !== -1) {
        return;
      }
      editor.paste();
    },
  },
  {
    code: 'undo',
    type: 'keyboard',
    shortcut: `${getControlChar()} + z`,
    name: 'Undo',
    condition: (keyboardEvent) =>
      keyboardEvent.key === 'z' &&
      isControlKey(keyboardEvent) &&
      !keyboardEvent.shiftKey,
    description: 'Undo',
    action: (editor, keyboardEvent) => {
      editor.undo();
    },
  },
  {
    code: 'redo',
    type: 'keyboard',
    name: 'Redo',
    shortcut: `${getControlChar()} + \u21E7 + z`,
    condition: (keyboardEvent) =>
      isControlKey(keyboardEvent) &&
      keyboardEvent.shiftKey &&
      keyboardEvent.key === 'z',
    description: 'Redo',
    action: (editor, keyboardEvent) => {
      editor.redo();
    },
  },
  {
    code: 'zoomIn',
    type: 'keyboard',
    name: 'Zoom in',
    shortcut: `${getControlChar()} +`,
    condition: (keyboardEvent) =>
      isControlKey(keyboardEvent) && keyboardEvent.key === '+',
    description: 'Zoom in',
    preventEvent: true,
    action: (editor, keyboardEvent) => {
      editor.keyboardZoomIn();
    },
  },
  {
    code: 'zoomOut',
    type: 'keyboard',
    name: 'Zoom out',
    shortcut: `${getControlChar()} -`,
    condition: (keyboardEvent) =>
      isControlKey(keyboardEvent) && keyboardEvent.key === '-',
    description: 'Zoom out',
    preventEvent: true,
    action: (editor, keyboardEvent) => {
      editor.keyboardZoomOut();
    },
  },
  {
    code: 'zoomReset',
    type: 'keyboard',
    name: 'Zoom reset',
    shortcut: `${getControlChar()} + 0`,
    condition: (keyboardEvent) =>
      isControlKey(keyboardEvent) && keyboardEvent.key === '0',
    description: 'Zoom reset',
    preventEvent: true,
    action: (editor, keyboardEvent) => {
      editor.resetZoom();
    },
  },
  {
    code: 'zoom',
    type: 'wheel',
    name: 'Zoom in & out',
    shortcut: `${getControlChar()} + scroll`,
    condition: (wheelEvent) => isControlKey(wheelEvent),
    description: 'Zoom in & out',
    action: (editor, wheelEvent) => {
      let zoomAmount = wheelEvent.deltaY > 0 ? 5 : -5;
      editor.setZoomWheel(zoomAmount);
    },
  },
];

export function getShortcutByCode(code) {
  const shortcut = KeyboardAndWheelActions.filter(
    (shortcutObj) => shortcutObj.code === code,
  );
  return shortcut.length > 0 ? shortcut[0] : null;
}

function moveActiveObject(keyboardEvent, editor) {
  const key = keyboardEvent.keyCode;
  let activeObject;

  if (document.querySelectorAll('textarea:focus, input:focus').length > 0) {
    return;
  }

  if (key === 37 || key === 38 || key === 39 || key === 40) {
    keyboardEvent.preventDefault();
    activeObject = editor.canvas.getActiveObject();
    if (!activeObject) {
      return;
    }
  }

  if (key === 37) {
    activeObject.left -= 1;
  } else if (key === 39) {
    activeObject.left += 1;
  } else if (key === 38) {
    activeObject.top -= 1;
  } else if (key === 40) {
    activeObject.top += 1;
  }

  if (key === 37 || key === 38 || key === 39 || key === 40) {
    activeObject.setCoords();
    editor.renderAll();
    editor.fireEvent('object:modified');
  }
}

export function handleKeydownEvents(keyboardEvent, editor) {
  let shouldPrevent = false;
  KeyboardAndWheelActions.forEach((shortcut) => {
    if (shortcut.condition(keyboardEvent) && shortcut.type === 'keyboard') {
      shortcut.action(editor, keyboardEvent);
      if (shortcut.preventEvent) {
        shouldPrevent = true;
      }
    }
  });
  moveActiveObject(keyboardEvent, editor);
  if (shouldPrevent) {
    keyboardEvent.preventDefault();
  }
}

export function handleWheelEvents(wheelEvent, editor) {
  KeyboardAndWheelActions.forEach((shortcut) => {
    if (shortcut.condition(wheelEvent) && shortcut.type === 'wheel') {
      shortcut.action(editor, wheelEvent);
    }
  });
}
