import { EuiHeaderSectionItem } from '@elastic/eui';
import React from 'react';
import useCanvas from 'lib/providers/canvasProvider';
import ActionIconGroup from 'components/Creator/CreatorCanvas/CanvasHeader/UtilityHeaderComponents/ActionIconGroup';
import { ReactComponent as Forward } from 'assets/customIcons/Forward.svg';
import { ReactComponent as ToFront } from 'assets/customIcons/ToFront.svg';
import { ReactComponent as Backward } from 'assets/customIcons/Backward.svg';
import { ReactComponent as ToBack } from 'assets/customIcons/ToBack.svg';

const positionActions = [
  {
    label: 'Forward',
    icon: Forward,
    value: 'forward',
  },
  {
    label: 'To front',
    icon: ToFront,
    value: 'to-front',
  },
  {
    label: 'Backward',
    icon: Backward,
    value: 'backward',
  },
  {
    label: 'To back',
    icon: ToBack,
    value: 'to-back',
  },
];

function PositionActions() {
  const { editor } = useCanvas();

  const handlePositionActionClick = (value) => {
    editor.changePosition(value);
  };

  return (
    <EuiHeaderSectionItem>
      <ActionIconGroup
        actions={positionActions}
        onActionClick={handlePositionActionClick}
      />
    </EuiHeaderSectionItem>
  );
}

export default PositionActions;
