/** @jsxImportSource @emotion/react */
import {
  EuiButtonEmpty,
  EuiCard,
  EuiI18n,
  EuiIcon,
  useEuiI18n,
  EuiText,
  EuiPopover,
  EuiSpacer,
  useEuiTheme,
} from '@elastic/eui';
import { css } from '@emotion/react';
import CurrencyRender from 'components/CurrencyRender';
import { SummaryRow } from 'components/Plans/PlanSummaryViewer';
import { DEMO_PLAN_CODE } from 'lib/api/plans';
import { useState } from 'react';

function PlanCard({
  planData,
  selected,
  setSelected,
  removeUnselected,
  footer = null,
}) {
  const { euiTheme } = useEuiTheme();
  const handleSelectedChange = () => {
    setSelected(planData);
  };

  return (
    <EuiCard
      icon={<EuiIcon size="xxl" color="accent" type={planData.icon} />}
      title={<EuiI18n default={planData.name} token={planData.name} />}
      description={
        <>
          {planData?.description ? (
            <EuiI18n
              default={planData.description}
              token={planData.description}
            />
          ) : null}
          <br />
          <span
            css={css`
              display: block;
              webkit-flex-shrink: 0;
              -ms-flex-negative: 0;
              flex-shrink: 0;
              block-size: ${euiTheme.size.s};
            `}
          />
          <CurrencyRender
            value={planData.price}
            addStyle={true}
            size={'l'}
            customText={
              planData.code === DEMO_PLAN_CODE ? (
                <EuiI18n default={'Free'} token={'Free'} />
              ) : null
            }
          />
        </>
      }
      footer={
        footer ? (
          footer
        ) : (
          <PlanCardFooter planData={planData} popover={!removeUnselected} />
        )
      }
      selectable={
        removeUnselected
          ? null
          : {
              onClick: handleSelectedChange,
              isSelected: selected ? planData.code === selected.code : false,
            }
      }
    />
  );
}

function PlanCardFooter({ planData, popover = true }) {
  const seeMoreAriaLabel = useEuiI18n('See more details', 'See more details');
  const daysLabel = useEuiI18n('days', 'days');
  const dayLabel = useEuiI18n('day', 'day');
  const { validity, n_labels, n_templates, n_downloads } = planData.rules;
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const onButtonClick = () =>
    setIsPopoverOpen((isPopoverOpen) => !isPopoverOpen);
  const closePopover = () => setIsPopoverOpen(false);

  const popoverButton = (
    <EuiButtonEmpty
      iconType="iInCircle"
      size="xs"
      onClick={onButtonClick}
      aria-label={seeMoreAriaLabel}
    >
      {seeMoreAriaLabel}
    </EuiButtonEmpty>
  );

  const content = (
    <EuiText color="subdued" size="s" textAlign="center">
      <h4>Plan Details:</h4>
      <SummaryRow size="xs" name={'Labels'} value={n_labels} prefix={'#'} />
      <SummaryRow
        size="xs"
        name={'Templates'}
        value={n_templates}
        prefix={'#'}
      />
      <SummaryRow
        size="xs"
        name={'Validity'}
        value={validity}
        suffix={validity > 1 ? daysLabel : validity === 1 ? dayLabel : ''}
      />
      <SummaryRow
        size="xs"
        name={'Price'}
        value={<CurrencyRender value={planData.price} size={'xs'} />}
      />
    </EuiText>
  );

  if (popover) {
    return (
      <EuiPopover
        button={popoverButton}
        isOpen={isPopoverOpen}
        closePopover={closePopover}
        anchorPosition={'rightCenter'}
      >
        {content}
      </EuiPopover>
    );
  }

  return content;
}

export default PlanCard;
