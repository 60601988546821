/** @jsxImportSource @emotion/react */
import useCanvas from 'lib/providers/canvasProvider';
import { useEffect, useState } from 'react';
import { EuiButtonGroup, EuiHeaderSectionItem } from '@elastic/eui';
import { css } from '@emotion/react';

function FontStyleAction({ objects }) {
  const { editor, activeObjectUpdated } = useCanvas();

  const typeStyleToggleButtons = [
    {
      id: 'bold',
      label: 'Bold',
      name: 'bold',
      iconType: 'editorBold',
    },
    {
      id: 'italic',
      label: 'Italic',
      name: 'italic',
      iconType: 'editorItalic',
    },
    {
      id: 'underline',
      label: 'Underline',
      name: 'underline',
      iconType: 'editorUnderline',
    },
    {
      id: 'linethrough',
      label: 'Strikethrough',
      name: 'strikethrough',
      iconType: 'editorStrike',
    },
  ];

  const [
    typeStyleToggleButtonsIdToSelectedMap,
    setTypeStyleToggleButtonsIdToSelectedMap,
  ] = useState({});

  const onTypeStyleChange = (optionId) => {
    const newTypeStyleToggleButtonsIdToSelectedMap = {
      ...typeStyleToggleButtonsIdToSelectedMap,
      ...{
        [optionId]: !typeStyleToggleButtonsIdToSelectedMap[optionId],
      },
    };

    setTypeStyleToggleButtonsIdToSelectedMap(
      newTypeStyleToggleButtonsIdToSelectedMap,
    );
    Object.keys(newTypeStyleToggleButtonsIdToSelectedMap).forEach(
      (buttonKey) => {
        const splitResults = buttonKey.split('_');
        const suffix = splitResults[splitResults.length - 1];
        objects.forEach((object) => {
          switch (suffix) {
            case 'bold':
              if (newTypeStyleToggleButtonsIdToSelectedMap[buttonKey]) {
                editor.setActiveStyle('fontWeight', 'bold', object);
              } else {
                editor.setActiveStyle('fontWeight', 'normal', object);
              }
              break;
            case 'italic':
              if (newTypeStyleToggleButtonsIdToSelectedMap[buttonKey]) {
                editor.setActiveStyle('fontStyle', 'italic', object);
              } else {
                editor.setActiveStyle('fontStyle', 'normal', object);
              }
              break;
            case 'underline':
              if (newTypeStyleToggleButtonsIdToSelectedMap[buttonKey]) {
                editor.setActiveStyle('underline', true, object);
              } else {
                editor.setActiveStyle('underline', false, object);
              }
              break;
            case 'linethrough':
              if (newTypeStyleToggleButtonsIdToSelectedMap[buttonKey]) {
                editor.setActiveStyle('linethrough', true, object);
              } else {
                editor.setActiveStyle('linethrough', false, object);
              }
              break;
            default:
              return;
          }
        });
      },
    );
  };

  useEffect(() => {
    const isObjectBold =
      editor.getActiveStyle('fontWeight', objects[0]) === 'bold';
    const isObjectItalic =
      editor.getActiveStyle('fontStyle', objects[0]) === 'italic';
    const isObjectUnderline = !!editor.getActiveStyle('underline', objects[0]);
    const isObjectLinethrough = !!editor.getActiveStyle(
      'linethrough',
      objects[0],
    );
    setTypeStyleToggleButtonsIdToSelectedMap({
      bold: isObjectBold,
      italic: isObjectItalic,
      underline: isObjectUnderline,
      linethrough: isObjectLinethrough,
    });
  }, [objects, activeObjectUpdated]);

  return (
    <EuiHeaderSectionItem
      css={css`
        margin-right: 4px;
      `}
    >
      <EuiButtonGroup
        legend="Label text style"
        className="eui-displayInlineBlock"
        options={typeStyleToggleButtons}
        idToSelectedMap={typeStyleToggleButtonsIdToSelectedMap}
        onChange={onTypeStyleChange}
        type="multi"
        isIconOnly
        buttonSize="compressed"
      />
    </EuiHeaderSectionItem>
  );
}

export default FontStyleAction;
