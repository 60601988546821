/** @jsxImportSource @emotion/react */
import { css, Global } from '@emotion/react';
import { theme } from 'assets/theme';
import React, { createContext, useState, useContext, useEffect } from 'react';
import { EuiProvider } from '@elastic/eui';
import createCache from '@emotion/cache';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import useNavigation from 'lib/providers/navigationProvider';

const cache = createCache({
  key: 'hello-label',
  container: document.querySelector('meta[name="hello-label-style"]'),
});
cache.compat = true;

const initialState = {
  theme: 'light',
  headTitle: 'Hello Label',
};

const themeModifications = {
  ...theme,
};

const AppContext = createContext({
  theme: initialState.theme,
  headTitle: initialState.headTitle,
});

export const AppProvider = ({ children }) => {
  const [theme, setTheme] = useState(initialState.theme);
  const [headTitle, setHeadTitle] = useState(initialState.headTitle);
  const { currentPageInfo } = useNavigation();

  useEffect(() => {
    let newTheme;
    // Fetch the system theme and set the current theme accordingly at loading time
    if (
      window.matchMedia &&
      window.matchMedia('(prefers-color-scheme: dark)').matches
    ) {
      newTheme = 'dark';
    } else {
      newTheme = 'light';
    }
    // detect system theme changes
    window
      .matchMedia('(prefers-color-scheme: dark)')
      .addEventListener('change', (event) => {
        setCurrentTheme(event.matches ? 'dark' : 'light');
      });

    // if the user set a preference, load it from local storage and override the system preference
    const savedThemeLocal = localStorage.getItem('globalTheme');

    if (!!savedThemeLocal) {
      newTheme = savedThemeLocal;
    }
    setCurrentTheme(newTheme);
  }, []);

  const setCurrentTheme = (themeName) => {
    localStorage.setItem('globalTheme', themeName);
    setTheme(themeName);
  };

  return (
    <HelmetProvider>
      <AppContext.Provider
        value={{
          theme,
          headTitle,
          setCurrentTheme,
          setHeadTitle,
        }}
      >
        <Helmet>
          <link
            rel="stylesheet"
            type="text/css"
            href={`/assets/styles/theme/eui_theme_${theme}.min.css`}
          />
          <title>{`Hello Label - ${headTitle}`}</title>
        </Helmet>
        <Global
          styles={css`
            h1,
            h2,
            h3,
            h4,
            h5,
            h6,
            .euiHeaderLinks .euiButtonEmpty__text,
            .euiTitle,
            .euiHeaderLogo__text,
            .hello-label_header-font {
              font-family: 'Roboto Mono', monospace !important;
              letter-spacing: -0.5px;
            }
            .echChart .echChart__titles {
              margin-bottom: 16px;
            }
            .centered-block {
              margin: 0 auto;
            }
          `}
        />
        <EuiProvider
          cache={cache}
          colorMode={theme}
          modify={themeModifications}
        >
          {children}
        </EuiProvider>
      </AppContext.Provider>
    </HelmetProvider>
  );
};

export default function useApp() {
  return useContext(AppContext);
}
