/** @jsxImportSource @emotion/react */
import {
  EuiHeaderSectionItem,
  EuiIcon,
  EuiSwitch,
  EuiToolTip,
} from '@elastic/eui';
import DimensionsAction from 'components/Creator/CreatorCanvas/CanvasHeader/UtilityHeaderComponents/ActionsComponents/DimensionsAction';
import { css } from '@emotion/react';
import React, { useState } from 'react';
import useCanvas from 'lib/providers/canvasProvider';

function BarcodeObjectActions({ objects: object }) {
  const { editor } = useCanvas();

  const [displayText, setDisplayText] = useState(
    !!object[0].fieldMetadata.displayValue,
  );

  const handleDisplayTextChange = (e) => {
    const value = e.target.checked;
    setDisplayText(value);
    object[0].fieldMetadata.displayValue = value;
    object[0].fieldMetadata.connectedTo.object.visible = value;
    editor.canvas.fire('object:modified');
    editor.renderAll();
  };

  return (
    <>
      <DimensionsAction
        objects={object}
        initialLockRatio={true}
        lockRatioDisabled={true}
      />
      <EuiHeaderSectionItem
        css={css`
          margin-right: 4px;
        `}
      >
        <EuiToolTip
          position={'bottom'}
          content={
            'If selected the barcode will be rendered with its code belo'
          }
        >
          <EuiSwitch
            label="Display code"
            showLabel={true}
            name="display-text"
            checked={displayText}
            onChange={handleDisplayTextChange}
            compressed
          />
        </EuiToolTip>
      </EuiHeaderSectionItem>
    </>
  );
}

export default BarcodeObjectActions;
