/** @jsxImportSource @emotion/react */
import useCanvas from 'lib/providers/canvasProvider';
import {
  EuiColorPicker,
  EuiColorPickerSwatch,
  EuiHeaderSectionItem,
  useEuiTheme,
  tint,
} from '@elastic/eui';
import { useEffect, useState } from 'react';
import CanvasTooltip from 'components/Creator/CanvasTooltip';
import { css } from '@emotion/react';
import useAppTheme from 'lib/providers/appProvider';
import { object } from 'prop-types';

export function parseFabricColor(value) {
  if (value) {
    if (typeof value === 'string') {
      if (value.indexOf('rgba') !== -1) {
        return value.replace('rgba(', '').replace(')', '');
      }
      return value;
    }
  }
}

export const transparentBackground = css`
  background-color: rgb(255, 255, 255);
  background-image: linear-gradient(
      -45deg,
      rgba(57, 76, 96, 0.15) 25%,
      rgba(0, 0, 0, 0) 25%,
      rgba(0, 0, 0, 0) 75%,
      rgba(57, 76, 96, 0.15) 75%
    ),
    linear-gradient(
      -45deg,
      rgba(57, 76, 96, 0.15) 25%,
      rgba(0, 0, 0, 0) 25%,
      rgba(0, 0, 0, 0) 75%,
      rgba(57, 76, 96, 0.15) 75%
    );
  background-position:
    0 0,
    6px 6px;
  background-size: 12px 12px;
`;

function ColorPicker({
  optionName,
  objects,
  label,
  marginRight = 0,
  showButton = true,
}) {
  const { euiTheme } = useEuiTheme();
  const { editor, activeObjectUpdated } = useCanvas();
  const [color, setColor] = useState(
    parseFabricColor(editor.getActiveStyle(optionName, objects[0])),
  );

  useEffect(() => {
    setColor(parseFabricColor(editor.getActiveStyle(optionName, objects[0])));
  }, [objects, activeObjectUpdated]);

  const handleFillColorChange = (value) => {
    setColor(value);
    let color;
    if (value.charAt(0) === '#') {
      color = value;
    } else {
      color = `rgba(${value})`;
    }
    objects.forEach((object) => {
      editor.setActiveStyle(optionName, color, object);
    });
  };

  const button = (
    <span
      css={css`
        ${transparentBackground}
      `}
    >
      <EuiColorPickerSwatch
        color={color}
        aria-label={label}
        title="Container color"
        style={{ width: 32, height: 32 }}
        css={css`
          border-radius: ${euiTheme.border.radius.medium} !important;
        `}
      />
    </span>
  );

  const picker = (
    <EuiColorPicker
      css={css`
        width: 188px !important;
      `}
      onChange={handleFillColorChange}
      color={color}
      showAlpha={true}
      format="rgba"
      popoverZIndex={euiTheme.levels.toast + 1}
      button={showButton ? button : null}
      secondaryInputDisplay="bottom"
    />
  );

  return (
    <EuiHeaderSectionItem
      css={css`
        margin-right: ${marginRight}px;
      `}
    >
      {showButton ? (
        <CanvasTooltip position={'bottom'} label={label}>
          {picker}
        </CanvasTooltip>
      ) : (
        picker
      )}
    </EuiHeaderSectionItem>
  );
}

export default ColorPicker;
