/**
 * Define util functions
 */
import { fabric } from 'fabric';
import canvas from 'components/Creator/CreatorCanvas/Canvas';

/**
 * Get fabric js gradient from colorstops, orientation and angle
 * @param {Array} handlers array of color stops
 * @param {Number} width gradient width
 * @param {Number} height gradient height
 * @param {String} orientation orientation type linear/radial
 * @param {Number} angle the angle of linear gradient
 */
export const generateFabricGradientFromColorStops = (
  handlers,
  width,
  height,
  orientation,
  angle,
) => {
  const gradAngleToCoords = (angle) => {
    let anglePI = -parseInt(angle, 10) * (Math.PI / 180);
    let angleCoords = {
      x1: Math.round(50 + Math.sin(anglePI) * 50) / 100,
      y1: Math.round(50 + Math.cos(anglePI) * 50) / 100,
      x2: Math.round(50 + Math.sin(anglePI + Math.PI) * 50) / 100,
      y2: Math.round(50 + Math.cos(anglePI + Math.PI) * 50) / 100,
    };

    return angleCoords;
  };

  let bgGradient = {};
  let colorStops = [];

  for (let i in handlers) {
    colorStops.push({
      id: i,
      color: handlers[i].color,
      offset: handlers[i].position / 100,
    });
  }

  if (orientation === 'linear') {
    let angleCoords = gradAngleToCoords(angle);
    bgGradient = new fabric.Gradient({
      type: 'linear',
      coords: {
        x1: angleCoords.x1 * width,
        y1: angleCoords.y1 * height,
        x2: angleCoords.x2 * width,
        y2: angleCoords.y2 * height,
      },
      colorStops,
    });
  } else if (orientation === 'radial') {
    bgGradient = new fabric.Gradient({
      type: 'radial',
      coords: {
        x1: width / 2,
        y1: height / 2,
        r1: 0,
        x2: width / 2,
        y2: height / 2,
        r2: width / 2,
      },
      colorStops: colorStops,
    });
  }

  return bgGradient;
};

export const getRealBBox = async (obj) => {
  let tempCanv, ctx, w, h;

  // we need to use a temp canvas to get imagedata
  const getImageData = (dataUrl) => {
    if (tempCanv == null) {
      tempCanv = document.createElement('canvas');
      tempCanv.style.border = '1px solid blue';
      tempCanv.style.position = 'absolute';
      tempCanv.style.top = '-100%';
      tempCanv.style.visibility = 'hidden';
      ctx = tempCanv.getContext('2d');
      document.body.appendChild(tempCanv);
    }

    return new Promise(function (resolve, reject) {
      if (dataUrl == null) return reject();

      let image = new Image();
      image.addEventListener('load', () => {
        w = image.width;
        h = image.height;
        tempCanv.width = w;
        tempCanv.height = h;
        ctx.drawImage(image, 0, 0, w, h);
        let imageData = ctx.getImageData(0, 0, w, h).data.buffer;
        resolve(imageData, false);
      });
      image.src = dataUrl;
    });
  };

  // analyze pixels 1-by-1
  const scanPixels = (imageData) => {
    let data = new Uint32Array(imageData),
      x,
      y,
      y1,
      y2,
      x1 = w,
      x2 = 0;

    // y1
    for (y = 0; y < h; y++) {
      for (x = 0; x < w; x++) {
        if (data[y * w + x] & 0xff000000) {
          y1 = y;
          y = h;
          break;
        }
      }
    }

    // y2
    for (y = h - 1; y > y1; y--) {
      for (x = 0; x < w; x++) {
        if (data[y * w + x] & 0xff000000) {
          y2 = y;
          y = 0;
          break;
        }
      }
    }

    // x1
    for (y = y1; y < y2; y++) {
      for (x = 0; x < w; x++) {
        if (x < x1 && data[y * w + x] & 0xff000000) {
          x1 = x;
          break;
        }
      }
    }

    // x2
    for (y = y1; y < y2; y++) {
      for (x = w - 1; x > x1; x--) {
        if (x > x2 && data[y * w + x] & 0xff000000) {
          x2 = x;
          break;
        }
      }
    }

    return {
      x1: x1,
      x2: x2,
      y1: y1,
      y2: y2,
      width: x2 - x1,
      height: y2 - y1,
    };
  };

  let data = await getImageData(obj.toDataURL());

  return scanPixels(data);
};

/**
 * Align objects on canvas according to the pos
 * @param {Object} canvas fabric js canvas
 * @param {Array} activeObj the array of fabric js objects
 * @param {String} pos the position to align left/center-h/right/top/center-v/bottom
 */
export const alignObject = (canvas, activeObj, pos) => {
  const bound = activeObj.getBoundingRect();
  const zoom = canvas.getZoom();
  const boundL = bound.left / zoom;
  const boundW = bound.width / zoom;
  const boundT = bound.top / zoom;
  const boundH = bound.height / zoom;
  const activeL = activeObj.left;
  const activeT = activeObj.top;
  const canvasW = canvas.width / zoom;
  const canvasH = canvas.height / zoom;

  switch (pos) {
    case 'left':
      activeObj.set({
        left: activeL - boundL,
      });
      break;
    case 'right':
      activeObj.set({
        left: canvasW - boundW,
      });
      break;
    case 'top':
      activeObj.set({
        top: activeT - boundT,
      });
      break;
    case 'bottom':
      activeObj.set({
        top: canvasH - boundH,
      });
      break;
    case 'center-h':
      activeObj.set({
        left: canvasW / 2 - boundW / 2,
      });
      break;
    case 'center-v':
      activeObj.set({
        top: canvasH / 2 - boundH / 2,
      });
      break;
    case 'center':
      activeObj.set({
        left: canvasW / 2 - boundW / 2,
        top: canvasH / 2 - boundH / 2,
      });
      break;
    default:
      break;
  }
  activeObj.setCoords();
  canvas.renderAll();
  canvas.fire('object:modified');
};

export function changePosition(canvas, position) {
  const activeObject = canvas.getActiveObject();
  switch (position) {
    case 'backward':
      canvas.sendBackwards(activeObject);
      break;
    case 'forward':
      canvas.bringForward(activeObject);
      break;
    case 'to-back':
      canvas.sendToBack(activeObject);
      break;
    case 'to-front':
      canvas.bringToFront(activeObject);
      break;
    default:
      break;
  }
  canvas.renderAll();
  canvas.fire('object:modified');
}

export const getCenterTopPosition = (canvas, object) => {
  const bound = object.getBoundingRect();
  const zoom = canvas.getZoom();
  const boundH = bound.height / zoom;
  const canvasH = canvas.height / zoom;
  return canvasH / 2 - boundH / 2;
};

export const getCenterLeftPosition = (canvas, object) => {
  const bound = object.getBoundingRect();
  const zoom = canvas.getZoom();
  const boundW = bound.width / zoom;
  const canvasW = canvas.width / zoom;
  return canvasW / 2 - boundW / 2;
};

export function getEmptyJsonCanvasAndImage(
  width,
  height,
  backgroundColor = '#ffffff',
) {
  const canvasEl = document.createElement('canvas');
  const canvas = new fabric.Canvas(canvasEl, {
    width,
    height,
    backgroundColor,
  });
  return {
    canvasJson: canvas.toJSON(['fieldMetadata']),
    canvasPng: canvas.toDataURL({
      type: 'image/png',
      quality: 0.8,
    }),
  };
}

export const setObjectInitialPosition = (
  canvas,
  object,
  returnPosition = false,
) => {
  const bound = object.getBoundingRect();
  const zoom = canvas.getZoom();
  const boundW = bound.width / zoom;
  const boundH = bound.height / zoom;
  const windowW = window.innerWidth - 48 - 60;
  const windowH = window.innerHeight - 48 * 2 - 60 * 2;
  const canvasW = canvas.width / zoom;
  const canvasH = canvas.height / zoom;
  let targetW = windowW < canvas.width ? windowW : canvasW;
  let targetH = windowH < canvas.height ? windowH : canvasH;
  if (zoom > 1) {
    targetW = targetW / zoom;
    targetH = targetH / zoom;
  }
  if (returnPosition) {
    return {
      left: targetW / 2 - boundW / 2,
      top: targetH / 2 - boundH / 2,
    };
  } else {
    object.set({
      left: targetW / 2 - boundW / 2,
      top: targetH / 2 - boundH / 2,
    });
    object.setCoords();
    canvas.renderAll();
  }
};

export function updateObjectPosition(canvas, object, { left, top }) {
  object.set({ left, top });
  object.setCoords();
  canvas.renderAll();
}

/**
 * Get the filters of current image selection
 * @param {Object} activeSelection fabric js object
 */
export const getCurrentEffect = (activeSelection) => {
  let updatedEffects = {
    opacity: 100,
    blur: 0,
    brightness: 50,
    saturation: 50,
    gamma: {
      r: 45,
      g: 45,
      b: 45,
    },
  };

  updatedEffects.opacity = activeSelection.opacity * 100;

  let hasBlur = activeSelection.filters.find((x) => x.blur);
  if (hasBlur) {
    updatedEffects.blur = hasBlur.blur * 100;
  }

  let hasBrightness = activeSelection.filters.find((x) => x.brightness);
  if (hasBrightness) {
    updatedEffects.brightness = ((hasBrightness.brightness + 1) / 2) * 100;
  }

  let hasSaturation = activeSelection.filters.find((x) => x.saturation);
  if (hasSaturation) {
    updatedEffects.saturation = ((hasSaturation.saturation + 1) / 2) * 100;
  }

  let hasGamma = activeSelection.filters.find((x) => x.gamma);
  if (hasGamma) {
    updatedEffects.gamma.r = Math.round(hasGamma.gamma[0] / 0.022);
    updatedEffects.gamma.g = Math.round(hasGamma.gamma[1] / 0.022);
    updatedEffects.gamma.b = Math.round(hasGamma.gamma[2] / 0.022);
  }

  return updatedEffects;
};

export const getUpdatedFilter = (effects, effect, value) => {
  let updatedEffects = {
    ...effects,
  };
  switch (effect) {
    case 'gamma.r':
      updatedEffects.gamma.r = value;
      break;
    case 'gamma.g':
      updatedEffects.gamma.g = value;
      break;
    case 'gamma.b':
      updatedEffects.gamma.b = value;
      break;

    default:
      updatedEffects[effect] = value;
      break;
  }

  effects = updatedEffects;

  // rebuild filter array, calc values for fabric
  // blur 0-1 (def val 0), brightness, saturation -1-1 (def val: 0), gamma 0-2.2 (def val: 1)
  let updatedFilters = [];

  if (effects.blur > 0) {
    updatedFilters.push(
      new fabric.Image.filters.Blur({
        blur: effects.blur / 100,
      }),
    );
  }

  if (effects.brightness !== 50) {
    updatedFilters.push(
      new fabric.Image.filters.Brightness({
        brightness: (effects.brightness / 100) * 2 - 1,
      }),
    );
  }

  if (effects.saturation !== 50) {
    updatedFilters.push(
      new fabric.Image.filters.Saturation({
        saturation: (effects.saturation / 100) * 2 - 1,
      }),
    );
  }

  if (
    effects.gamma.r !== 45 ||
    effects.gamma.g !== 45 ||
    effects.gamma.b !== 45
  ) {
    updatedFilters.push(
      new fabric.Image.filters.Gamma({
        gamma: [
          Math.round(effects.gamma.r * 0.022 * 10) / 10,
          Math.round(effects.gamma.g * 0.022 * 10) / 10,
          Math.round(effects.gamma.b * 0.022 * 10) / 10,
        ],
      }),
    );
  }

  return updatedFilters;
};

export const getActiveFontStyle = (activeSelection, styleName) => {
  if (activeSelection.getSelectionStyles && activeSelection.isEditing) {
    let styles = activeSelection.getSelectionStyles();
    if (styles.find((o) => o[styleName] === '')) {
      return '';
    }

    return styles[0][styleName];
  }

  return activeSelection[styleName] || '';
};

export const setActiveFontStyle = (activeSelection, styleName, value) => {
  if (activeSelection.setSelectionStyles && activeSelection.isEditing) {
    let style = {};
    style[styleName] = value;
    activeSelection.setSelectionStyles(style);
    activeSelection.setCoords();
  } else {
    activeSelection.set(styleName, value);
  }
};

export const downloadImage = (
  data,
  extension = 'png',
  mimeType = 'image/png',
) => {
  const imageData = data
    .toString()
    .replace(/^data:image\/(png|jpeg|jpg);base64,/, '');
  const byteCharacters = atob(imageData);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i += 1) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  const file = new Blob([byteArray], {
    type: mimeType + ';base64',
  });
  const fileURL = window.URL.createObjectURL(file);

  // IE doesn't allow using a blob object directly as link href
  // instead it is necessary to use msSaveOrOpenBlob
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(file);
    return;
  }
  const link = document.createElement('a');
  link.href = fileURL;
  link.download = 'image.' + extension;
  link.dispatchEvent(new MouseEvent('click'));
  setTimeout(() => {
    // for Firefox it is necessary to delay revoking the ObjectURL
    window.URL.revokeObjectURL(fileURL);
  }, 60);
};

export const downloadSVG = (SVGmarkup) => {
  const url =
    'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(SVGmarkup);

  const link = document.createElement('a');
  link.href = url;
  link.download = 'image.svg';
  link.dispatchEvent(new MouseEvent('click'));
  setTimeout(() => {
    // for Firefox it is necessary to delay revoking the ObjectURL
    window.URL.revokeObjectURL(url);
  }, 60);
};

export function getOS() {
  const userAgent = window.navigator.userAgent;
  const platform =
    window.navigator?.userAgentData?.platform || window.navigator.platform;
  const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
  const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
  const iosPlatforms = ['iPhone', 'iPad', 'iPod'];
  let os = null;

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = 'macos';
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = 'ios';
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = 'windows';
  } else if (/Android/.test(userAgent)) {
    os = 'android';
  } else if (/Linux/.test(platform)) {
    os = 'linux';
  }

  return os;
}

/**
 *
 * @param {KeyboardEvent} keyEvent
 * @returns boolean
 */
export function isControlKey(keyEvent) {
  const os = getOS();
  if (os === 'macos' || os === 'ios') {
    // check if command key has been clicked
    return keyEvent.metaKey;
  } else {
    // check if control key has been clicked
    return keyEvent.ctrlKey;
  }
}

export function getControlChar() {
  const os = getOS();
  if (os === 'macos' || os === 'ios') {
    // check if command key has been clicked
    return '\u2318';
  } else {
    // check if control key has been clicked
    return 'ctrl';
  }
}
