import Label from 'components/forms/Common/Label';
import moment from 'moment/moment';
import { useEffect, useState } from 'react';
import {
  EuiDatePicker,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiSelect,
} from '@elastic/eui';

const buildGMTOptions = () => {
  const options = [];
  for (let i = 12; i >= 1; i--) {
    options.push({ value: -i, text: `GMT -${i < 10 ? '0' + i : i}:00` });
  }
  for (let i = 0; i <= 12; i++) {
    options.push({ value: i, text: `GMT ${i < 10 ? '0' + i : i}:00` });
  }

  return options;
};

const GMTOptions = buildGMTOptions();

function DateField({
  field,
  handleOnChange,
  minDate = moment(),
  maxDate = moment().add(30, 'days'),
  showTimeSelect = true,
  fullWidth = false,
  compressed = false,
  dateFormat = 'DD/MM/YYYY hh:mm A Z',
}) {
  const startDate = field.value ? moment(field.value) : moment();
  const [fieldDate, setFieldDate] = useState(startDate);
  /*const [utcOffset, setUtcOffset] = useState(startDate.utcOffset() / 60);

  const onUTCOffsetChange = (e) => {
    setUtcOffset(parseInt(e.target.value, 10));
  };*/
  const middleYear =
    minDate.year() + Math.floor((maxDate.year() - minDate.year()) / 2);

  const handleChangeInternal = (date) => {
    setFieldDate(date);
    handleOnChange(date);
  };

  useEffect(() => {
    setFieldDate(field.value ? moment(field.value) : null);
  }, [field.value]);

  /*useEffect(() => {
    const newDate = fieldDate.utcOffset(utcOffset);
    setFieldDate(newDate);
    handleOnChange(newDate.toDate());
  }, [utcOffset]);*/

  const DateFieldComponent = (
    <EuiFormRow
      label={<Label field={field} />}
      isInvalid={field.isInvalid}
      error={field.errors}
      isDisabled={field.disabled}
      fullWidth={fullWidth}
      display={compressed ? 'rowCompressed' : 'row'}
    >
      <EuiDatePicker
        fullWidth={fullWidth}
        selected={fieldDate}
        onChange={(date) => handleChangeInternal(date)}
        minDate={minDate}
        maxDate={maxDate}
        aria-label={field.label.toString()}
        placeholder={field.label.toString()}
        showTimeSelect={showTimeSelect}
        dateFormat={dateFormat}
        yearDropdownItemNumber={middleYear}
      />
    </EuiFormRow>
  );

  return DateFieldComponent;
  /*if (!showUtfOffset) {
    return DateFieldComponent;
  }

  return (
    <EuiFlexGroup alignItems={'flexStart'}>
      <EuiFlexItem>{DateFieldComponent}</EuiFlexItem>
      <EuiFlexItem grow={false}>
        <EuiFormRow
          label={<Label field={{ label: 'Time zones', is_required: false }} />}
        >
          <EuiSelect
            options={GMTOptions}
            value={utcOffset}
            onChange={onUTCOffsetChange}
          />
        </EuiFormRow>
      </EuiFlexItem>
    </EuiFlexGroup>
  );*/
}

export default DateField;
