/** @jsxImportSource @emotion/react */
import {
  EuiButtonIcon,
  EuiComboBox,
  EuiHeaderSectionItem,
  EuiPopover,
  EuiToolTip,
  useGeneratedHtmlId,
} from '@elastic/eui';
import useCanvas from 'lib/providers/canvasProvider';
import React, { useEffect, useMemo, useState } from 'react';
import { css } from '@emotion/react';
import { isCustomFont } from 'lib/fonts';
import FontForm from 'components/Fonts/FontForm';
import { getFontFamilyFromFont } from 'lib/hooks/api/fonts';

function fontFamilyToFontOption(family) {
  return {
    code: family,
    label: family,
  };
}

function FontFamilySelect({
  objects,
  marginRight = 4,
  label = 'Set font family',
}) {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const fontFamilyId = useGeneratedHtmlId({ prefix: 'fontFamily' });
  const {
    editor,
    activeObjectUpdated,
    templateFonts,
    setTemplateFonts,
    availableFonts,
    templateMetadata,
    setTemplateMetadata,
  } = useCanvas();
  const [fontFamily, setFontFamily] = useState([
    fontFamilyToFontOption(editor.getActiveStyle('fontFamily', objects[0])),
  ]);
  const fontLoadPopoverId = useGeneratedHtmlId({ prefix: 'font-load-popover' });

  useEffect(() => {
    setFontFamily([
      fontFamilyToFontOption(editor.getActiveStyle('fontFamily', objects[0])),
    ]);
  }, [objects, activeObjectUpdated]);

  const fontOptions = useMemo(() => {
    const defaultFonts = {
      label: 'Default Fonts',
      options: availableFonts.defaultFonts.map((font) => ({
        code: font,
        label: font,
        css: css`
          font-family: ${font};
        `,
        id: null,
      })),
    };
    if (availableFonts.userFonts.length > 0) {
      const userFonts = {
        label: 'User Fonts',
        options: availableFonts.userFonts.map((font) => {
          const family = getFontFamilyFromFont(font);
          return {
            code: family,
            label: family,
            css: css`
              font-family: ${family};
            `,
            id: font._id,
          };
        }),
      };
      return [userFonts, defaultFonts];
    } else {
      return defaultFonts.options;
    }
  }, [availableFonts]);

  const handleFontFamilyChange = async (fonts) => {
    if (fonts.length > 0) {
      const { code: newFont, id: newFontId } = fonts[0];
      setFontFamily(fonts);
      objects.forEach((object) => {
        editor.setActiveStyle('fontFamily', newFont, object);
      });
      setTemplateFonts([...templateFonts, newFont]);
      if (isCustomFont(newFont)) {
        let newCustomFonts;
        if (templateMetadata.customFonts) {
          const fontAdded = templateMetadata.customFonts.filter(
            (fontId) => fontId === newFontId,
          );
          if (fontAdded.length === 0) {
            newCustomFonts = [...templateMetadata.customFonts, newFontId];
          } else {
            newCustomFonts = templateMetadata.customFonts;
          }
        } else {
          newCustomFonts = [newFontId];
        }
        setTemplateMetadata({
          ...templateMetadata,
          customFonts: newCustomFonts,
        });
      }
    }
  };

  const handleCloseFontLoadPopover = () => {
    setIsPopoverOpen(false);
  };

  const toggleFontLoadPopover = () => {
    setIsPopoverOpen(!isPopoverOpen);
  };

  const handleEditFontIdChange = (font) => {};

  const handleFontAddedCallback = (newFont) => {
    handleCloseFontLoadPopover();
  };

  return (
    <EuiHeaderSectionItem
      css={css`
        margin-right: ${marginRight}px;
      `}
    >
      <EuiComboBox
        css={css`
          width: 220px !important;
        `}
        id={fontFamilyId}
        compressed
        options={fontOptions}
        selectedOptions={fontFamily}
        onChange={handleFontFamilyChange}
        aria-label="Font family"
        name={'Font family'}
        singleSelection={{ asPlainText: true }}
        isClearable={false}
        append={
          <EuiPopover
            id={fontLoadPopoverId}
            repositionOnScroll
            button={
              <EuiToolTip content="Load custom font">
                <EuiButtonIcon
                  css={css`
                    height: 30px !important;
                    line-height: 30px !important;
                  `}
                  iconType={'plus'}
                  aria-label={'load custom font'}
                  onClick={toggleFontLoadPopover}
                />
              </EuiToolTip>
            }
            isOpen={isPopoverOpen}
            anchorPosition={'downLeft'}
            closePopover={handleCloseFontLoadPopover}
            panelPaddingSize="m"
          >
            <div
              css={css`
                width: 300px;
              `}
            >
              <FontForm
                fontId={null}
                isEdit={null}
                direction={'column'}
                handleEditFontIdChange={handleEditFontIdChange}
                afterSubmitCallback={handleFontAddedCallback}
              />
            </div>
          </EuiPopover>
        }
      />
    </EuiHeaderSectionItem>
  );
}

export default FontFamilySelect;
