import React from 'react';
import { EuiTabbedContent } from '@elastic/eui';

function PlansView({ tabs, selectedTab, handleTabChange }) {
  return (
    <EuiTabbedContent
      tabs={tabs}
      selectedTab={selectedTab}
      onTabClick={handleTabChange}
    />
  );
}

export default PlansView;
