import { EuiErrorBoundary, EuiLink, EuiText } from '@elastic/eui';
import { logError } from 'lib/ErrorService/errorLogger';
import useGlobalProvider from 'lib/providers/globalProvider';
import useOptionsProvider from 'lib/providers/optionsProvider';
import React from 'react';

function ErrorBoundary({ children }) {
  const { setFatalError } = useGlobalProvider();
  const { appInfoOptions } = useOptionsProvider();

  const handleError = (err) => {
    logError(err);
    setFatalError({
      title: 'An error occurred',
      body: (
        <EuiText>
          Try to reload the page, if the error persists try to contact the
          support at{' '}
          <EuiLink
            color="danger"
            href={`mailto:${appInfoOptions.support_email}`}
          >
            {appInfoOptions.support_email}
          </EuiLink>
        </EuiText>
      ),
    });
  };

  return (
    <EuiErrorBoundary onError={(err) => handleError(err)}>
      {children}
    </EuiErrorBoundary>
  );
}

export default ErrorBoundary;
