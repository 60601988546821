import { EuiButton, EuiI18n } from '@elastic/eui';
import PageBanner from 'components/PageBanner';
import PlansHistoryTable from 'components/table/pageTables/PlansHistory.table';
import { PageError } from 'lib/ErrorService/PageError';
import { usePlanUsages } from 'lib/hooks/api/planUsages';
import { PLAN_TABS_IDS } from 'pages/Plans/index';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

function PlansHistory({}) {
  const [error, setError] = useState(null);
  const [isEmpty, setIsEmpty] = useState(false);
  const navigate = useNavigate();

  const { planUsages, isLoading } = usePlanUsages(
    { page: 0, pageSize: 1 },
    false,
    false,
    {},
  );

  useEffect(() => {
    if (!isLoading && planUsages?.meta?.totalItems === 0) {
      setIsEmpty(true);
    } else {
      setIsEmpty(false);
    }
  }, [planUsages, isLoading]);

  if (error) {
    return <PageError pageName={'plans'} />;
  }

  return (
    <>
      {isEmpty ? (
        <PageBanner
          title={
            <EuiI18n
              token={'No plans activated so far'}
              default={'No plans activated so far'}
            />
          }
          body={
            <EuiI18n token={'Activate a plan'} default={'Activate a plan'} />
          }
          iconType={'payment'}
          color={'primary'}
          actions={[
            <EuiButton
              fill
              onClick={() =>
                navigate('/plans', { state: { tab: PLAN_TABS_IDS.New } })
              }
            >
              <EuiI18n token="Activate a plan" default="Activate a plan" />
            </EuiButton>,
          ]}
        />
      ) : (
        <PlansHistoryTable setError={setError} setIsEmpty={setIsEmpty} />
      )}
    </>
  );
}

export default PlansHistory;
