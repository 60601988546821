import { invalidateCacheEntry, QUERY_KEY_MAPPING } from 'lib/hooks/api/common';

export async function downloadLabels(
  apiClient,
  labelsCode,
  setMessage,
  queryClient,
) {
  try {
    const { data } = await apiClient.labelsUsersControllerDownloadLabels(
      labelsCode,
      {
        responseType: 'arraybuffer',
      },
    );
    const url = window.URL.createObjectURL(new Blob([data]), {
      type: 'application/zip',
    });
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `labels.zip`);

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link.parentNode.removeChild(link);
    // invalidate stats cache
    invalidateCacheEntry(queryClient, QUERY_KEY_MAPPING.stats, 'list');
  } catch (err) {
    if (err.response) {
      const enc = new TextDecoder('utf-8');
      const errDecoded = JSON.parse(enc.decode(err.response.data));
      setMessage({
        title: 'An error occurred',
        text: `${errDecoded.message}`,
        color: 'danger',
        iconType: 'alert',
      });
    } else {
      console.log(err);
      setMessage({
        title: 'An error occurred',
        text: `An error occurred while downloading labels`,
        color: 'danger',
        iconType: 'alert',
      });
    }
  }
}
