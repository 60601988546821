import LangSwitcherFlag from 'components/LangSwitcherFlags';
import React from 'react';
import {
  EuiHeader,
  EuiHeaderSectionItem,
  EuiHeaderLogo,
  EuiHeaderSection,
} from '@elastic/eui';
import useAuth from 'lib/providers/authProvider';
import logo from 'assets/logo.svg';
import NotificationMenu from 'layout/PageHeader/NotificationMenu';
import ThemeSwitcher from 'components/ThemeSwitcher';
import UserMenu from 'layout/PageHeader/UserMenu';
import PagesMenu from 'layout/PageHeader/PagesMenu';
import { useNavigate } from 'react-router-dom';
import { getLogoPage } from 'lib/data/pages';

function PageHeader() {
  const { user, logout } = useAuth();
  const navigate = useNavigate();

  const logoPage = getLogoPage();

  return (
    <EuiHeader position="fixed" theme="dark">
      <EuiHeaderSection grow={false}>
        <EuiHeaderSectionItem border="right">
          <EuiHeaderLogo
            iconType={logo}
            iconTitle="Hello Label"
            onClick={() => navigate(logoPage.url)}
          >
            Hello Label
          </EuiHeaderLogo>
        </EuiHeaderSectionItem>
        <PagesMenu />
      </EuiHeaderSection>
      <EuiHeaderSection side="right">
        <EuiHeaderSectionItem>
          <NotificationMenu />
        </EuiHeaderSectionItem>
        <EuiHeaderSectionItem>
          <ThemeSwitcher compressed={true} />
        </EuiHeaderSectionItem>
        {/*<EuiHeaderSectionItem>
          <LangSwitcherFlag />
        </EuiHeaderSectionItem>*/}
        <EuiHeaderSectionItem>
          <UserMenu user={user} logout={logout} />
        </EuiHeaderSectionItem>
      </EuiHeaderSection>
    </EuiHeader>
  );
}

export default PageHeader;
