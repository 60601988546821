/** @jsxImportSource @emotion/react */
import useCanvas from 'lib/providers/canvasProvider';
import React from 'react';
import { css } from '@emotion/react';
import useAppTheme from 'lib/providers/appProvider';
import {
  EuiFlexGroup,
  EuiFlexItem,
  useEuiTheme,
  useEuiShadow,
  shade,
  tint,
  EuiHorizontalRule,
  EuiConfirmModal,
} from '@elastic/eui';
import { useEditorActions } from 'components/Creator/CreatorCanvas/editorActions';
import SidebarItem, {
  SidebarItemMobileSimple,
} from 'components/Creator/CreatorCanvas/CanvasPanel/SidebarItem';
import ActivePanelWrapper from 'components/Creator/CreatorCanvas/CanvasPanel/ActivePanelWrapper';
import ZoomSelector from 'components/Creator/CreatorCanvas/CanvasBottomToolbar/ZoomSelector';
import SidebarPopover from 'components/Creator/CreatorCanvas/CanvasPanel/SidebarPopover';
import { hideScrollBar } from 'lib/cssUtils';

export const panelWidthExpanded = 400;
export const panelWidthClosed = 48;

export const expandedBackgroundColor = (colorMode, euiTheme) =>
  colorMode === 'dark'
    ? tint(euiTheme.colors.lightestShade, 0.03)
    : tint(euiTheme.colors.darkestShade, 0.05);

export const closedBackgroundColor = (colorMode, euiTheme) =>
  colorMode === 'dark'
    ? shade(euiTheme.colors.lightestShade, 0.5)
    : shade(euiTheme.colors.darkestShade, 0.28);

export const border = (colorMode, euiTheme) =>
  shade(euiTheme.colors.emptyShade, 0.5);

function CanvasPanel({ setCreatorMode }) {
  const { euiTheme } = useEuiTheme();
  const { theme } = useAppTheme();

  const darkShadow = useEuiShadow('xl');

  const {
    templateMetadata,
    editor,
    selectedObjects,
    fieldsDisplayed,
    setCurrentZoom,
    setTemplateMetadata,
    templateData,
    currentZoom,
    isMobile,
    isCanvasPanelExpanded,
    setIsCanvasPanelExpanded,
    activePanelAction,
    setActivePanelAction,
  } = useCanvas();

  const {
    editorActions,
    mainHeaderLeftActions,
    mainHeaderRightActions,
    confirmModal,
    setConfirmModal,
    zoomPanelAction,
    handleDiscardConfirm,
    handleSaveConfirm,
  } = useEditorActions(
    editor,
    selectedObjects,
    templateMetadata,
    fieldsDisplayed,
    setCurrentZoom,
    setTemplateMetadata,
    setCreatorMode,
    templateData,
    currentZoom,
  );

  const actionClickWrapper = (actionItem) => {
    if (activePanelAction) {
      // close expanded panel
      if (activePanelAction.code === actionItem.code) {
        setActivePanelAction(null);
        setIsCanvasPanelExpanded(false);
      } else {
        setActivePanelAction(actionItem);
      }
    } else {
      setActivePanelAction(actionItem);
      setIsCanvasPanelExpanded(true);
    }
  };

  const closePanel = () => {
    setIsCanvasPanelExpanded(false);
    setActivePanelAction(null);
  };

  return (
    <div
      css={css`
        ${theme === 'dark'
          ? darkShadow
          : css`
              box-shadow:
                0 2.7px 9px rgba(0, 0, 0, 0.49),
                0 9.4px 24px rgba(0, 0, 0, 0.36),
                0 21.8px 43px rgba(0, 0, 0, 0.08);
            `}
        background-color: ${expandedBackgroundColor(theme, euiTheme)};
        border-color: ${border(theme, euiTheme)};
        width: ${isCanvasPanelExpanded
          ? `${panelWidthExpanded}px`
          : `${panelWidthClosed}px`};
        height: calc(100vh - 48px);
        position: fixed;
        top: 48px;
        left: 0;
        z-index: ${euiTheme.levels.menu};
      `}
    >
      <EuiFlexGroup
        direction={'column'}
        alignItems={'center'}
        gutterSize={'none'}
        css={css`
          background-color: ${closedBackgroundColor(theme, euiTheme)};
          border-color: ${theme === 'dark'
            ? border(theme, euiTheme)
            : expandedBackgroundColor(theme, euiTheme)};
          height: 100%;
          width: ${panelWidthClosed}px;
          position: absolute;
          top: 0;
          left: 0;
          z-index: ${euiTheme.levels.menu};
          padding-top: ${24}px;
          padding-bottom: ${24 * 3}px;
          overflow-y: auto;
          ${hideScrollBar()}
        `}
      >
        {isMobile ? (
          <>
            {mainHeaderLeftActions.map((actionItem, index) => (
              <EuiFlexItem key={index} grow={false}>
                <SidebarItemMobileSimple actionItem={actionItem} />{' '}
              </EuiFlexItem>
            ))}

            <EuiHorizontalRule size={'half'} margin={'s'} />
          </>
        ) : null}

        {editorActions.map((actionItem, index) => (
          <EuiFlexItem key={index} grow={false}>
            <SidebarItem
              actionItem={actionItem}
              activeAction={activePanelAction}
              actionClickWrapper={actionClickWrapper}
            />{' '}
          </EuiFlexItem>
        ))}

        {isMobile ? (
          <>
            <EuiHorizontalRule size={'half'} margin={'s'} />
            {mainHeaderRightActions.map((actionItem, index) => (
              <EuiFlexItem key={index} grow={false}>
                <SidebarItemMobileSimple actionItem={actionItem} />{' '}
              </EuiFlexItem>
            ))}
          </>
        ) : null}
        {isMobile ? (
          <>
            <EuiHorizontalRule size={'half'} margin={'s'} />
            <EuiFlexItem grow={false}>
              <SidebarPopover
                actionItem={zoomPanelAction}
                componentProps={{ editor, currentZoom }}
                Component={ZoomSelector}
                isMobile={isMobile}
              />
              {/*<SidebarItemMobileSimple actionItem={zoomPanelAction} />{' '}*/}
            </EuiFlexItem>
          </>
        ) : null}
      </EuiFlexGroup>
      {activePanelAction ? (
        <ActivePanelWrapper
          activeAction={activePanelAction}
          closePanel={closePanel}
        />
      ) : null}
      {isMobile && confirmModal ? (
        <EuiConfirmModal
          title={confirmModal.title}
          onCancel={() => setConfirmModal(null)}
          onConfirm={
            confirmModal.buttonColor === 'danger'
              ? handleDiscardConfirm
              : handleSaveConfirm
          }
          cancelButtonText="No, don't do it"
          confirmButtonText="Yes, do it"
          buttonColor={confirmModal.buttonColor}
          defaultFocusedButton={confirmModal.defaultFocusedButton}
        >
          <p>{confirmModal.text}</p>
        </EuiConfirmModal>
      ) : null}
    </div>
  );
}

export default CanvasPanel;
