import ActionPopover, {
  TextActionButton,
} from 'components/Creator/CreatorCanvas/CanvasHeader/UtilityHeaderComponents/ActionsComponents/ActionPopover';
import AlignActions from 'components/Creator/CreatorCanvas/CanvasHeader/UtilityHeaderComponents/DefaultActions/AlignActions';
import PositionActions from 'components/Creator/CreatorCanvas/CanvasHeader/UtilityHeaderComponents/DefaultActions/PositionActions';
import FlipActions from 'components/Creator/CreatorCanvas/CanvasHeader/UtilityHeaderComponents/DefaultActions/FlipActions';
import React from 'react';
import { EuiForm, EuiFormRow } from '@elastic/eui';

function PositionPanelActions() {
  return (
    <ActionPopover
      width={300}
      buttonLabel={<TextActionButton label={'Position'} />}
      label={'Set position'}
      content={
        <EuiForm component={'div'}>
          <EuiFormRow label={'Align'} display={'columnCompressed'}>
            <AlignActions />
          </EuiFormRow>
          <EuiFormRow label={'Position'} display={'columnCompressed'}>
            <PositionActions />
          </EuiFormRow>
          <EuiFormRow label={'Flip'} display={'columnCompressed'}>
            <FlipActions />
          </EuiFormRow>
        </EuiForm>
      }
    />
  );
}

export default PositionPanelActions;
