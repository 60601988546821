/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { EuiIcon, shade, tint, useEuiTheme } from '@elastic/eui';
import CanvasTooltip from 'components/Creator/CanvasTooltip';
import useAppTheme from 'lib/providers/appProvider';

function ActionIconGroup({
  actions,
  onActionClick,
  marginLeft = 0,
  keepActive = null,
}) {
  const { euiTheme } = useEuiTheme();
  return (
    <div
      css={css`
        border-radius: ${euiTheme.border.radius.medium};
        display: flex;
        overflow: hidden;
        margin-left: ${marginLeft}px;
      `}
    >
      {actions.map((action, index) => (
        <Action
          key={index}
          action={action}
          onClick={onActionClick}
          isLast={index + 1 === actions.length}
          isActive={action.value === keepActive}
        />
      ))}
    </div>
  );
}

function Action({ action, onClick, isLast, isActive }) {
  const { euiTheme } = useEuiTheme();
  const { theme } = useAppTheme();

  const borderRight = isLast
    ? 'none'
    : `1px solid ${shade(euiTheme.colors.lightestShade, 0.1)}`;

  const colorAndBackground = isActive
    ? css`
        background-color: ${tint(euiTheme.colors.darkestShade, 0.2)};
        color: ${theme === 'dark' ? '#000' : '#fff'};
      `
    : css`
        background-color: ${shade(euiTheme.colors.lightestShade, 0.05)};
        color: ${euiTheme.colors.text};
      `;

  return (
    <CanvasTooltip position={'bottom'} label={action.label}>
      <span
        css={css`
          height: 32px;
          line-height: 32px;
          padding: 0 8px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          ${colorAndBackground};
          border-right: ${borderRight};

          &:hover {
            background-color: ${tint(euiTheme.colors.darkestShade, 0.2)};
            color: ${theme === 'dark' ? '#000' : '#fff'};
          }
        `}
        aria-label={action.label}
        onClick={() => onClick(action.value)}
      >
        <EuiIcon type={action.icon} />
      </span>
    </CanvasTooltip>
  );
}

export default ActionIconGroup;
