import useGlobalProvider from 'lib/providers/globalProvider';
import React, { createContext, useState, useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { getPage } from 'lib/data/pages';
import useAuth from 'lib/providers/authProvider';
import PageHeader from 'layout/PageHeader';

export const navigationInitialState = {
  currentPage: 'dashboard',
  currentPageInfo: getPage('dashboard'),
  pageHeaderActions: [],
  mainNavbar: <PageHeader />,
};

const NavigationContext = createContext({
  currentPage: navigationInitialState.currentPage,
  currentPageInfo: navigationInitialState.currentPageInfo,
  pageHeaderActions: navigationInitialState.pageHeaderActions,
  mainNavbar: navigationInitialState.mainNavbar,
});

export const NavigationProvider = ({ children }) => {
  const { isTokenExpired, logout } = useAuth();
  const { setFatalError } = useGlobalProvider();
  const [mainHeader, setMainHeader] = useState(
    navigationInitialState.mainNavbar,
  );
  const [pageHeaderActions, setPageHeaderActions] = useState(
    navigationInitialState.pageHeaderActions,
  );
  const [currentPage, setCurrentPage] = useState(
    navigationInitialState.currentPage,
  );
  const [currentPageInfo, setCurrentPageInfo] = useState(
    navigationInitialState.currentPageInfo,
  );
  const [fullPageLayout, setFullPageLayout] = useState(false);
  let location = useLocation();

  useEffect(() => {
    if (location.pathname === '/') {
      setCurrentPage('dashboard');
      window.scrollTo(0, 0);
    } else {
      if (location.pathname.indexOf('/edit') !== -1) {
        setCurrentPage('templates/new');
      } else {
        if (
          location.pathname.indexOf('payments') !== -1 &&
          location.pathname.indexOf('receipt') !== -1
        ) {
          setCurrentPage('payment-receipt');
        } else {
          setCurrentPage(location.pathname.replace('/', ''));
        }
      }
      window.scrollTo(0, 0);
    }
    setFatalError(false);
  }, [location]);

  useEffect(() => {
    setCurrentPageInfo(getPage(currentPage));
    if (currentPage !== 'templates/new') {
      setFullPageLayout(false);
    }
    if (isTokenExpired()) {
      logout();
    }
  }, [currentPage]);

  return (
    <NavigationContext.Provider
      value={{
        mainHeader,
        setMainHeader,
        currentPage,
        currentPageInfo,
        setCurrentPage,
        fullPageLayout,
        pageHeaderActions,
        setPageHeaderActions,
        setFullPageLayout,
      }}
    >
      {children}
    </NavigationContext.Provider>
  );
};

export default function useNavigation() {
  return useContext(NavigationContext);
}
