import { setUpValidator } from 'lib/validator';
import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import axios from 'axios';
import { EuiContext } from '@elastic/eui';
import it from 'flag-icons/flags/4x3/it.svg';
import gb from 'flag-icons/flags/4x3/gb.svg';
import Validator from 'validatorjs';

export const locale_flags_mapping = {
  it,
  en: gb,
};

export const supported_languages = [
  {
    code: 'en',
    label: 'English',
  },
  {
    code: 'it',
    label: 'Italian',
  },
];

export function getLabelFromCode(code) {
  return supported_languages.filter((lang) => lang.code === code)[0].label;
}

const initialState = {
  locale: 'en',
};

const LocaleContext = createContext({
  locale: initialState.locale,
});

setUpValidator(initialState.locale);

export const LocaleProvider = ({ children }) => {
  const [locale, setLocale] = useState(initialState.locale);
  const [localeData, setLocaleData] = useState(null);
  Validator.useLang(locale);
  const i18n = useMemo(
    () => ({
      mapping: localeData,
      formatNumber: (value) =>
        new Intl.NumberFormat(initialState.locale).format(value),
      render: undefined,
    }),
    [localeData],
  );

  useEffect(() => {
    const savedLangLocal = localStorage.getItem('globalLang');
    if (!!savedLangLocal) {
      setCurrentLocale(savedLangLocal);
    }
  }, []);

  useEffect(() => {
    loadLocale();
  }, [locale]);

  const loadLocale = async () => {
    try {
      const { data } = await axios.get(`/i18n/${locale}.json`);
      setLocaleData(data);
    } catch (err) {
      console.log(err);
    }
  };

  const setCurrentLocale = (locale) => {
    localStorage.setItem('globalLang', locale);
    setLocale(locale);
  };

  if (i18n) {
    return (
      <LocaleContext.Provider
        value={{
          locale,
          setLocale: setCurrentLocale,
        }}
      >
        <EuiContext i18n={i18n}>{children}</EuiContext>
      </LocaleContext.Provider>
    );
  }

  return (
    <LocaleContext.Provider
      value={{
        locale,
        setLocale,
      }}
    >
      {children}
    </LocaleContext.Provider>
  );
};

export default function useLocale() {
  return useContext(LocaleContext);
}
