import {
  EuiButtonEmpty,
  EuiFieldNumber,
  EuiFlexGroup,
  EuiFlexItem,
  EuiForm,
  EuiFormRow,
  EuiI18n,
  EuiPopover,
  EuiSpacer,
  EuiText,
  useEuiI18n,
} from '@elastic/eui';
import CurrencyRender from 'components/CurrencyRender';
import PlanCard from 'components/Plans/PlanCard';
import eCommercePlanIcon from 'assets/e-commerce-plan-icon.png';
import { SummaryRow } from 'components/Plans/PlanSummaryViewer';
import {
  getECommercePlanPricePerLabel,
  CUSTOM_ECOMMERCE_PLAN_CODE,
} from 'lib/api/plans';
import { useEffect, useState } from 'react';

function CustomECommercePlanCard({
  selected,
  setSelected,
  removeUnselected,
  initialLabels = 0,
}) {
  const [nLabels, setNLabels] = useState(initialLabels);

  const price = !isNaN(nLabels)
    ? getECommercePlanPricePerLabel(nLabels) * nLabels
    : 0;

  useEffect(() => {
    if (selected) {
      if (selected.code === CUSTOM_ECOMMERCE_PLAN_CODE) {
        setSelected((prev) => ({
          ...prev,
          price,
          n_labels: nLabels,
        }));
      }
    }
  }, [nLabels]);

  return (
    <PlanCard
      planData={{
        code: CUSTOM_ECOMMERCE_PLAN_CODE,
        name: 'Custom Plan',
        description:
          'If any of the above plans does not suite your requirement customize your plan',
        icon: eCommercePlanIcon,
        price,
        n_labels: nLabels,
      }}
      removeUnselected={removeUnselected}
      selected={selected}
      setSelected={setSelected}
      footer={<CustomPlanFooter nLabels={nLabels} setNLabels={setNLabels} />}
    />
  );
}

function CustomPlanFooter({ nLabels, setNLabels }) {
  const customizePlanLabel = useEuiI18n('Customize plan', 'Customize plan');

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const onButtonClick = () =>
    setIsPopoverOpen((isPopoverOpen) => !isPopoverOpen);
  const closePopover = () => setIsPopoverOpen(false);

  const popoverButton = (
    <EuiButtonEmpty
      iconType="iInCircle"
      size="xs"
      onClick={onButtonClick}
      aria-label={customizePlanLabel}
    >
      {customizePlanLabel}
    </EuiButtonEmpty>
  );

  return (
    <EuiPopover
      button={popoverButton}
      isOpen={isPopoverOpen}
      closePopover={closePopover}
      anchorPosition={'rightCenter'}
    >
      <CustomizerForm nLabels={nLabels} setNLabels={setNLabels} />
    </EuiPopover>
  );
}

function CustomizerForm({ nLabels, setNLabels }) {
  const handleLabelsChange = (e) => {
    let value;
    try {
      value = parseInt(e.target.value);
    } catch (e) {
      value = 0;
    }
    setNLabels(value);
  };

  const price = !isNaN(nLabels)
    ? getECommercePlanPricePerLabel(nLabels) * nLabels
    : 0;

  return (
    <div>
      <EuiText color="subdued" size="s" textAlign="left">
        <h4>Plan Details:</h4>
      </EuiText>
      <EuiSpacer size="s" />
      <EuiForm component="form" onSubmit={(e) => e.preventDefault()}>
        <EuiFlexGroup responsive={false} alignItems={'center'}>
          <EuiFlexItem>
            <EuiText color="subdued" size="s" textAlign="left">
              <strong>
                # <EuiI18n token={'Labels'} default={'Labels'} />
              </strong>
            </EuiText>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiFieldNumber
              style={{ width: 120 }}
              compressed
              value={isNaN(nLabels) ? '' : nLabels}
              fullWidth
              onChange={handleLabelsChange}
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiForm>
      <EuiText color="subdued" size="s" textAlign="left">
        <SummaryRow size="xs" name={'Templates'} value={-1} prefix={'#'} />
        <SummaryRow size="xs" name={'Validity'} value={-1} />
        <SummaryRow
          size="xs"
          name={'Price'}
          value={<CurrencyRender value={price} size={'xs'} />}
        />
      </EuiText>
    </div>
  );
}

export default CustomECommercePlanCard;
