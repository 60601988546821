/** @jsxImportSource @emotion/react */
import { EuiRange, useGeneratedHtmlId } from '@elastic/eui';

function ZoomSelector({ editor, currentZoom }) {
  const zoomSelectorId = useGeneratedHtmlId();

  const onChange = (e) => {
    try {
      const zoomValue = e.currentTarget.value / 100;
      editor.setZoom(zoomValue);
    } catch (e) {
      editor.setZoom(currentZoom);
    }
  };

  return (
    <div>
      <EuiRange
        id={zoomSelectorId}
        min={5}
        step={5}
        max={800}
        value={Math.round(currentZoom * 100)}
        onChange={(e) => onChange(e)}
        showInput
        compressed
        isInvalid={false}
        prepend={'Zoom'}
        aria-label="Manually set the level of zoom"
      />
    </div>
  );
}

export default ZoomSelector;
