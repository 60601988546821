/** @jsxImportSource @emotion/react */
import {
  EuiButton,
  EuiI18n,
  EuiText,
  useEuiI18n,
  useEuiTheme,
} from '@elastic/eui';
import { css } from '@emotion/react';
import { LabelStatusEnum } from '@hello-label/api-client';
import { labelsStatusMapper } from 'components/table/pageTables/Labels.table';
import { DATE_FORMAT } from 'components/table/specialFields';
import useAuth from 'lib/providers/authProvider';
import useGlobalProvider from 'lib/providers/globalProvider';
import { capitalize } from 'lib/utils';
import moment from 'moment/moment';
import { PLAN_TABS_IDS } from 'pages/Plans';
import { useNavigate } from 'react-router-dom';

function PlanSummaryViewer({ size = 'xs', showExpirationDate = false }) {
  const { userPlanUsageSummary } = useAuth();
  const { loading } = useGlobalProvider();
  const daysLabel = useEuiI18n('days', 'days');
  const dayLabel = useEuiI18n('day', 'day');
  const navigate = useNavigate();

  if (!userPlanUsageSummary?.plan && !loading) {
    return (
      <EuiText size={size}>
        <SummaryRow
          name={'Plan'}
          value={
            userPlanUsageSummary?.plan
              ? capitalize(userPlanUsageSummary?.plan)
              : 'none'
          }
          size={size}
        />
        <EuiButton
          size={size}
          color={'warning'}
          onClick={() =>
            navigate('/plans', { state: { tab: PLAN_TABS_IDS.New } })
          }
        >
          <EuiI18n token={'Upgrade plan'} default={'Upgrade plan'} />
        </EuiButton>
      </EuiText>
    );
  }

  return (
    <EuiText size={size}>
      <SummaryRow
        name={'Plan'}
        value={
          userPlanUsageSummary?.plan
            ? capitalize(userPlanUsageSummary?.plan.name)
            : 'none'
        }
        size={size}
      />
      <SummaryRow
        name={'Labels'}
        value={userPlanUsageSummary?.remaining_labels}
        prefix={'#'}
        size={size}
      />
      <SummaryRow
        name={'Templates'}
        value={userPlanUsageSummary?.remaining_templates}
        prefix={'#'}
        size={size}
      />
      <SummaryRow
        name={'Expires in'}
        value={userPlanUsageSummary?.expires_in}
        suffix={
          userPlanUsageSummary?.expires_in > 1
            ? daysLabel
            : userPlanUsageSummary?.expires_in === 1
            ? dayLabel
            : ''
        }
        size={size}
      />
      {showExpirationDate && userPlanUsageSummary?.expires_in !== -1 ? (
        <SummaryRow
          name={'Expiration date'}
          value={moment()
            .add(userPlanUsageSummary?.expires_in, 'days')
            .format(DATE_FORMAT)}
          suffix={null}
          size={size}
        />
      ) : null}
    </EuiText>
  );
}

export const SummaryRow = ({ name, value, prefix, suffix, size }) => {
  const { euiTheme } = useEuiTheme();
  return (
    <p
      css={css`
        margin-block-end: ${euiTheme.size[size]} !important;
      `}
    >
      <PlanEntryLabelValue
        name={name}
        value={value}
        prefix={prefix}
        suffix={suffix}
      />
    </p>
  );
};

export const PlanEntryLabelValue = ({ name, value, prefix, suffix }) => {
  return (
    <>
      <strong>
        {prefix ? `${prefix} ` : ''}
        <EuiI18n token={name} default={name} />:
      </strong>{' '}
      {renderPlanUsageValue(value)}
      {suffix ? ` ${suffix}` : ''}
    </>
  );
};

export const renderPlanUsageValue = (value, status) => {
  if (status === undefined || status === LabelStatusEnum.Completed) {
    if (value) {
      if (value === -1) {
        return <EuiI18n token={'unlimited'} default={'unlimited'} />;
      } else {
        return value;
      }
    } else {
      return 0;
    }
  } else {
    return labelsStatusMapper[status];
  }
};

export default PlanSummaryViewer;
