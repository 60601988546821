export function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function roundToDecimal(value, precision = 2) {
  let multiplier = 1;
  for (let i = 0; i < precision; i++) {
    multiplier *= 10;
  }

  return Math.round((value + Number.EPSILON) * multiplier) / multiplier;
}

export function valuesFromFieldObjects(formData, cleanEmpty = true) {
  const data = {};
  Object.keys(formData).forEach((field) => {
    if (cleanEmpty) {
      if (typeof formData[field].value === 'string') {
        if (formData[field].value.length > 0) {
          data[field] = formData[field].value;
        }
      } else {
        data[field] = formData[field].value;
      }
    } else {
      data[field] = formData[field].value;
    }
  });
  return data;
}
export function isEmptyObject(obj) {
  return (
    obj && // 👈 null and undefined check
    Object.keys(obj).length === 0 &&
    Object.getPrototypeOf(obj) === Object.prototype
  );
}

export function getStaticServerFilePath(imagePath) {
  return `${process.env.REACT_APP_API_CLIENT_BASE_PATH}/${imagePath}`;
}

export function dateToIso(date, showTimeSelect) {
  if (showTimeSelect) {
    return date.toISOString();
  } else {
    return date
      .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
      .toISOString();
  }
}

export function blobToDataURL(blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (_e) => resolve(reader.result);
    reader.onerror = (_e) => reject(reader.error);
    reader.onabort = (_e) => reject(new Error('Read aborted'));
    reader.readAsDataURL(blob);
  });
}
