import { ReactKeycloakProvider } from '@react-keycloak/web';
import FallbackLoading from 'components/Loading/FallbackLoading';
import PublicPageLayout from 'layout/PublicPageLayout';
import keycloak, {
  eventLogger,
  initOptions,
  tokenLogger,
} from 'lib/auth/keycloak';
import { AuthProvider } from 'lib/providers/authProvider';
import { NavigationProvider } from 'lib/providers/navigationProvider';
import { NotificationProvider } from 'lib/providers/notificationsProvider';
import { OptionsProvider } from 'lib/providers/optionsProvider';
import { ServerBridgeProvider } from 'lib/providers/serverBridgeProvider';
import React from 'react';
import { Routes, Route, Outlet } from 'react-router-dom';
import ProtectedPageLayout from 'layout/ProtectedPageLayout';
import { CanvasProvider } from 'lib/providers/canvasProvider';

const Dashboard = React.lazy(() => import('./Dashboard'));
const Generate = React.lazy(() => import('./Generate'));
const Templates = React.lazy(() => import('./Templates'));
const Creator = React.lazy(() => import('./Templates/Creator'));
const CreatorForm = React.lazy(() => import('./Templates/CreatorForm'));
const Profile = React.lazy(() => import('./Profile'));
const Settings = React.lazy(() => import('./Settings'));
const Labels = React.lazy(() => import('./Labels'));
const Plans = React.lazy(() => import('./Plans'));
const Payments = React.lazy(() => import('./Payments'));
const PaymentsReceipt = React.lazy(() => import('./Payments/Receipt'));
const Fonts = React.lazy(() => import('./Fonts'));
const NotFound = React.lazy(() => import('./NotFound'));
const LogOutPage = React.lazy(() => import('./LogoutPage'));
const SingUp = React.lazy(() => import('./SignUp'));

const dashboardPage = (
  <React.Suspense fallback={<FallbackLoading />}>
    <Dashboard />
  </React.Suspense>
);

const generatePage = (
  <React.Suspense fallback={<FallbackLoading />}>
    <Generate />
  </React.Suspense>
);

const templatesPage = (
  <React.Suspense fallback={<FallbackLoading />}>
    <Templates />
  </React.Suspense>
);

const creatorPage = (
  <React.Suspense fallback={<FallbackLoading />}>
    <Creator />
  </React.Suspense>
);

const creatorFormPage = (
  <React.Suspense fallback={<FallbackLoading />}>
    <CreatorForm />
  </React.Suspense>
);

const profilePage = (
  <React.Suspense fallback={<FallbackLoading />}>
    <Profile />
  </React.Suspense>
);

const settingsPage = (
  <React.Suspense fallback={<FallbackLoading />}>
    <Settings />
  </React.Suspense>
);

const labelsPage = (
  <React.Suspense fallback={<FallbackLoading />}>
    <Labels />
  </React.Suspense>
);

const plansPage = (
  <React.Suspense fallback={<FallbackLoading />}>
    <Plans />
  </React.Suspense>
);

const paymentsPage = (
  <React.Suspense fallback={<FallbackLoading />}>
    <Payments />
  </React.Suspense>
);

const paymentsReceiptPage = (
  <React.Suspense fallback={<FallbackLoading />}>
    <PaymentsReceipt />
  </React.Suspense>
);

const fontsPage = (
  <React.Suspense fallback={<FallbackLoading />}>
    <Fonts />
  </React.Suspense>
);

const notFoundPage = (
  <React.Suspense fallback={<FallbackLoading />}>
    <NotFound />
  </React.Suspense>
);

const signUpPage = (
  <React.Suspense fallback={<FallbackLoading />}>
    <PublicPageLayout pageTitleCode={'Sign up'}>
      <SingUp />
    </PublicPageLayout>
  </React.Suspense>
);

const logOutPage = (
  <React.Suspense fallback={<FallbackLoading />}>
    <LogOutPage />
  </React.Suspense>
);

function PrivateRoute() {
  return (
    <ReactKeycloakProvider
      authClient={keycloak}
      autoRefreshToken={true}
      initOptions={initOptions}
      onEvent={eventLogger}
      onTokens={tokenLogger}
      LoadingComponent={<FallbackLoading />}
    >
      <AuthProvider>
        <NavigationProvider>
          <NotificationProvider>
            <ServerBridgeProvider>
              <OptionsProvider>
                <CanvasProvider>
                  <Outlet />
                </CanvasProvider>
              </OptionsProvider>
            </ServerBridgeProvider>
          </NotificationProvider>
        </NavigationProvider>
      </AuthProvider>
    </ReactKeycloakProvider>
  );
}

function AppRouter() {
  return (
    <Routes>
      <Route path="/signup" element={signUpPage} />
      <Route element={<PrivateRoute />}>
        <Route path="/" element={<ProtectedPageLayout />}>
          <Route index element={dashboardPage} />
          <Route path="generate" element={generatePage} />
          <Route path="templates/">
            <Route index element={templatesPage} />
            <Route path="editor/:templateCode" element={creatorPage} />
            {/*<Route path="editor/new" element={creatorPage} />*/}
            <Route path="form/:templateCode" element={creatorFormPage} />
            <Route path="form/new" element={creatorFormPage} />
          </Route>
          <Route path="labels" element={labelsPage} />
          <Route path="profile" element={profilePage} />
          {/*<Route path="settings" element={settingsPage} />*/}
          <Route path="plans" element={plansPage} />
          <Route path="payments">
            <Route index element={paymentsPage} />
            <Route path=":paymentId/receipt" element={paymentsReceiptPage} />
          </Route>
          <Route path="fonts" element={fontsPage} />
          <Route path="logout" element={logOutPage} />
          <Route path="*" element={notFoundPage} />
        </Route>
      </Route>
    </Routes>
  );
}

export default AppRouter;
