import { EuiI18n, EuiLink, EuiText } from '@elastic/eui';
import { useQueryClient } from '@tanstack/react-query';
import {
  getEntityItemCacheKey,
  invalidateCacheEntry,
  QUERY_KEY_MAPPING,
} from 'lib/hooks/api/common';
import { useUserPlanUsageSummary } from 'lib/hooks/api/planUsages';
import { PLAN_TABS_IDS } from 'pages/Plans';
import React, { createContext, useState, useContext, useEffect } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import useGlobalProvider from 'lib/providers/globalProvider';
import { Configuration, UsersApi } from '@hello-label/api-client';
import { useNavigate } from 'react-router-dom';

const initialState = {
  user: null,
};

const AuthContext = createContext({
  user: initialState.user,
});

export const AuthProvider = ({ children }) => {
  const [apiClient, setApiClient] = useState(null);
  const {
    setFatalError,
    setGlobalUser,
    setLoading,
    setPersistentNotification,
  } = useGlobalProvider();
  const { keycloak, initialized } = useKeycloak();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [user, setUser] = useState(initialState.user);
  const [userToken, setUserToken] = useState(null);
  const { planUsageSummary: userPlanUsageSummary } = useUserPlanUsageSummary(
    !!apiClient && user?.enabled,
    apiClient,
    true,
    {},
  );
  const [authInitialized, setAuthInitialized] = useState(false);
  const [refreshUser, setRefreshUser] = useState(false);

  useEffect(() => {
    setLoading(!authInitialized, AuthProvider.name);
  }, [authInitialized]);

  useEffect(() => {
    if (initialized) {
      setUserToken(keycloak.token);
      setApiClient(
        new UsersApi(
          new Configuration({
            basePath: process.env.REACT_APP_API_CLIENT_BASE_PATH,
            accessToken: keycloak.token,
          }),
        ),
      );
    }
  }, [keycloak, initialized]);

  useEffect(() => {
    if (apiClient) {
      fetchUser();
    }
  }, [apiClient]);

  useEffect(() => {
    if (apiClient) {
      invalidateCacheEntry(
        queryClient,
        QUERY_KEY_MAPPING.planUsages,
        'item',
        'summary',
      );
    }
  }, [refreshUser]);

  useEffect(() => {
    if (userPlanUsageSummary) {
      const { expires_in } = userPlanUsageSummary;
      if (expires_in <= 30 && expires_in !== -1) {
        setPersistentNotification({
          title: 'Your plan is due soon',
          text: (
            <EuiText size="s">
              Your plan expires in {expires_in}{' '}
              {expires_in === 1 ? 'day' : 'days'}. Please upgrade it in order to
              continue using our services. Visit our{' '}
              <EuiLink
                color={'warning'}
                onClick={() =>
                  navigate('/plans', { state: { tab: PLAN_TABS_IDS.New } })
                }
              >
                <EuiI18n token={'plans'} default={'plans'} />
              </EuiLink>{' '}
              .
            </EuiText>
          ),
          color: 'warning',
          iconType: 'alert',
        });
      }
      setAuthInitialized(true);
    }
  }, [userPlanUsageSummary]);

  async function fetchUser() {
    try {
      setLoading(true, AuthProvider.name);
      const { data } = await apiClient.usersProtectedControllerGetCurrentUser();
      setUser(data);
      setGlobalUser(data);
      if (!data.enabled) {
        setFatalError({
          title: 'Your account is disabled',
          body: 'Your account is currently disabled. If you have just created your account please wait until we process your request and enable it. Thanks',
          color: 'warning',
        });
      }
    } catch (err) {
      if (err.response) {
        if (err.response.status === 403) {
          setFatalError({
            title: 'Forbidden',
            body: 'You are trying to access a restricted area, you will be logged out in 5 seconds.',
          });
          setTimeout(() => keycloak.logout(), 5000);
        }
      }
      console.log(err);
    } finally {
      setLoading(false, AuthProvider.name);
    }
  }

  const login = () => {
    keycloak.login();
  };

  const logout = () => {
    keycloak.logout();
  };

  const isTokenExpired = () => {
    return keycloak.isTokenExpired();
  };

  const updateUserData = async () => {
    await fetchUser();
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        login,
        logout,
        userToken,
        apiClient,
        isTokenExpired,
        updateUserData,
        userPlanUsageSummary,
        setRefreshUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default function useAuth() {
  return useContext(AuthContext);
}
