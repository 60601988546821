/** @jsxImportSource @emotion/react */
import { useMemo } from 'react';
import { defaultShapes } from 'lib/canvasEditor/dist/drawing/shapes';
import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiIcon,
  EuiSpacer,
  useEuiTheme,
  transparentize,
  shade,
  EuiHorizontalRule,
  EuiI18n,
} from '@elastic/eui';
import SVG from 'react-inlinesvg';
import { css } from '@emotion/react';
import PanelButton from 'components/Creator/CreatorCanvas/CanvasPanel/ActionPanels/PanelButton';
import useCanvas from 'lib/providers/canvasProvider';

function ShapeSelector() {
  const { editor, setIsCanvasPanelExpanded, isMobile, setActivePanelAction } =
    useCanvas();
  const { euiTheme } = useEuiTheme();
  const shapes = useMemo(() => {
    const shapeArray = [];
    let tmp = [];
    defaultShapes.forEach((shape, i) => {
      if (i % 6 === 0 && i > 0) {
        shapeArray.push(tmp);
        tmp = [shape];
      } else {
        tmp.push(shape);
      }
    });
    return shapeArray;
  }, []);

  const handleShapeClick = async (shape) => {
    await editor.drawShape(shape, '#000', '#fff');
    closeCanvasPanel();
  };

  const handleAddALineClick = async () => {
    await editor.addLine();
    closeCanvasPanel();
  };

  const closeCanvasPanel = () => {
    if (isMobile) {
      setIsCanvasPanelExpanded(false);
      setActivePanelAction(null);
    }
  };

  return (
    <div>
      <PanelButton
        color={euiTheme.colors.primary}
        textColor={euiTheme.colors.text}
        hoverColor={shade(euiTheme.colors.primary, 0.1)}
        fill={true}
        content={<EuiI18n default={'Add a line'} token={'Add a line'} />}
        onClick={handleAddALineClick}
      />
      <EuiHorizontalRule margin={'l'} />
      {shapes.map((shapesRow, rowIndex) => (
        <div key={rowIndex}>
          <EuiFlexGroup
            alignItems={'center'}
            gutterSize={'m'}
            responsive={false}
          >
            {shapesRow.map((shape, index) => (
              <EuiFlexItem key={index}>
                <SVG
                  onClick={() => handleShapeClick(shape)}
                  src={shape}
                  css={css`
                    stroke: ${euiTheme.colors.text} !important;
                    fill: ${euiTheme.colors.emptyShade} !important;
                    fill-opacity: 0;
                    padding: 5px;
                    border-radius: ${euiTheme.border.radius.medium};

                    &:hover {
                      background-color: ${transparentize(
                        euiTheme.colors.darkShade,
                        0.05,
                      )};
                      cursor: pointer;
                    }
                  `}
                />
              </EuiFlexItem>
            ))}
          </EuiFlexGroup>
          {rowIndex + 1 < shapes.length ? <EuiSpacer size={'m'} /> : null}
        </div>
      ))}
    </div>
  );
}

export default ShapeSelector;
