/** @jsxImportSource @emotion/react */
import {
  EuiHeaderSectionItem,
  EuiHeaderSectionItemButton,
  EuiPopover,
  useEuiTheme,
  useGeneratedHtmlId,
  tint,
  shade,
  EuiButtonEmpty,
} from '@elastic/eui';
import React, { useState } from 'react';
import { css } from '@emotion/react';
import CanvasTooltip from 'components/Creator/CanvasTooltip';
import useAppTheme from 'lib/providers/appProvider';

export function TextActionButton({ label }) {
  return <div>{label}</div>;
}

function ActionPopover({
  buttonIcon,
  buttonLabel,
  content,
  label,
  width = 200,
  marginRight = 0,
  anchorPosition = 'downLeft',
}) {
  const { euiTheme } = useEuiTheme();
  const { theme } = useAppTheme();
  const [isOpen, setIsOpen] = useState(false);
  const actionPopoverId = useGeneratedHtmlId({ prefix: 'actionPopover' });

  const onMenuButtonClick = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  let button;
  if (buttonIcon) {
    button = (
      <EuiHeaderSectionItemButton
        css={css`
          width: 32px !important;
          min-width: 32px !important;
          height: 32px !important;
          display: flex !important;
          justify-content: center !important;
          align-items: center !important;
          background-color: ${shade(euiTheme.colors.lightestShade, 0.05)};
          color: ${euiTheme.colors.text};

          &:hover {
            background-color: ${tint(euiTheme.colors.darkestShade, 0.2)};
            color: ${theme === 'dark' ? '#000' : '#fff'};
          }
        `}
        aria-controls={actionPopoverId}
        aria-expanded={isOpen}
        aria-haspopup="true"
        aria-label={label}
        onClick={onMenuButtonClick}
      >
        <CanvasTooltip position={'bottom'} label={label}>
          {buttonIcon}
        </CanvasTooltip>
      </EuiHeaderSectionItemButton>
    );
  } else {
    button = (
      <EuiButtonEmpty
        onClick={onMenuButtonClick}
        css={css`
          height: 32px !important;
          background-color: ${shade(euiTheme.colors.lightestShade, 0.05)};
          color: ${euiTheme.colors.text};

          &:hover {
            background-color: ${tint(euiTheme.colors.darkestShade, 0.2)};
            color: ${theme === 'dark' ? '#000' : '#fff'};
          }
        `}
      >
        <CanvasTooltip position={'bottom'} label={label}>
          {buttonLabel}
        </CanvasTooltip>
      </EuiButtonEmpty>
    );
  }

  return (
    <EuiHeaderSectionItem
      css={css`
        margin-right: ${marginRight}px;
      `}
    >
      <EuiPopover
        id={actionPopoverId}
        repositionOnScroll
        button={button}
        isOpen={isOpen}
        anchorPosition={anchorPosition}
        closePopover={closeMenu}
        panelPaddingSize="m"
      >
        <div
          css={css`
            width: ${width}px;
          `}
        >
          {content}
        </div>
      </EuiPopover>
    </EuiHeaderSectionItem>
  );
}

export default ActionPopover;
