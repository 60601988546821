import PersistentNotification from 'components/PersistentNotification';
import ErrorBoundary from 'lib/ErrorService/ErrorBoundary';
import useApp from 'lib/providers/appProvider';
import React from 'react';
import { EuiPageTemplate } from '@elastic/eui';
import useNavigation from 'lib/providers/navigationProvider';
import AppMessage from 'components/AppMessage';
import useGlobalProvider from 'lib/providers/globalProvider';
import PageBanner from 'components/PageBanner';
import PageContentChecker from 'components/PageContentChecker';

function ProtectedPageLayout() {
  const { fatalError } = useGlobalProvider();
  const { fullPageLayout, pageHeaderActions, mainHeader, currentPageInfo } =
    useNavigation();
  const { headTitle } = useApp();

  return (
    <EuiPageTemplate
      paddingSize={fullPageLayout ? 'none' : 'l'}
      offset={48}
      restrictWidth
    >
      {mainHeader}
      <PersistentNotification />
      {fullPageLayout || !currentPageInfo?.profileMenu ? null : (
        <EuiPageTemplate.Header
          restrictWidth
          pageTitle={headTitle}
          bottomBorder
          rightSideItems={pageHeaderActions}
          rightSideGroupProps={{ alignItems: 'center', gutterSize: 's' }}
        />
      )}
      <EuiPageTemplate.Section restrictWidth={!fullPageLayout}>
        {fatalError ? (
          <PageBanner
            title={fatalError.title}
            body={fatalError.body}
            color={fatalError.color}
          />
        ) : (
          <ErrorBoundary>
            <PageContentChecker />
          </ErrorBoundary>
        )}
        <AppMessage />
      </EuiPageTemplate.Section>
    </EuiPageTemplate>
  );
}

export default ProtectedPageLayout;
