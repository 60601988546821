/** @jsxImportSource @emotion/react */
import {
  EuiFlexGroup,
  EuiHorizontalRule,
  EuiPopover,
  EuiButton,
  EuiFlexItem,
  EuiText,
  EuiSwitch,
  EuiSpacer,
  useCurrentEuiBreakpoint,
  useEuiMaxBreakpoint,
  useEuiMinBreakpoint,
  EuiI18n,
} from '@elastic/eui';
import SearchField from 'components/forms/fields/SearchField';
import { handleUseSpecialQueryChange } from 'components/table/reducer';
import { ACTIONS_LABEL } from 'components/table/Table/index';
import moment from 'moment/moment';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { css } from '@emotion/react';

function columnsToVisibleColumnsObject(columns) {
  const obj = {};
  columns
    .filter((col) => col.name !== ACTIONS_LABEL)
    .forEach((col) => (obj[col.field] = col.visible));
  return obj;
}

export const FieldInputValueFunctions = {
  string: (val) => ({ $regex: '.*' + val + '.*', $options: 'i' }),
  int: (val) => parseInt(val),
  float: (val) => parseFloat(val),
  date: (val) => (moment(val).isValid() ? moment(val).toDate() : null),
  dateRange: (val) => val,
  select: (values) => ({ $in: values.map((val) => val.code) }),
};

function TableToolbar({
  originalColumns,
  setColumns,
  totalEntities,
  entityName,
  entitiesName,
  selectedEntities,
  showDownloadButton,
  handleDownload,
  defaultVisibleColumns,
  compressTable,
  setCompressedTable,
  setTableQuery,
  tableDispatcher,
  isLoading,
  showNewEntityButton = false,
  handleNewEntityClick = null,
}) {
  const [columnsMenuIsOpen, setColumnsMenuIsOpen] = useState(false);
  const [visibleColumns, setVisibleColumns] = useState(
    columnsToVisibleColumnsObject(defaultVisibleColumns),
  );
  const searchableColumns = useMemo(
    () => defaultVisibleColumns.filter((col) => col.searchable),
    [defaultVisibleColumns],
  );
  const currentBreakpoint = useCurrentEuiBreakpoint();

  const handleColumnsClick = () => {
    setColumnsMenuIsOpen(!columnsMenuIsOpen);
  };

  const closeColumnsMenu = () => {
    setColumnsMenuIsOpen(false);
  };

  const toggleColumnVisibility = (column) => {
    setVisibleColumns({
      ...visibleColumns,
      [column.field]: !visibleColumns[column.field],
    });
  };

  const handleTableSearch = (value) => {
    let useSpecialQuery = false;
    const queryConditions = [];

    searchableColumns.forEach((col) => {
      const field = col.searchField ? col.searchField : col.field;
      if (col.requiresSpecialQuery) {
        useSpecialQuery = true;
      }
      try {
        const searchValue = FieldInputValueFunctions[col.type](value);
        if (searchValue) {
          queryConditions.push({ [field]: searchValue });
        }
      } catch (err) {}
    });
    handleUseSpecialQueryChange(useSpecialQuery, tableDispatcher);
    if (queryConditions.length > 0) {
      setTableQuery({ $or: queryConditions });
    } else {
      setTableQuery({});
    }
  };

  useEffect(() => {
    const filteredColumns = originalColumns.filter(
      (col) => visibleColumns[col.field] || col.name === ACTIONS_LABEL,
    );
    setColumns(filteredColumns);
  }, [visibleColumns]);

  const columnsMenuButton = (
    <EuiButton
      iconSide="right"
      color="text"
      size="s"
      onClick={handleColumnsClick}
      iconType="listAdd"
      fullWidth={['xs', 's'].includes(currentBreakpoint)}
    >
      <EuiI18n token={'Columns'} default={'Columns'} />
    </EuiButton>
  );

  let newEntityButton;
  if (showNewEntityButton) {
    newEntityButton = (
      <EuiButton
        iconSide="right"
        color="primary"
        size="s"
        onClick={handleNewEntityClick}
        iconType="plusInCircle"
        fullWidth={['xs', 's'].includes(currentBreakpoint)}
      >
        <EuiI18n
          token={'New {entityName}'}
          default={'New {entityName}'}
          values={{ entityName }}
        />
      </EuiButton>
    );
  }

  return (
    <>
      <EuiFlexGroup alignItems="center" justifyContent="spaceBetween">
        <EuiFlexItem grow={false}>
          <EuiText size="s">
            {selectedEntities.length > 0 ? (
              <EuiI18n
                token={'selectedEntities'}
                default={'{entitiesName} selected {number}'}
                values={{ entitiesName, number: selectedEntities.length }}
              />
            ) : (
              <EuiI18n
                token={'totalEntities'}
                default={'{entitiesName} total {total}'}
                values={{ entitiesName, total: totalEntities }}
              />
            )}
          </EuiText>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiFlexGroup
            wrap
            gutterSize="s"
            alignItems="center"
            justifyContent={'flexEnd'}
          >
            <EuiFlexItem grow={true}>
              <SearchBar
                handleOnSearch={handleTableSearch}
                isLoading={isLoading}
              />
            </EuiFlexItem>
            {!['xs', 's'].includes(currentBreakpoint) ? (
              <EuiFlexItem grow={false}>
                <EuiButton
                  iconSide="right"
                  color="text"
                  size="s"
                  onClick={() => setCompressedTable(!compressTable)}
                  iconType={
                    compressTable ? 'tableDensityNormal' : 'tableDensityCompact'
                  }
                >
                  {compressTable ? (
                    <EuiI18n token={'Expand'} default={'Expand'} />
                  ) : (
                    <EuiI18n token={'Reduce'} default={'Reduce'} />
                  )}
                </EuiButton>
              </EuiFlexItem>
            ) : null}
            <EuiFlexItem grow={false}>
              <EuiPopover
                button={columnsMenuButton}
                isOpen={columnsMenuIsOpen}
                closePopover={closeColumnsMenu}
                panelPaddingSize="s"
                css={css`
                  .euiPopover__anchor {
                    width: 100% !important;
                  }
                `}
              >
                <div
                  css={css`
                    ${useEuiMaxBreakpoint('s')} {
                      min-width: calc(100vw - 16px);
                    }
                    ${useEuiMinBreakpoint('m')} {
                      min-width: 160px;
                    }
                  `}
                >
                  {originalColumns
                    .filter((col) => col.name !== ACTIONS_LABEL)
                    .map((col, index) => (
                      <Fragment key={index}>
                        <EuiFlexGroup
                          justifyContent="flexStart"
                          alignItems="center"
                          gutterSize="none"
                          responsive={false}
                        >
                          <EuiFlexItem grow={false}>
                            <EuiSwitch
                              label=""
                              className="euiSwitch--mini"
                              checked={visibleColumns[col.field]}
                              onChange={() => toggleColumnVisibility(col)}
                              compressed={true}
                            />
                          </EuiFlexItem>
                          <EuiFlexItem grow={true}>
                            <span
                              onClick={() => toggleColumnVisibility(col)}
                              css={css`
                                cursor: pointer;
                              `}
                            >
                              {col.name}
                            </span>
                          </EuiFlexItem>
                        </EuiFlexGroup>
                        <EuiSpacer size="s" />
                      </Fragment>
                    ))}
                </div>
              </EuiPopover>
            </EuiFlexItem>
            {showDownloadButton ? (
              <EuiFlexItem grow={false}>
                <EuiButton
                  iconSide="right"
                  color="text"
                  size="s"
                  onClick={handleDownload}
                  iconType="download"
                >
                  <EuiI18n token={'Download'} default={'Download'} />
                </EuiButton>
              </EuiFlexItem>
            ) : null}
            {showNewEntityButton ? (
              <EuiFlexItem grow={false}>{newEntityButton}</EuiFlexItem>
            ) : null}
          </EuiFlexGroup>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiHorizontalRule margin="s" />
    </>
  );
}

function SearchBar({ handleOnSearch, isLoading = false }) {
  const [searchValue, setSearchValue] = useState({
    value: '',
    isInvalid: false,
    errors: [],
    label: 'Search',
    is_required: false,
  });

  const handleChangeInternal = (e) => {
    setSearchValue({ ...searchValue, value: e.target.value });
  };

  const handleOnSearchInternal = (value) => {
    if (handleOnSearch) {
      handleOnSearch(value);
    }
  };

  return (
    <div
      css={css`
        min-width: 300px;
      `}
    >
      <SearchField
        field={searchValue}
        fullWidth={true}
        handleOnChange={(e) => handleChangeInternal(e)}
        handleOnSearch={handleOnSearchInternal}
        type={'search'}
        noLabel={true}
        compressed={true}
        isClearable={true}
        isLoading={isLoading}
      />
    </div>
  );
}

export default TableToolbar;
