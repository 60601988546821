import FontFaceObserver from 'fontfaceobserver';

export const DEFAULT_FONTS = [
  'Helvetica',
  'Arial',
  'Gill Sans',
  'Georgia',
  'Times New Roman',
  'Baskerville',
  'Courier New',
  'Bradley Hand',
  'Brush Script MT',
  'Verdana',
  'Impact',
  'Trebuchet MS',
  'Comic Sans MS',
];

export function isCustomFont(font) {
  const custom = DEFAULT_FONTS.filter((fontName) => fontName === font);
  return custom.length === 0;
}

export function getSupportedFonts() {
  return [...DEFAULT_FONTS].sort();
}

export async function loadFont(font) {
  const newFont = new FontFaceObserver(font);
  await newFont.load();
  console.log('loaded');
}
