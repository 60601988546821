import { fabric } from 'fabric';

export function handleCopyEvent(canvas, copyEvent) {
  if (!canvas.getActiveObject()) return;

  // copy image as dataUrl
  if (canvas.getActiveObject().type === 'image') {
    copyEvent.preventDefault();

    copyEvent.clipboardData.setData(
      'text/plain',
      canvas.getActiveObject().toDataURL(),
    );
  }

  // if selection is not an image, copy as JSON
  if (canvas.getActiveObject().type !== 'image') {
    copyEvent.preventDefault();
    canvas.getActiveObject().clone((cloned) => {
      copyEvent.clipboardData.setData(
        'text/plain',
        JSON.stringify(cloned.toJSON()),
      );
    });
  }
}

export function handlePasteEvent(canvas, pasteEvent) {
  let pasteTextData = pasteEvent.clipboardData.getData('text');

  // check if base64 image
  if (pasteTextData && isBase64String(pasteTextData)) {
    fabric.Image.fromURL(pasteTextData, (img) => {
      img.set({
        left: 10,
        top: 10,
      });
      img.scaleToHeight(100);
      img.scaleToWidth(100);
      canvas.add(img);
      canvas.setActiveObject(img);
      canvas.fire('object:modified');
    });

    return;
  }

  // check if there's an image in clipboard items
  if (pasteEvent.clipboardData.items.length > 0) {
    for (let i = 0; i < pasteEvent.clipboardData.items.length; i++) {
      if (pasteEvent.clipboardData.items[i].type.indexOf('image') === 0) {
        let blob = pasteEvent.clipboardData.items[i].getAsFile();
        if (blob !== null) {
          let reader = new FileReader();
          reader.onload = (f) => {
            fabric.Image.fromURL(f.target.result, (img) => {
              img.set({
                left: 10,
                top: 10,
              });
              img.scaleToHeight(100);
              img.scaleToWidth(100);
              canvas.add(img);
              canvas.setActiveObject(img);
              canvas.fire('object:modified');
            });
          };
          reader.readAsDataURL(blob);
        }
      }
    }
  }

  // check if JSON and type is valid
  let validTypes = [
    'rect',
    'circle',
    'line',
    'path',
    'polygon',
    'polyline',
    'text',
    'textbox',
    'group',
  ];
  if (isJSONObjectString(pasteTextData)) {
    let obj = JSON.parse(pasteTextData);
    if (!validTypes.includes(obj.type)) return;

    // insert and select
    fabric.util.enlivenObjects([obj], function (objects) {
      objects.forEach(function (o) {
        o.set({
          left: 10,
          top: 10,
        });
        canvas.add(o);
        o.setCoords();
        canvas.setActiveObject(o);
      });
      canvas.renderAll();
      canvas.fire('object:modified');
    });
  }
}

export const isJSONObjectString = (s) => {
  try {
    const o = JSON.parse(s);
    return !!o && typeof o === 'object' && !Array.isArray(o);
  } catch {
    return false;
  }
};

// base64 validator
export const isBase64String = (str) => {
  try {
    str = str.split('base64,').pop();
    window.atob(str);
    return true;
  } catch (e) {
    return false;
  }
};
