import ColorPicker from 'components/Creator/CreatorCanvas/CanvasHeader/UtilityHeaderComponents/ActionsComponents/ColorPicker';
import StrokeActions from 'components/Creator/CreatorCanvas/CanvasHeader/UtilityHeaderComponents/ActionsComponents/StokeActions';

function ShapeObjectActions({ objects }) {
  return (
    <>
      <ColorPicker
        objects={objects}
        optionName={'fill'}
        label={'Set fill color'}
        marginRight={4}
      />
      <StrokeActions objects={objects} />
    </>
  );
}

export default ShapeObjectActions;
