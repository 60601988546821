import { EuiBadge, EuiText, EuiTextColor, useEuiTheme } from '@elastic/eui';
import React from 'react';
import { css } from '@emotion/react';
import { DEFAULT_FIELD_TYPE } from 'lib/templateFieldsUtils';

function TemplateFieldItem({ fieldInfo, inline, textColor }) {
  const { euiTheme } = useEuiTheme();

  return (
    <>
      <EuiText>
        <h5>
          <EuiTextColor color={textColor}>{fieldInfo.name}</EuiTextColor>
          {inline ? (
            <EuiBadge
              css={css`
                margin-left: 10px;
                color: ${euiTheme.colors.darkShade} !important;
              `}
              iconType={
                fieldInfo.type === DEFAULT_FIELD_TYPE ? 'visText' : 'image'
              }
              color={
                fieldInfo.type === DEFAULT_FIELD_TYPE ? 'success' : 'warning'
              }
            >
              {fieldInfo.type} field
            </EuiBadge>
          ) : null}
        </h5>
      </EuiText>
      {inline ? null : (
        <EuiBadge
          iconType={fieldInfo.type === DEFAULT_FIELD_TYPE ? 'visText' : 'image'}
          color={fieldInfo.type === DEFAULT_FIELD_TYPE ? 'success' : 'warning'}
        >
          {fieldInfo.type} field
        </EuiBadge>
      )}
    </>
  );
}

export default TemplateFieldItem;
