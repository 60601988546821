/** @jsxImportSource @emotion/react */
import {
  EuiCard,
  EuiForm,
  EuiHorizontalRule,
  EuiSpacer,
  EuiIcon,
  EuiFlexGrid,
  EuiFlexItem,
} from '@elastic/eui';
import { css } from '@emotion/react';
import { useQueryClient } from '@tanstack/react-query';
import FormActions from 'components/forms/Common/Action';
import BankTransfer from 'components/Payments/methods/BankTransfer';
import CreditCard from 'components/Payments/methods/CreditCard';
import PayPal from 'components/Payments/methods/PayPal';
import { handleApiError } from 'lib/ErrorService';
import {
  getEntityListCacheKey,
  invalidateCacheEntry,
  QUERY_KEY_MAPPING,
} from 'lib/hooks/api/common';
import useAuth from 'lib/providers/authProvider';
import useGlobalProvider from 'lib/providers/globalProvider';
import { useEffect, useState } from 'react';
import bankTransferIcon from 'assets/paymentMethods/bank-transfer.svg';
import payPalIcon from 'assets/paymentMethods/paypal.svg';
import creditCardIcon from 'assets/paymentMethods/credit-card.svg';

const PAYMENT_METHODS = [
  {
    name: 'bank-transfer',
    label: 'Bank transfer',
    icon: bankTransferIcon,
    component: BankTransfer,
    disabled: false,
  },
  {
    name: 'paypal',
    label: 'PayPal',
    icon: payPalIcon,
    component: PayPal,
    disabled: true,
  },
  {
    name: 'credit-card',
    label: 'Card',
    icon: creditCardIcon,
    component: CreditCard,
    disabled: true,
  },
];

function PaymentForm({
  description,
  amount,
  planId,
  objectPurchased,
  onCancelAction,
  onSuccessAction,
  additionalNotes = null,
  handleForm = true,
  setPaymentMethodExternal = null,
  usePublicApi = false,
}) {
  const { setMessage, setLoading } = useGlobalProvider();
  const { apiClient } = useAuth();
  const queryClient = useQueryClient();
  const [paymentMethod, setPaymentMethod] = useState(PAYMENT_METHODS[0]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true, PaymentForm.name);
      const paymentDataBody = {
        payment_mode: paymentMethod.name,
        amount: amount,
        plan: planId,
        account_number:
          paymentMethod.name === 'bank-transfer' ? 'no-account-number' : '',
        transaction_code:
          paymentMethod.name === 'bank-transfer' ? 'no-code' : '',
      };
      const { data: paymentData } =
        await apiClient.paymentsUsersControllerCreate(paymentDataBody);
      invalidateCacheEntry(queryClient, QUERY_KEY_MAPPING.payments, 'list');
      onSuccessAction(paymentData);
    } catch (err) {
      handleApiError(err, setMessage, 'Error while processing your payment');
    } finally {
      setLoading(false, PaymentForm.name);
    }
  };

  useEffect(() => {
    if (setPaymentMethodExternal) {
      setPaymentMethodExternal(paymentMethod);
    }
  }, [paymentMethod]);

  const PaymentComponent = paymentMethod ? paymentMethod.component : null;

  return (
    <EuiForm
      component={'form'}
      onSubmit={handleForm ? handleSubmit : undefined}
    >
      {description ? (
        <>
          {description}
          <EuiSpacer size="xl" />{' '}
        </>
      ) : null}
      <EuiFlexGrid columns={3} gutterSize="xl">
        {PAYMENT_METHODS.map((method, index) => (
          <EuiFlexItem key={index}>
            <PaymentMethodCard
              method={method}
              selectedPaymentMethod={paymentMethod}
              setPaymentMethod={setPaymentMethod}
            />
          </EuiFlexItem>
        ))}
      </EuiFlexGrid>
      {paymentMethod ? (
        <>
          <EuiSpacer size={'xl'} />
          <PaymentComponent
            amount={amount}
            objectPurchased={objectPurchased}
            additionalNotes={additionalNotes}
            usePublicApi={usePublicApi}
          />
        </>
      ) : null}
      {handleForm ? (
        <>
          <EuiHorizontalRule margin="l" />
          <FormActions
            submitLabel={'Continue'}
            handleCancel={onCancelAction}
            cancelLabel={'Back'}
            showCancel={true}
            showRequired={paymentMethod?.name === 'credit-card'}
            showReset={paymentMethod?.name === 'credit-card'}
          />
        </>
      ) : null}
    </EuiForm>
  );
}

export function PaymentMethodCard({
  method,
  setPaymentMethod,
  selectedPaymentMethod,
}) {
  const handleOnClick = () => {
    setPaymentMethod(method);
  };

  return (
    <EuiCard
      titleSize={'xs'}
      icon={<EuiIcon size="xxl" color={'accent'} type={method.icon} />}
      title={method.label}
      isDisabled={method.disabled}
      selectable={{
        size: 's',
        fullWidth: false,
        css: css`
          margin: 0 auto;
          display: none;
        `,
        onClick: () => handleOnClick(),
        isSelected: selectedPaymentMethod?.name === method.name,
        isDisabled: method.disabled,
      }}
    />
  );
}

export default PaymentForm;
