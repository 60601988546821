import { EuiText } from '@elastic/eui';
import { KeyboardAndWheelActions } from 'lib/canvasEditor/dist/keyboardAndWheelActions';
import { defaultTips } from 'lib/canvasEditor/tips';

function CanvasShortcutsAndTips() {
  return (
    <EuiText size={'s'} color={'subdued'}>
      <ul>
        {KeyboardAndWheelActions.map((shortcut, index) => (
          <li key={index}>
            {shortcut.shortcut}: {shortcut.description}
          </li>
        ))}
        {defaultTips.map((tip, index) => (
          <li key={index}>{tip}</li>
        ))}
      </ul>
    </EuiText>
  );
}

export default CanvasShortcutsAndTips;
