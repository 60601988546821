/** @jsxImportSource @emotion/react */
import { EuiToolTip, shade, useEuiTheme } from '@elastic/eui';
import { css } from '@emotion/react';
import { closedBackgroundColor } from 'components/Creator/CreatorCanvas/CanvasPanel';
import useAppTheme from 'lib/providers/appProvider';

function CanvasTooltip({
  label,
  position = 'right',
  shortcut = null,
  children,
}) {
  const { euiTheme } = useEuiTheme();
  const { theme } = useAppTheme();

  return (
    <EuiToolTip
      position={position}
      display={'block'}
      css={css`
        text-align: center;
        margin: 0 auto;
      `}
      content={
        <span>
          {label}
          {shortcut ? (
            <span
              css={css`
                margin: 5px auto 0 auto;
                display: block;
                padding: 6px;
                border-radius: ${euiTheme.border.radius.medium};
                background-color: ${closedBackgroundColor(theme, euiTheme)};
              `}
            >
              {shortcut}
            </span>
          ) : null}
        </span>
      }
    >
      {children}
    </EuiToolTip>
  );
}

export default CanvasTooltip;
