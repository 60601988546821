/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { panelWidthClosed } from 'components/Creator/CreatorCanvas/CanvasPanel/index';
import {
  EuiButtonIcon,
  EuiFlexGroup,
  EuiFlexItem,
  EuiHorizontalRule,
  EuiSpacer,
  EuiText,
  EuiThemeProvider,
} from '@elastic/eui';

function ActivePanelWrapper({ activeAction, closePanel }) {
  return (
    <div
      css={css`
        margin-left: ${panelWidthClosed}px;
        padding: 16px 24px;
      `}
    >
      <EuiThemeProvider colorMode="dark">
        <EuiFlexGroup alignItems="center" responsive={false}>
          <EuiFlexItem grow={9}>
            <EuiText>
              <h4>{activeAction.description}</h4>
            </EuiText>
          </EuiFlexItem>
          <EuiFlexItem grow={1}>
            <EuiButtonIcon
              iconType="menuLeft"
              iconSize={'l'}
              size="s"
              color="text"
              onClick={closePanel}
              aria-label="Close panel"
            />
          </EuiFlexItem>
        </EuiFlexGroup>
        <EuiHorizontalRule margin={'xs'} />
        <EuiSpacer size={'s'} />
        {activeAction.component}
      </EuiThemeProvider>
    </div>
  );
}

export default ActivePanelWrapper;
