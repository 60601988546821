import { fabric } from 'fabric';
import { DRAWING_BOX_FIELD } from 'lib/templateFieldsUtils';

export function drawLimitBox(canvas, width, height, accentColor) {
  canvas.add(
    new fabric.Rect({
      left: -2,
      top: -2,
      originX: 'left',
      originY: 'top',
      width: width + 2,
      height: height + 2,
      angle: 0,
      fill: 'rgba(255,0,0,0)',
      stroke: accentColor,
      strokeDashArray: [10],
      strokeWidth: 2,
      transparentCorners: false,
      selectable: false,
      evented: false,
      exportIgnore: true,
      fieldMetadata: { type: DRAWING_BOX_FIELD, isTemplateField: false },
    }),
  );
}
