/** @jsxImportSource @emotion/react */
import { EuiButtonIcon, EuiCallOut } from '@elastic/eui';
import { css } from '@emotion/react';

function DismissibleCallout({ title, text, iconType, color, dismissMessage }) {
  const handleDismissClick = () => {
    if (dismissMessage) {
      dismissMessage();
    }
  };

  return (
    <EuiCallOut
      css={css`
        position: relative;
        z-index: 1000;
      `}
      title={title}
      color={color}
      iconType={iconType}
    >
      {text ? text : null}
      <EuiButtonIcon
        css={css`
          position: absolute;
          top: 16px;
          right: 16px;
        `}
        iconType="cross"
        color={color}
        aria-label="Dismiss notification"
        onClick={handleDismissClick}
      />
    </EuiCallOut>
  );
}

export default DismissibleCallout;
