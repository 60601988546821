import { useQuery } from '@tanstack/react-query';
import { handleApiError } from 'lib/ErrorService';
import {
  getEntityItemCacheKey,
  QUERY_KEY_MAPPING,
  usePaginatedApiCall,
} from 'lib/hooks/api/common';
import useAuth from 'lib/providers/authProvider';
import useGlobalProvider from 'lib/providers/globalProvider';
import { useEffect } from 'react';

export function useLabels(
  { pageSize, page, query, sort, populate, useSpecialQuery },
  useSetLoading = false,
  paginationEnabled = true,
  axiosOptions = {},
) {
  const {
    isLoading,
    isFetching,
    data: labels,
    fetchStatus,
    error,
  } = usePaginatedApiCall(
    'labelsUsersControllerFindAll',
    QUERY_KEY_MAPPING.labels,
    { pageSize, page, query, sort, populate, useSpecialQuery },
    useSetLoading,
    paginationEnabled,
    axiosOptions,
    'Error while loading labels',
  );

  return {
    isLoading,
    isFetching,
    labels,
    fetchStatus,
    error,
  };
}

export function useLabel(
  labelCode,
  populate = [],
  useSetLoading = true,
  axiosOptions = {},
) {
  const { apiClient } = useAuth();
  const { setLoading, setMessage } = useGlobalProvider();

  const {
    isLoading,
    isFetching,
    fetchStatus,
    data: label,
    error,
  } = useQuery({
    queryKey: getEntityItemCacheKey(QUERY_KEY_MAPPING.labels, labelCode),
    queryFn: fetchQuery,
    staleTime: 60 * 1000 * 10, // 10 minute
    enabled: !!labelCode && !!apiClient,
    onError: (err) =>
      handleApiError(
        err,
        setMessage,
        `Error while loading label with code ${labelCode}`,
      ),
  });

  async function fetchQuery() {
    const { data } = await apiClient.labelsUsersControllerFindOne(
      labelCode,
      populate,
      axiosOptions,
    );
    return data;
  }

  useEffect(() => {
    if (useSetLoading) {
      setLoading(isLoading && fetchStatus !== 'idle', useLabel.name);
    }
    return () => {
      if (isLoading && fetchStatus !== 'idle') {
        setLoading(false, useLabel.name);
      }
    };
  }, [isLoading, fetchStatus]);

  return {
    label,
    isFetching,
    isLoading: isLoading && fetchStatus !== 'idle',
    error,
  };
}
