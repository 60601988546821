import { EuiHeaderSectionItem } from '@elastic/eui';
import React from 'react';
import useCanvas from 'lib/providers/canvasProvider';
import ActionIconGroup from 'components/Creator/CreatorCanvas/CanvasHeader/UtilityHeaderComponents/ActionIconGroup';

const alignActions = [
  {
    label: 'Align left',
    icon: 'editorItemAlignLeft',
    value: 'left',
  },
  {
    label: 'Align center',
    icon: 'editorItemAlignCenter',
    value: 'center-h',
  },
  {
    label: 'Align right',
    icon: 'editorItemAlignRight',
    value: 'right',
  },
  {
    label: 'Align top',
    icon: 'editorItemAlignTop',
    value: 'top',
  },
  {
    label: 'Align middle',
    icon: 'editorItemAlignMiddle',
    value: 'center-v',
  },
  {
    label: 'Align bottom',
    icon: 'editorItemAlignBottom',
    value: 'bottom',
  },
];

function AlignActions() {
  const { editor } = useCanvas();

  const handleAlignActionClick = (value) => {
    editor.alignObjects(null, value);
  };

  return (
    <EuiHeaderSectionItem>
      <ActionIconGroup
        actions={alignActions}
        onActionClick={handleAlignActionClick}
      />
    </EuiHeaderSectionItem>
  );
}

export default AlignActions;
