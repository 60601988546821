import { fabric } from 'fabric';
import { GRID_FIELD } from 'lib/templateFieldsUtils';

export function drawGrid(canvas, canvasHeight, canvasWidth, strokeColor) {
  const nTiles = 4;
  const gridSize = Math.max(canvasHeight, canvasWidth) / nTiles;
  const grid = new fabric.Group([], {
    top: 0,
    left: 0,
    selectable: false,
    evented: false,
    exportIgnore: true,
    fieldMetadata: { type: GRID_FIELD, isTemplateField: false },
  });
  for (let i = 0; i < canvasWidth / gridSize; i++) {
    const vLine = new fabric.Line(
      [i * gridSize, 0, i * gridSize, canvasHeight],
      {
        type: 'line',
        stroke: strokeColor,
        strokeDashArray: [10],
        transparentCorners: false,
        selectable: false,
        evented: false,
        exportIgnore: true,
      },
    );
    grid.addWithUpdate(vLine);
  }
  for (let j = 0; j < canvasHeight / gridSize; j++) {
    const hLine = new fabric.Line(
      [0, j * gridSize, canvasWidth, j * gridSize],
      {
        type: 'line',
        stroke: strokeColor,
        strokeDashArray: [10],
        transparentCorners: false,
        selectable: false,
        evented: false,
        exportIgnore: true,
      },
    );
    grid.addWithUpdate(hLine);
  }
  canvas.add(grid);
  return grid;
}
