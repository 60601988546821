/** @jsxImportSource @emotion/react */
import { EuiI18n, EuiToolTip } from '@elastic/eui';
import { css } from '@emotion/react';
import PlanCardModal from 'components/Plans/PlanCardModal';
import { tableReducer } from 'components/table/reducer';
import {
  LinkTableField,
  PlanCodeCardButton,
  PlanNameCardButton,
  renderDateField,
} from 'components/table/specialFields';
import Table from 'components/table/Table';
import { usePlanUsages } from 'lib/hooks/api/planUsages';
import { useEffect, useMemo, useReducer, useState } from 'react';

const tableInitialState = {
  currentPage: 0,
  pageSize: 5,
  sort: { createdAt: -1 },
  useSpecialQuery: false,
};

const defaultVisibleColumns = [
  { field: '_id', visible: false },
  { field: 'status', visible: true, searchable: true, type: 'string' },
  { field: 'activation_date', visible: true, searchable: false, type: 'date' },
  { field: 'expiration_date', visible: true, searchable: false, type: 'date' },
  { field: 'labels', visible: true, searchable: true, type: 'int' },
  { field: 'templates', visible: true, searchable: true, type: 'int' },
  { field: 'downloads', visible: true, searchable: true, type: 'int' },
  {
    field: 'plan.name',
    visible: true,
    searchable: true,
    type: 'string',
  },
  {
    field: 'payment._id',
    visible: true,
    searchable: true,
    type: 'string',
    requiresSpecialQuery: true,
  },
  { field: 'createdAt', visible: true, searchable: false },
  { field: 'updatedAt', visible: false, searchable: false },
];

const columnsGenerator = (handlePlanCardShowClick) => [
  {
    field: '_id',
    name: 'Id',
    'data-test-subj': 'codeCell',
    sortable: true,
    truncateText: true,
    enlarge: 1,
    align: 'center',
  },
  {
    field: 'status',
    name: 'Status',
    truncateText: true,
    sortable: true,
    align: 'center',
  },
  {
    field: 'activation_date',
    name: 'Activation date',
    sortable: true,
    dataType: 'date',
    render: (date) => renderDateField(date),
    align: 'center',
  },
  {
    field: 'expiration_date',
    name: 'Expiration date',
    sortable: true,
    dataType: 'date',
    render: (date) => renderDateField(date, 'Unlimited'),
    align: 'center',
  },
  {
    field: 'labels',
    name: 'Labels',
    truncateText: true,
    sortable: true,
    align: 'center',
    dataType: 'number',
  },
  {
    field: 'templates',
    name: 'Templates',
    truncateText: true,
    sortable: true,
    align: 'center',
    dataType: 'number',
  },
  {
    field: 'downloads',
    name: 'Downloads',
    truncateText: true,
    sortable: true,
    align: 'center',
    dataType: 'number',
  },
  {
    field: 'plan.name',
    name: 'Plan',
    truncateText: true,
    sortable: true,
    align: 'center',
    render: (name, item) => (
      <PlanNameCardButton
        planData={item?.plan}
        name={name}
        handlePlanCardShowClick={handlePlanCardShowClick}
      />
    ),
  },
  {
    field: 'payment._id',
    name: 'Payment',
    truncateText: true,
    sortable: true,
    align: 'center',
    render: (_id) => (
      <LinkTableField
        url={`/payments/${_id}/receipt`}
        linkText={_id}
        popoverText={'View payment receipt'}
      />
    ),
  },
  {
    field: 'createdAt',
    name: 'Created at',
    sortable: true,
    dataType: 'date',
    render: (none, item) => renderDateField(item.createdAt),
    align: 'center',
  },
  {
    field: 'updatedAt',
    name: 'Updated at',
    sortable: true,
    dataType: 'date',
    render: (none, item) => renderDateField(item.createdAt),
    align: 'center',
  },
];

function PlansHistoryTable({ setError }) {
  const [tableQuery, setTableQuery] = useState({});
  const [planCardVisible, setPlanCardVisible] = useState(false);
  const [selectedPlanCardData, setSelectedPlanCardData] = useState(null);
  const [tableState, tableDispatcher] = useReducer(
    tableReducer,
    tableInitialState,
  );
  const { planUsages, isFetching, isLoading, error } = usePlanUsages(
    {
      page: tableState.currentPage,
      pageSize: tableState.pageSize,
      query: tableQuery,
      sort: tableState.sort,
      populate: ['payment'],
      useSpecialQuery: tableState.useSpecialQuery,
    },
    false,
    true,
    {},
  );

  const handlePlanCardShowClick = (planData) => {
    setPlanCardVisible(true);
    setSelectedPlanCardData(planData);
  };

  useEffect(() => {
    if (error) {
      setError(error);
    }
  }, [error]);

  const columns = useMemo(() => columnsGenerator(handlePlanCardShowClick), []);

  if (!planUsages) {
    return null;
  }

  return (
    <>
      <Table
        entities={planUsages}
        entityName={<EuiI18n token={'Plan'} default={'Plan'} />}
        entitiesName={<EuiI18n token={'Plans'} default={'Plans'} />}
        isLoading={isLoading || isFetching}
        tableColumns={columns}
        tableState={tableState}
        setTableQuery={setTableQuery}
        tableDispatcher={tableDispatcher}
        defaultVisibleColumns={defaultVisibleColumns}
        pageSizeOptions={[5, 10, 25, 0]}
        tableLayout="auto"
        canSelect={false}
        sortSpecialFields={{
          'payment._id': 'payment._id',
        }}
        sortSpecialFieldsMapping={{
          'payment._id': 'payment._id',
        }}
      />
      <PlanCardModal
        planData={selectedPlanCardData}
        isVisible={planCardVisible}
        setIsVisible={setPlanCardVisible}
      />
    </>
  );
}

export default PlansHistoryTable;
