import { EuiButtonEmpty, EuiToolTip } from '@elastic/eui';
import moment from 'moment/moment';
import { Link } from 'react-router-dom';

export const DATE_FORMAT = 'DD-MM-YYYY HH:mm:ss';
export const DATE_FORMAT_DATE_ONLY = 'DD-MM-YYYY';

export const DATE_FORMAT_TIME_ONLY = 'HH:mm:ss';

export function renderDateField(date, fallback = null) {
  if (date) {
    return moment(date).format(DATE_FORMAT);
  } else {
    return fallback;
  }
}

export function PlanNameCardButton({
  name,
  planData,
  handlePlanCardShowClick,
}) {
  const handleClick = () => {
    handlePlanCardShowClick(planData);
  };

  if (!name) {
    return '';
  } else {
    return (
      <EuiToolTip position="top" content={'View plan'}>
        <EuiButtonEmpty onClick={handleClick}>{name}</EuiButtonEmpty>
      </EuiToolTip>
    );
  }
}

export function LinkTableField({ url, linkText, popoverText }) {
  if (linkText) {
    return (
      <EuiToolTip position="top" content={popoverText}>
        <Link to={url}>{linkText}</Link>
      </EuiToolTip>
    );
  } else {
    return '';
  }
}
