import { EuiFormRow, EuiSelect } from '@elastic/eui';
import Label from 'components/forms/Common/Label';

function SelectField({
  field,
  handleOnChange,
  options,
  compressed = false,
  fullWidth = false,
  showPlaceholder = true,
  append = null,
  helpText = null,
}) {
  return (
    <EuiFormRow
      label={<Label field={field} />}
      isInvalid={field.isInvalid}
      error={field.errors}
      isDisabled={field.disabled}
      fullWidth={fullWidth}
      helpText={helpText}
      display={compressed ? 'rowCompressed' : 'row'}
    >
      <EuiSelect
        compressed={compressed}
        fullWidth={fullWidth}
        options={options}
        value={field.value}
        placeholder={showPlaceholder ? field.label : ''}
        onChange={(e) => handleOnChange(e)}
        aria-label={field.label.toString()}
        name={field.name}
        isInvalid={field.isInvalid}
        append={append}
      />
    </EuiFormRow>
  );
}

export default SelectField;
