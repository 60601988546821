import { EuiI18n } from '@elastic/eui';
import { Fragment } from 'react';

function Label({ field }) {
  return (
    <Fragment>
      <EuiI18n default={field.label} token={field.label} />
      {field.is_required ? ' *' : ''}
    </Fragment>
  );
}

export default Label;
