/** @jsxImportSource @emotion/react */
import { useRef, useState } from 'react';
import { allowedEditorTypes } from 'lib/canvasEditor/dist/upload';
import InputField from 'components/forms/fields/InputField';
import useCanvas from 'lib/providers/canvasProvider';

function LogoUploader() {
  const { editor, setIsCanvasPanelExpanded, isMobile, setActivePanelAction } =
    useCanvas();
  const [uploadedLogo, setUploadedLogo] = useState({
    value: null,
    label: 'Logo',
    isInvalid: false,
    errors: [],
  });
  const logoRef = useRef();

  const handleFileChange = async (files) => {
    if (files.length > 0) {
      setUploadedLogo({ ...uploadedLogo, value: files[0] });
      await editor.drawImage(files[0], null);
      logoRef.current.removeFiles();
      closeCanvasPanel();
    }
  };

  const closeCanvasPanel = () => {
    if (isMobile) {
      setIsCanvasPanelExpanded(false);
      setActivePanelAction(null);
    }
  };

  return (
    <InputField
      showLabel={false}
      field={uploadedLogo}
      fieldRef={logoRef}
      accept={allowedEditorTypes}
      fullWidth={true}
      helpText={
        'Upload your logo or any static image you want to print on any label. Accepted formats are jpeg, png and svg.'
      }
      handleOnChange={(files) => handleFileChange(files)}
    />
  );
}

export default LogoUploader;
