/** @jsxImportSource @emotion/react */
import { EuiListGroup, EuiListGroupItem, useEuiTheme } from '@elastic/eui';
import { generateBarcodeSvg } from '@hello-label/common-web-libs/src/barcode';
import React from 'react';
import TemplateFieldItem from 'components/Creator/TemplateFieldItem';
import useCanvas from 'lib/providers/canvasProvider';
import { css } from '@emotion/react';
import { hideScrollBar } from 'lib/cssUtils';

function TemplateFieldsSelector({}) {
  const { euiTheme } = useEuiTheme();

  const {
    templateMetadata,
    editor,
    fieldsDisplayed,
    setIsCanvasPanelExpanded,
    isMobile,
    setActivePanelAction,
  } = useCanvas();

  const handleTextFieldClick = async (fieldInfo, code) => {
    await editor.addTextBox(fieldInfo.sampleValue, {
      fontSize: 24,
      editable: true,
      fieldMetadata: { code, ...fieldInfo, isTemplateField: true },
    });
    closeCanvasPanel();
  };

  const handleBarcodeFieldClick = async (fieldInfo, code) => {
    const barcodeSvg = await generateBarcodeSvg(
      fieldInfo.type,
      fieldInfo.sampleValue,
      {
        displayValue: false,
      },
    );
    await editor.drawBarcode(barcodeSvg, fieldInfo.sampleValue, {
      code,
      ...fieldInfo,
      displayValue: true,
      isTemplateField: true,
    });
    closeCanvasPanel();
  };

  const closeCanvasPanel = () => {
    if (isMobile) {
      setIsCanvasPanelExpanded(false);
      setActivePanelAction(null);
    }
  };

  return (
    <EuiListGroup
      flush={false}
      bordered={false}
      color="text"
      gutterSize={'none'}
      css={css`
        overflow-y: auto;
        height: calc(100vh - 140px);
        ${hideScrollBar()}
      `}
    >
      {templateMetadata.fields.map((fieldInfo, index) => (
        <EuiListGroupItem
          key={index}
          onClick={() =>
            fieldInfo.type === 'text'
              ? handleTextFieldClick(fieldInfo, index)
              : handleBarcodeFieldClick(fieldInfo, index)
          }
          iconType="plusInCircle"
          iconProps={{ size: 'm' }}
          label={
            <TemplateFieldItem
              fieldInfo={fieldInfo}
              inline={true}
              textColor={
                fieldsDisplayed[index]
                  ? euiTheme.colors.disabled
                  : euiTheme.colors.text
              }
            />
          }
          isDisabled={fieldsDisplayed[index]}
        />
      ))}
    </EuiListGroup>
  );
}

export default TemplateFieldsSelector;
