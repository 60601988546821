import { useQueryClient } from '@tanstack/react-query';
import {
  getEntityListCacheKey,
  invalidateCacheEntry,
  QUERY_KEY_MAPPING,
  usePaginatedApiCall,
} from 'lib/hooks/api/common';
import { useEffect, useState } from 'react';

export function useNotifications(
  { pageSize, page, query, sort, populate, useSpecialQuery },
  useSetLoading = false,
  paginationEnabled = false,
  axiosOptions = {},
) {
  const {
    isLoading,
    isFetching,
    data: notifications,
    fetchStatus,
    error,
  } = usePaginatedApiCall(
    'notificationsUsersControllerFindAll',
    QUERY_KEY_MAPPING.notifications,
    { pageSize, page, query, sort, populate, useSpecialQuery },
    useSetLoading,
    paginationEnabled,
    axiosOptions,
    'Error while loading notifications',
  );

  return {
    isLoading,
    isFetching,
    notifications,
    fetchStatus,
    error,
  };
}

export function useNotificationLoader() {
  const [pagesLoaded, setPagesLoaded] = useState(0);
  const [notifications, setNotifications] = useState([]);
  const [fullReload, setFullReload] = useState(false);
  const [total, setTotal] = useState(null);
  const [loadMoreCounter, setLoadMoreCounter] = useState(0);
  const [disableLoadMore, setDisableLoadMore] = useState(true);
  const [hasUnreadNotifications, setHasUnreadNotifications] = useState(false);
  const [refreshNotifications, setRefreshNotifications] = useState(false);
  const queryClient = useQueryClient();

  const { notifications: notificationData } = useNotifications(
    {
      pageSize: 0,
      page: pagesLoaded,
      sort: { createdAt: -1 },
    },
    false,
    false,
    {},
  );

  /*useEffect(() => {
    if (pageSize * pagesLoaded < total && loadMoreCounter > 0) {
      setPagesLoaded(pagesLoaded + 1);
    }
  }, [loadMoreCounter]);*/

  useEffect(() => {
    invalidateCacheEntry(queryClient, QUERY_KEY_MAPPING.notifications, 'list');
    setFullReload(true);
    setPagesLoaded(0);
  }, [refreshNotifications]);

  useEffect(() => {
    if (notificationData) {
      setTotal(notificationData.meta.totalItems);
      let newNotifications;
      if (pagesLoaded === 0 || fullReload) {
        newNotifications = notificationData.data;
      } else {
        newNotifications = [...notifications, ...notificationData.data];
      }
      const filtered = newNotifications.filter(
        (notification) => !notification.opened,
      );
      setHasUnreadNotifications(filtered.length > 0);
      // setDisableLoadMore(newNotifications.length === total);
      setNotifications(newNotifications);
      setFullReload(false);
    }
  }, [notificationData]);

  useEffect(() => {
    const filtered = notifications.filter(
      (notification) => !notification.opened,
    );
    setHasUnreadNotifications(filtered.length > 0);
  }, [notifications]);

  return {
    notifications,
    setNotifications,
    hasUnreadNotifications,
    setRefreshNotifications,
    setLoadMoreCounter,
    disableLoadMore,
  };
}
