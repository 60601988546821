import { fabric } from 'fabric';
import { CUSTOM_IMAGE_FIELD } from 'lib/templateFieldsUtils';
import {
  getCenterLeftPosition,
  getCenterTopPosition,
  setObjectInitialPosition,
} from 'lib/canvasEditor/dist/utils';

export const allowedEditorTypes = ['image/jpeg', 'image/png', 'image/svg+xml'];

export const processSingleFile = (canvas, file, callback = null) => {
  return new Promise((resolve, reject) => {
    if (!allowedEditorTypes.includes(file.type)) {
      resolve();
    }

    let reader = new FileReader();

    // handle svg
    if (file.type === 'image/svg+xml') {
      reader.onload = (f) => {
        fabric.loadSVGFromString(f.target.result, async (objects, options) => {
          let obj = fabric.util.groupSVGElements(objects, options);
          obj
            .set({
              left: 0,
              top: 0,
            })
            .setCoords();
          obj.set('fieldMetadata', {
            type: CUSTOM_IMAGE_FIELD,
            isTemplateField: false,
          });

          canvas.add(obj);
          setObjectInitialPosition(canvas, obj);
          canvas.renderAll();
          canvas.fire('object:modified');
          if (callback) {
            callback();
          }
          resolve(obj);
        });
      };
      reader.readAsText(file);
    } else {
      // handle image, read file, add to canvas
      reader.onload = (f) => {
        fabric.Image.fromURL(f.target.result, async (img) => {
          img.set({
            left: 0,
            top: 0,
          });
          img.scaleToHeight(300);
          img.scaleToWidth(300);
          img.set('fieldMetadata', {
            type: CUSTOM_IMAGE_FIELD,
            isTemplateField: false,
          });
          img.set('top', await getCenterTopPosition(canvas, img));
          img.set('left', await getCenterLeftPosition(canvas, img));
          canvas.add(img);
          setObjectInitialPosition(canvas, img);
          canvas.renderAll();
          canvas.fire('object:modified');
          if (callback) {
            callback();
          }
          resolve(img);
        });
      };

      reader.readAsDataURL(file);
    }
  });
};

export const processFiles = (canvas, files) => {
  if (files.length === 0) {
    return;
  }

  for (let file of files) {
    processSingleFile(file);
  }
};
