export const pages = [
  {
    code: 'dashboard',
    url: '/',
    label: 'Dashboard',
    icon: null,
    useOnLogo: true,
    mainNavbar: false,
    needsPlan: false,
  },
  {
    code: 'generate',
    url: '/generate',
    label: 'Generate Labels',
    icon: 'savedObjectsApp',
    mainNavbar: true,
    needsPlan: true,
  },
  {
    code: 'templates',
    url: '/templates',
    label: 'Templates',
    icon: 'documents',
    mainNavbar: true,
    needsPlan: true,
  },
  {
    code: 'templates/form/new',
    url: '/templates/form/new',
    label: 'Creator',
    icon: 'emsApp',
    mainNavbar: true,
    needsPlan: true,
  },
  {
    code: 'labels',
    url: '/labels',
    label: 'Labels',
    icon: 'fa-solid fa-user',
    mainNavbar: false,
    profileMenu: true,
    needsPlan: false,
  },
  {
    code: 'profile',
    url: '/profile',
    label: 'Edit profile',
    icon: 'fa-solid fa-user',
    mainNavbar: false,
    profileMenu: true,
    needsPlan: false,
  },
  /* {
    code: 'settings',
    url: '/settings',
    label: 'Settings',
    icon: 'fa-solid fa-gear',
    mainNavbar: false,
    profileMenu: true,
    needsPlan: false,
  },*/
  {
    code: 'plans',
    url: '/plans',
    label: 'Plans',
    icon: 'fa-solid fa-gear',
    mainNavbar: false,
    profileMenu: true,
    needsPlan: false,
  },
  {
    code: 'payments',
    url: '/payments',
    label: 'Payments',
    icon: 'fa-solid fa-gear',
    mainNavbar: false,
    profileMenu: true,
    needsPlan: false,
  },
  {
    code: 'fonts',
    url: '/fonts',
    label: 'Fonts',
    icon: 'fa-solid fa-gear',
    mainNavbar: false,
    profileMenu: true,
    needsPlan: false,
  },
  {
    code: 'payment-receipt',
    label: 'Payment Receipt',
    icon: 'fa-solid fa-gear',
    mainNavbar: false,
    profileMenu: false,
    needsPlan: false,
  },
  {
    code: 'logout',
    url: null,
    label: 'Log out',
    icon: 'fa-solid fa-arrow-right-from-bracket',
    mainNavbar: false,
    profileMenu: false,
    isLogout: true,
  },
];

export function getPage(pageCode) {
  const filtered = pages.filter((page) => page.code === pageCode);
  if (filtered.length === 1) {
    return filtered[0];
  } else {
    return null;
  }
}

export function getLogoPage() {
  return pages.filter((page) => page.code === 'dashboard')[0];
}

export function getLogoutPage() {
  return pages.filter((page) => page.code === 'logout')[0];
}

export function getMainNavbarPages() {
  return pages.filter((page) => page.mainNavbar);
}

export function getProfileMenuPages() {
  return pages.filter((page) => page.profileMenu);
}
